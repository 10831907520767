<template>
  <v-data-table
    :headers="headers"
    :items="repositories"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <td class="text-xs">
          {{ props.item.institution_id.title }}
        </td>
        <td class="justify-center">
          <v-layout
            row
          >
            <v-icon
              class="mr-2 pointer"
              @click="viewDetails(props.item)"
            >
              view_list
            </v-icon>
            <v-icon
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
              "
              class="mr-2 pointer"
              @click="editRepository(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
              "
              class="pointer"
              @click="deleteRepository(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    loading: false,
    searchField: '',
    activeRow: null,
    headers: [
      {
        text: 'Name',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Institution ID',
        sortable: false,
        value: 'institution_id',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '',
      },
    ],
    repositories: [],
  }),
  created() {
    this.getLocale();
  },
  beforeMount() {
    this.getRepositories();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('repositories.table.name');
      this.headers[1].text = this.$i18n.t('repositories.table.institution_id');
      this.headers[2].text = this.$i18n.t('repositories.table.actions');
    },
    search(value) {
      this.searchField = value;
    },
    getRepositories() {
      this.loading = true;
      getRequest(
        '/repository/new',
        `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => {
          console.log(response);
          this.repositories = response.repositories;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    viewDetails(data) {
      this.$emit('details', data);
    },
    editRepository(data) {
      this.$emit('edit', data);
    },
    deleteRepository(id) {
      this.$emit('delete', id);
    },
  },
};
</script>
