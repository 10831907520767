<template>
  <div>
    <v-container grid-list-lg>
      <v-layout row>
        <v-flex xs6>
          <v-switch
            v-model="twoFactorAuthentication.enabled"
            class="background-grey"
            color="#b71c1c"
            :label="`Activate Two Factor Authentication`"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>


export default {
  name: 'TwoFactorAuth',
  props: ['institution'],
  data() {
    return {};
  },
  computed: {
    twoFactorAuthentication() {
      return this.$props.institution.preferences.twoFactorAuthentication || {};
    },
  },
};
</script>

