<template>
  <v-dialog
    v-model="dialog"
    width="250"
  >
    <v-card flat>
      <v-toolbar color="red darken-3">
        <v-spacer />
        <v-flex
          xs2
          sm1
          class="mr-2"
        >
          <v-btn
            outline
            icon
            color="black"
            @click="dialog = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <v-card-text>
        <p>{{ $t('price.initial_price') }}: {{ price.initial_price }}</p>
        <p>{{ $t('price.discounted_price') }}: {{ price.discounted_price }}</p>
        <p>{{ $t('price.discount_percentage') }}: {{ price.discount_percentage }}%</p>
        <p>{{ $t('price.logistic_price') }}: {{ price.logistic_price }}</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    price: {},
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.price = {};
      }
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.price = data;
    },
  },
};
</script>
