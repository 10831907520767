<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title class="red darken-3 pa-1">
        <span class="headline pl-2"> {{ $t('reservations.new_reservation') }} </span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <!--------------------------------------SELECT PARTS--------------------------------------->
        <!--TOOLBAR-->
        <v-toolbar
          color="grey darken-4"
          flat
          dark
          dense
          class="pointer"
          @click="showSelectParts = !showSelectParts"
        >
          <v-toolbar-title>
            {{ $t('reservations.parts.select_parts') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              flat
              icon
            >
              <v-icon v-if="showSelectParts">
                expand_less
              </v-icon>
              <v-icon v-else>
                expand_more
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!--SEARCH-->
        <search
          v-if="showSelectParts"
          @search="search"
          @clear="clearSearch"
        />
        <!--DATATABLE-->
        <datatable
          v-if="showSelectParts"
          :search="searchObject"
          :selected="selected"
          @select="selectPart"
          @details="details"
        />
        <!----------------------------------------ADD PARTS---------------------------------------->
        <!--TOOLBAR-->
        <v-toolbar
          color="grey darken-4"
          flat
          dark
          dense
          class="pointer"
          @click="showAddParts = !showAddParts"
        >
          <v-toolbar-title> {{ $t('reservations.new_part.add_parts') }} </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              flat
              icon
            >
              <v-icon v-if="showAddParts">
                expand_less
              </v-icon>
              <v-icon v-else>
                expand_more
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!--NEW PART-->
        <new-part
          v-if="showAddParts"
          @submit="newPart"
        />
        <!-----------------------------------RESERVATION DETAILS----------------------------------->
        <!--TOOLBAR-->
        <v-toolbar
          color="grey darken-4"
          flat
          dark
          dense
          class="pointer"
          @click="showReservationDetails = !showReservationDetails"
        >
          <v-toolbar-title>
            {{ $t('reservations.reservation_details') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              flat
              icon
            >
              <v-icon v-if="showReservationDetails">
                expand_less
              </v-icon>
              <v-icon v-else>
                expand_more
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout
          v-if="showReservationDetails"
          align-center
          justify-center
          row
          wrap
        >
          <!--ORDER LIST-->
          <v-flex
            xs12
            md6
            pa-4
          >
            <order-list
              :parts="selected"
              @remove-part="removePart"
            />
          </v-flex>
          <!--CLIENT DETAILS-->
          <v-flex
            xs12
            md6
            pa-4
          >
            <client-details
              ref="clientDetails"
              :price="totalPrice"
              @add-client="addClient"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('reservations.close') }}
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="disabled"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('reservations.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Search from '@/components/Reservations/components/searchParts.vue';
import Datatable from '@/components/Reservations/components/partsDatatable.vue';

import OrderList from '@/components/Reservations/components/orderList.vue';
import ClientDetails from '@/components/Reservations/components/clientDetails.vue';
import NewPart from '@/components/Reservations/components/newPart.vue';

import postRequest from '../../api/postRequest';

export default {
  components: {
    search: Search,
    datatable: Datatable,
    'order-list': OrderList,
    'client-details': ClientDetails,
    'new-part': NewPart,
  },
  data: () => ({
    dialog: false,
    loading: false,
    showSelectParts: false,
    showReservationDetails: false,
    showAddParts: false,
    searchObject: null,
    totalPrice: 0,
    selected: [],
  }),
  computed: {
    disabled() {
      let client;
      if (this.$refs.clientDetails && this.$refs.clientDetails.client) {
        client = this.$refs.clientDetails.client; // eslint-disable-line prefer-destructuring
      }
      if (this.selected.length < 1 || !client || !client._id) return true;
      return false;
    },
  },
  watch: {
    selected(value) {
      if (value && value.length > 0) this.totalPrice = value.reduce((a, c) => a + c.price, 0);
      else this.totalPrice = 0;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.showSelectParts = true;
      this.showReservationDetails = true;
    },
    close() {
      this.dialog = false;
      this.showSelectParts = false;
      this.showReservationDetails = false;
      this.showAddParts = false;
      this.searchObject = null;
      this.totalPrice = 0;
      this.selected = [];
      if (this.$refs.clientDetails && this.$refs.clientDetails.client) {
        this.$refs.clientDetails.client = {};
      }
    },
    save() {
      const { clientDetails } = this.$refs;
      const { client } = clientDetails;
      const { comment } = clientDetails;
      const { proposedPrice } = clientDetails;
      const { currency } = clientDetails;
      this.loading = true;
      const parts = [];
      const reservationParts = [];
      this.selected.forEach((s) => {
        if (s.__type && s.__type === 'temp') reservationParts.push(s);
        else parts.push(s._id);
      });

      const formData = new FormData();

      formData.append('data', JSON.stringify({
        userId: this.$store.getters.userId,
        institutionId: this.$store.getters.institutionId,
        role: this.$store.getters.role,
        parts,
        reservationParts: reservationParts.map(p => ({
          images: p.images.map(i => i.title),
          description: p.description,
          notes: p.notes,
          price: p.price,
          currency: p.currency,
        })),
        clientId: client._id,
        comment,
        proposedPrice,
        currency,
      }));

      reservationParts.forEach(r => r.images.forEach(i => formData.append('res-photos', i.blob, i.title)));

      postRequest.formData('/reservation/new', '', formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.$emit('saved');
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$emit('error');
          this.close();
        });
    },
    search(data) {
      this.searchObject = data;
    },
    clearSearch() {
      this.searchObject = { type: null };
    },
    selectPart(value) {
      this.selected = value;
    },
    removePart(id) {
      const index = this.selected.findIndex(s => s._id === id);
      this.selected = this.selected.splice(index, 1);
    },
    details(data) {
      this.$emit('details', data);
    },
    addClient() {
      this.$emit('add-client');
    },
    getClients() {
      const { clientDetails } = this.$refs;
      clientDetails.getClients();
    },
    newPart(data) {
      this.selected.push({
        __type: 'temp',
        description: data.description,
        images: data.images,
        notes: data.notes,
        price: parseInt(data.price, 10),
        currency: data.currency,
      });
      console.log(data);
    },
  },
};
</script>
