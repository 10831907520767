<template>
  <v-layout>
    <v-container
      grid-list-sm
      fluid
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          v-for="(image, index) in imgArray"
          :key="index"
          xs6
          sm4
          d-flex
        >
          <v-card
            class="d-flex"
            flat
            tile
            draggable="true"
            :data-index="index"
            @dragstart="dragStartHandler"
            @dragover.prevent
            @drop="dropHandler"
            @dragend="dragEndHandler"
          >
            <v-img
              :src="image.type === 'string'
                ? `${server}/images/?q=${image.title.thumb}`
                : image.dataUrl"
            >
              <v-layout
                row
                ma-1
              >
                <v-icon
                  x-large
                  color="primary"
                  class="pointer"
                  @click="viewImage(image)"
                >
                  image
                </v-icon>
                <v-spacer />
                <v-icon
                  x-large
                  color="red"
                  class="pointer"
                  @click="deleteImage(index, image)"
                >
                  delete_forever
                </v-icon>
              </v-layout>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    draggingIndex: -1,
    dropIndex: -1,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    imgArray() {
      return this.$props.images;
    },
  },
  created() {
    console.log('image', this.$props.images);
  },
  methods: {
    viewImage(image) {
      this.$emit('open', image);
    },
    deleteImage(index, image) {
      this.$emit('delete', { index, image });
    },
    dragStartHandler(e) {
      this.draggingIndex = e.currentTarget.getAttribute('data-index');
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', null);
    },
    dropHandler(e) {
      this.dropIndex = e.currentTarget.getAttribute('data-index');
      if (this.draggingIndex !== this.dropIndex) {
        const draggedImage = this.imgArray[this.draggingIndex];
        this.imgArray.splice(this.draggingIndex, 1);
        this.imgArray.splice(this.dropIndex, 0, draggedImage);
      }
      this.draggingIndex = -1;
      this.dropIndex = -1;

      this.$emit('clicked', this.imgArray);
    },


    dragEndHandler(e) {
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },
  },
};
</script>
