<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('warranties.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('warranties.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />

      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'LekoEmployee'
          || $store.getters.role == 'InstitutionAdmin'
        "
        color="grey darken-4"
        dark
        @click="newWarranty"
      >
        {{ $t('warranties.button') }}
      </v-btn>
    </v-toolbar>

    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <!--DIALOG-->
    <edit-dialog
      ref="editDialog"
      @close="getWarranties"
      @error="error"
    />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      @edit="editWarranty"
      @error="error"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/Warranties/datatable.vue';
import EditDialog from '@/components/Warranties/dialog.vue';

export default {
  components: {
    datatable: Datatable,
    'edit-dialog': EditDialog,
  },
  data: () => ({
    search: '',
    alert: { value: false, message: '' },
  }),
  watch: {
    search(value) {
      const { datatable } = this.$refs;
      datatable.search(value);
    },
  },
  methods: {
    newWarranty() {
      const { editDialog } = this.$refs;
      editDialog.open(false, null);
    },
    editWarranty(data) {
      const { editDialog } = this.$refs;
      editDialog.open(true, data);
    },
    getWarranties() {
      const { datatable } = this.$refs;
      datatable.getWarranties();
    },
    error() {
      this.alert = { value: true, message: '' };
      setTimeout(() => { this.alert = { value: false, message: '' }; }, 2000);
    },
  },
};
</script>
