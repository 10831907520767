<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400"
  >
    <v-card>
      <!--TITLE-->
      <v-card-title class="red darken-3">
        <span class="headline">{{ $t('repositories.form.edit_locations') }}</span>
      </v-card-title>
      <!--BODY-->
      <v-card-text>
        <v-layout
          row
          wrap
          align-center
          justify-center
        >
          <!--ALERT-->
          <v-flex xs12>
            <v-alert
              :value="alert.value"
              :type="alert.type"
            >
              {{ alert.message }}
            </v-alert>
          </v-flex>
          <!--X-->
          <v-flex xs4>
            <v-text-field
              v-model="x"
              class="mr-2"
              label="X"
              color="red darken-1"
              type="number"
              :min="initial.x"
              oninput="validity.valid||(value='');"
            />
          </v-flex>
          <!--Y-->
          <v-flex xs4>
            <v-text-field
              v-model="y"
              class="ml-2 mr-2"
              label="Y"
              color="red darken-1"
              type="number"
              :min="initial.y"
              oninput="validity.valid||(value='');"
            />
          </v-flex>
          <!--CODE-->
          <v-flex xs4>
            <v-text-field
              v-model="code"
              class="ml-2"
              :label="$t('repositories.form.code')"
              color="red darken-1"
            />
          </v-flex>
          <!--NOTES-->
          <v-flex xs12>
            <v-text-field
              v-model="notes"
              :label="$t('repositories.form.notes')"
              color="red darken-1"
            />
          </v-flex>

          <!--WARNING LABEL-->
          <v-flex xs12>
            <v-layout class="mt-2">
              <span class="body-2 red--text">{{ $t('repositories.form.location_changes') }}</span>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <!--ACTION BUTTONS-->
      <v-card-actions>
        <v-spacer />
        <!--CLOSE-->
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('repositories.form.close') }}
        </v-btn>
        <!--SAVE-->
        <v-btn
          :loading="loading"
          :disabled="disabled || !x || !y"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('repositories.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    alert: { value: false, message: null, type: 'error' },
    dialog: false,
    loading: false,
    disabled: false,
    repositoryId: null,
    roomId: null,
    locationId: null,
    x: null,
    y: null,
    initial: { // The initial values of the fields.
      x: null,
      y: null,
    },
    code: null,
    notes: null,
  }),
  methods: {
    open(data) {
      console.log(data);
      this.x = data.location.position_x;
      this.y = data.location.position_y;
      this.initial = {
        x: data.location.position_x,
        y: data.location.position_y,
      };
      this.code = data.location.code;
      this.notes = data.location.notes;
      this.repositoryId = data.repository._id;
      this.roomId = data.room._id;
      this.locationId = data.location._id;
      this.dialog = true;
    },
    clear() {
      this.x = null;
      this.y = null;
      this.initial = { x: null, y: null };
      this.repositoryId = null;
      this.roomId = null;
      this.locationId = null;
      this.dialog = false;
      this.disabled = false;
      this.alert = { value: false, message: null, type: 'error' };
    },
    close() {
      this.clear();
      this.$emit('close', { saved: false });
    },
    save() {
      this.loading = true;
      putRequest('/repository/location', '', {
        repositoryId: this.repositoryId,
        roomId: this.roomId,
        locationId: this.locationId,
        x: this.x,
        y: this.y,
        code: this.code,
        notes: this.notes,
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$emit('save', {
          saved: true,
          editedRepository: response.data,
        });
        this.clear();
      }).catch((error) => {
        this.loading = false;
        this.alert = { value: true, message: error.message, type: 'error' };
        this.disabled = true;
        setTimeout(() => {
          this.alert.value = false;
          this.disabled = false;
        }, 2000);
      });
    },
  },
};
</script>
