<template>
  <div>
    <v-dialog
      v-model="averagePrices"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <div
          v-if="loadingPrices"
          class="col-12 text-center pt-5"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          /><br><br>
          <strong class="pt-3">{{ $t('parts.inventory.calculating_average') }}</strong>
        </div>
        <div v-else>
          <div class="mb-4">
            <strong>{{ $t('parts.inventory.average_prices') }} : {{ oem_for_average }}</strong>
          </div>
          <div>
            {{ $t('parts.inventory.average_price_ebay') }} <strong>eBay</strong>:
            <strong v-if="averageEbay != null">{{ averageEbay }} € <v-icon
              small
              class="ml-2"
              @click="copyToClipboard(averageEbay)"
            >mdi-content-copy</v-icon></strong>
            <strong v-else> {{ $t('parts.inventory.no_results') }}</strong>
          </div><br>
          <div>
            {{ $t('parts.inventory.average_price_rrr') }}:
            <strong v-if="averageRRR != null">{{ averageRRR }} € <v-icon
              small
              class="ml-2"
              @click="copyToClipboard(averageRRR)"
            >mdi-content-copy</v-icon></strong>
            <strong v-else> {{ $t('parts.inventory.no_results') }}</strong>
          </div><br>
          <div>
            {{ $t('parts.inventory.average_price_bparts') }}:
            <strong v-if="averageBParts != null">{{ averageBParts }} € <v-icon
              small
              class="ml-2"
              @click="copyToClipboard(averageBParts)"
            >mdi-content-copy</v-icon></strong>
            <strong v-else> {{ $t('parts.inventory.no_results') }}</strong>
          </div><br>
          <div>
            {{ $t('parts.inventory.average_price_proxy') }}:
            <strong v-if="averageProxy != null">{{ averageProxy }} € <v-icon
              small
              class="ml-2"
              @click="copyToClipboard(averageProxy)"
            >mdi-content-copy</v-icon></strong>
            <strong v-else> {{ $t('parts.inventory.no_results') }}</strong>
          </div><br>
        </div>
        <v-card-actions class="col-12 justify-end mt-2">
          <small
            class="text-grey"
            style="color:gray"
          >*{{ $t('parts.inventory.note_shipping_worldwide') }}</small>  <v-btn
            small
            @click="averagePrices = false"
          >
            {{ $t('parts.inventory.form.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
  components: {
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    averageRRR: null,
    averageBParts: null,
    averageProxy: null,
    averagePrices: false,
    loadingPrices: false,
    oem_for_average: null,
    averageEbay: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard:', text);
      }).catch((err) => {
        console.error('Failed to copy text:', err);
      });
    },
    async openPriceAverages(code) {
      this.oem_for_average = code;
      if (code) {
        this.averagePrices = true;
        await this.getDataFromEndpoint(code);
      }
    },
    async getDataFromEndpoint(searchQuery) {
      const url = `https://spare-parts-scraping-91992d3a1a4c.herokuapp.com/getData?query=${encodeURIComponent(searchQuery)}`;
      try {
        this.loadingPrices = true;
        const response = await axios.get(url);
        const { data } = response;
        this.avergeRRR = null;
        this.averageBParts = null;
        this.averageProxy = null;
        const averagePriceRRR = this.calculateAveragePrice(data.productCodesRRR, false, false);
        const averagePriceBParts = this.calculateAveragePrice(data.productCodesBParts, false, false);
        const averagePriceProxy = this.calculateAveragePrice(data.productCodesProxy, true, false);
        const averagePriceEbay = this.calculateAveragePrice(data.productCodesEbay, false, true);
        this.averageRRR = averagePriceRRR ? averagePriceRRR.toFixed(2) : null;
        this.averageBParts = averagePriceBParts ? averagePriceBParts.toFixed(2) : null;
        this.averageProxy = averagePriceProxy ? averagePriceProxy.toFixed(2) : null;
        this.averageEbay = averagePriceEbay ? averagePriceEbay.toFixed(2) : null;
        console.log(`Average price for productCodesRRR: €${this.averageRRR}`);
        console.log(`Average price for productCodesBParts: €${this.averageBParts}`);
        console.log(`Average price for productCodesProxy: €${this.averageProxy}`);
        console.log(`Average price for averageEbay: €${this.averageEbay}`);
        this.loadingPrices = false;
      } catch (error) {
        console.error('Axios error:', error);
      } finally {
        this.loadingPrices = false;
      }
    },
    calculateAveragePrice(products, isProxy = false, isEbay = false) {
      if (products && products.length > 0) {
        // Helper function to convert price string to number
        const convertPrice = (priceString, isProxy) => {
          let cleanPrice = priceString.trim();
          // Remove currency symbol
          if (isEbay) {
            cleanPrice = cleanPrice.replace('EUR', '').replace(/\s+/g, '');
          } else {
            cleanPrice = cleanPrice.replace('€', '').replace(/\s+/g, '');
          }
          if (isProxy || isEbay) {
            cleanPrice = cleanPrice.replace(/\./g, ''); // Remove thousands separators
            cleanPrice = cleanPrice.replace(',', '.'); // Replace decimal separator
          } else {
            cleanPrice = cleanPrice.replace(',', '.'); // Replace comma with period if necessary
          }
          return parseFloat(cleanPrice);
        };
        const prices = products.map(product => convertPrice(product.price, isProxy));
        const sum = prices.reduce((accumulator, price) => accumulator + price, 0);
        const average = sum / prices.length;
        return average;
      }
    },
  },
};
</script>
