<template>
  <v-dialog
    v-model="alert"
    persistent
    width="300"
  >
    <v-card>
      <v-card-text>
        <v-flex xs12>
          <v-layout class="mt-2">
            <span class="body-2 red--text">{{ $t('delete_alert') }}</span>
          </v-layout>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!--CANCEL-->
        <v-btn
          color="red darken-1"
          flat
          @click.native="cancel"
        >
          {{ $t('no') }}
        </v-btn>
        <!--CONFIRM-->
        <v-btn
          color="blue darken-1"
          :loading="loading"
          flat
          @click.native="confirm"
        >
          {{ $t('yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    alert: false,
    loading: false,
    content: null,
  }),
  methods: {
    open(content) {
      this.content = content;
      this.alert = true;
    },
    close() {
      this.content = null;
      this.loading = false;
      this.alert = false;
    },
    cancel() {
      this.$emit('cancelled', this.content);
      this.close();
    },
    confirm() {
      this.loading = true;
      this.$emit('confirmed', this.content);
    },
  },
};
</script>
