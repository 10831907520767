<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title class="redColor">
        <span class="headline">Actions</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout
            align-center
            justify-center
            wrap
          >
            <!----------------------------------------ALERT---------------------------------------->
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                type="warning"
                outline
              >
                <v-layout
                  align-center
                  justify-center
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    sm6
                  >
                    {{ alert.message }}
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-layout row>
                      <v-btn
                        outline
                        small
                        color="success"
                        @click="confirm = true, alert.value = false"
                      >
                        {{ $t('parts.damaged_parts.actions.alert.yes') }}
                      </v-btn>
                      <v-btn
                        outline
                        small
                        color="error"
                        @click="confirm = false, alert.value = false"
                      >
                        {{ $t('parts.damaged_parts.actions.alert.no') }}
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-alert>
            </v-flex>
            <!---------------------------------------BUTTONS--------------------------------------->
            <v-flex
              xs12
              sm4
            >
              <v-switch
                v-model="switchI"
                :label="$t('parts.damaged_parts.actions.inventory')"
                color="success"
              />
            </v-flex>
            <v-flex
              xs12
              sm4
            >
              <v-switch
                v-model="switchL"
                :label="$t('parts.damaged_parts.actions.lost')"
                color="orange"
              />
            </v-flex>
            <v-flex
              xs12
              sm4
            >
              <v-switch
                v-model="switchR"
                :label="$t('parts.damaged_parts.actions.recycle')"
                color="error"
              />
            </v-flex>
            <!------------------------------------------------------------------------------------->
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.damaged_parts.actions.close') }}
        </v-btn>
        <v-btn
          v-if="switchI"
          :loading="loading"
          :disabled="!confirm"
          color="success"
          flat
          @click.native="inventory"
        >
          {{ $t('parts.damaged_parts.actions.inventory') }}
        </v-btn>
        <v-btn
          v-if="switchL"
          :loading="loading"
          :disabled="!confirm"
          color="orange"
          flat
          @click.native="lost"
        >
          {{ $t('parts.damaged_parts.actions.lost') }}
        </v-btn>
        <v-btn
          v-if="switchR"
          :loading="loading"
          :disabled="!confirm"
          color="error"
          flat
          @click.native="recycle"
        >
          {{ $t('parts.damaged_parts.actions.recycle') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    loading: false,
    dialog: false,
    switchI: false,
    switchL: false,
    switchR: false,
    alert: {
      value: false,
      message: '',
    },
    confirm: false,
    part: {},
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.part = {};
        this.$emit('close');
      }
    },
    switchI(value) {
      if (value) {
        this.switchL = false;
        this.switchR = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.damaged_parts.actions.alert.inventory_message');
      } else if (!this.switchL && !this.switchR) {
        this.confirm = false;
        this.alert.value = false;
      }
    },
    switchL(value) {
      if (value) {
        this.switchI = false;
        this.switchR = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.damaged_parts.actions.alert.lost_message');
      } else if (!this.switchI && !this.switchR) {
        this.confirm = false;
        this.alert.value = false;
      }
    },
    switchR(value) {
      if (value) {
        this.switchI = false;
        this.switchL = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.damaged_parts.actions.alert.recycled_message');
      } else if (!this.switchI && !this.switchL) {
        this.confirm = false;
        this.alert.value = false;
      }
    },
  },
  methods: {
    open(part) {
      console.log(part);
      this.part = part;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.part = {};
      this.$emit('close');
    },
    inventory() {
      this.loading = true;
      putRequest(
        `/part/inventory/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=damaged`,
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
    lost() {
      this.loading = true;
      putRequest(
        `/part/lost/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=damaged`,
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
    recycle() {
      this.loading = true;
      putRequest(
        `/part/recycled/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=damaged`,
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
  },
};
</script>
