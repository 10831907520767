<template>
  <v-dialog
    v-model="dialog"
    width="650"
    persistent
  >
    <v-card flat>
      <v-card-title class="redColor">
        <span class="headline">{{ $t('leko_employees.shippings.actions.label') }}</span>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          align-center
          justify-center
          wrap
        >
          <!-----------------------------------------ALERT----------------------------------------->
          <v-flex xs12>
            <v-alert
              :value="alert.value"
              :type="alert.type"
              outline
            >
              <v-layout
                align-center
                justify-center
                row
                wrap
              >
                <v-flex
                  :class="{ sm6: !switchV }"
                  xs12
                >
                  {{ alert.message }}
                </v-flex>
                <v-flex
                  v-if="alert.buttons"
                  xs12
                  sm6
                >
                  <v-layout row>
                    <v-btn
                      outline
                      small
                      color="success"
                      @click="confirm = true, alert.value = false"
                    >
                      {{ $t('leko_employees.shippings.actions.alert.yes') }}
                    </v-btn>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click="confirm = false, alert.value = false"
                    >
                      {{ $t('leko_employees.shippings.actions.alert.no') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-alert>
          </v-flex>
          <!----------------------------------------SWITCH----------------------------------------->
          <v-flex
            xs6
            sm3
          >
            <v-switch
              v-model="switchV"
              :disabled="status > 4"
              :label="$t('leko_employees.shippings.actions.verify')"
              color="success"
            />
          </v-flex>
          <v-flex
            xs6
            sm3
          >
            <v-switch
              v-model="switchB"
              :disabled="status > 4"
              :label="$t('leko_employees.shippings.actions.block')"
              color="error"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
          >
            <v-switch
              v-model="switchR"
              :disabled="status !== 6"
              :label="$t('leko_employees.shippings.actions.return')"
              color="amber"
            />
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>

        <!-----------------------------------------VERIFY------------------------------------------>
        <v-layout
          row
          align-center
        >
          <v-flex xs12>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-layout
                v-if="switchV"
                row
                align-center
                justify-center
                wrap
              >
                <v-flex
                  xs7
                  sm9
                  md10
                >
                  <v-select
                    v-model="client"
                    :loading="loading"
                    :items="clients"
                    :label="$t('leko_employees.shippings.actions.client.select_client')"
                    :rules="rules.requiredSelect"
                    color="red darken-1"
                    item-text="name"
                    item-value="_id"
                    prepend-icon="person"
                    return-object
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      {{ data.item.name }} {{ data.item.surname }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      {{ data.item.name }} {{ data.item.surname }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs5
                  sm3
                  md2
                >
                  <v-btn
                    color="success"
                    depressed
                    small
                    @click="addClient"
                  >
                    {{ $t('leko_employees.shippings.actions.client.new_client') }}
                  </v-btn>
                </v-flex>

                <v-flex xs12>
                  <v-textarea
                    v-model="notes"
                    :label="$t('leko_employees.shippings.actions.notes')"
                    outline
                    color="red darken-2"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="trackingNumber"
                    :rules="rules.requiredNumbers"
                    :label="$t('leko_employees.shippings.actions.tracking_number')"
                    color="red darken-2"
                    type="Number"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>

          <v-flex
            v-if="switchR"
            xs12
          />
        </v-layout>
        <!----------------------------------------------------------------------------------------->
      </v-card-text>

      <!------------------------------------------ACTIONS------------------------------------------>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('leko_employees.shippings.actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="switchV"
          :disabled="!valid || Object.keys(client).length < 1"
          :loading="loading1"
          color="green"
          flat
          @click.native="verify"
        >
          {{ $t('leko_employees.shippings.actions.verify') }}
        </v-btn>

        <v-btn
          v-if="switchB"
          :disabled="!confirm"
          :loading="loading1"
          color="error"
          flat
          @click.native="block"
        >
          {{ $t('leko_employees.shippings.actions.block') }}
        </v-btn>

        <v-btn
          v-if="switchR"
          :disabled="!confirm"
          :loading="loading1"
          color="amber"
          flat
          @click.native="returnOrder"
        >
          {{ $t('leko_employees.shippings.actions.return') }}
        </v-btn>
      </v-card-actions>
      <!------------------------------------------------------------------------------------------->
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    loading1: false,
    switchV: false,
    switchB: false,
    switchR: false,
    alert: {
      value: false,
      buttons: false,
      type: 'warning',
      message: '',
    },
    confirm: false,
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
      requiredSelect: [v => !!v.name || 'Item is required'],
    },
    buyerDetails: {},
    orderId: null,
    saleId: null,
    trackingNumber: null,
    part: {},
    status: null,
    price: {},
    client: {},
    clients: [],
    notes: '',
  }),
  watch: {
    switchV(value) {
      if (value) {
        this.$refs.form.validate();
        this.switchB = false;
        this.switchR = false;
        this.closeAlert();
        this.getMatchingClients();
      } else if (!this.switchR) {
        this.switchB = true;
        this.client = {};
      }
    },
    switchB(value) {
      if (value) {
        this.switchV = false;
        this.switchR = false;
        this.openAlert(
          'warning',
          this.$i18n.t('leko_employees.shippings.actions.alert.block'),
          true,
        );
      } else if (!this.switchR) {
        this.$refs.form.validate();
        this.switchV = true;
        this.confirm = false;
      }
    },
    switchR(value) {
      if (value) {
        this.switchV = false;
        this.switchB = false;
        this.openAlert(
          'warning',
          this.$i18n.t('leko_employees.shippings.actions.alert.return'),
          true,
        );
      } else {
        this.confirm = false;
        this.closeAlert();
      }
    },
  },
  methods: {
    open(data) {
      console.log(data);
      this.status = data.status;
      this.orderId = data._id;
      this.saleId = data.sale_id;
      this.buyerDetails = data.buyer_details;
      this.part = data.part_id;
      this.price = data.price;
      this.getMatchingClients();
      this.dialog = true;
    },
    close() {
      this.clients = [];
      this.client = {};
      this.buyerDetails = {};
      this.part = {};
      this.price = {};
      this.notes = '';
      this.orderId = null;
      this.trackingNumber = null;
      this.loading = false;
      this.loading1 = false;
      this.dialog = false;
      this.closeAlert();
      this.$emit('close');
    },
    verify() {
      this.loading1 = true;
      putRequest('/order/verify', `order_id=${this.orderId}`, {
        user_id: this.$store.getters.userId,
        part_id: this.part._id,
        client_id: this.client._id,
        price: this.price.logistic_price,
        tracking_number: this.trackingNumber,
        notes: this.notes,
      })
        .then((response) => {
          console.log(response);
          this.$emit('action-completed');
          this.close();
        })
        .catch((error) => {
          console.log(error.response);
          this.close();
        });
    },
    block() {
      this.loading1 = true;
      putRequest('/order/block', `order_id=${this.orderId}`)
        .then((response) => {
          console.log(response);
          this.$emit('action-completed');
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.close();
        });
    },
    returnOrder() {
      this.loading1 = true;
      putRequest('/order/return', `sale_id=${this.saleId}&order_id=${this.orderId}`)
        .then((response) => {
          console.log(response);
          this.$emit('action-completed');
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.close();
        });
    },
    getClients() {
      return new Promise((resolve, reject) => {
        getRequest(
          '/client/',
          `role=${this.$store.getters.role}&institution_id=${
            this.$store.getters.institutionId
          }`,
        )
          .then((response) => {
            this.clients = response.clients;
            console.log(response);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    searchMatchingClient() {
      if (this.buyerDetails.registered) {
        for (let i = 0; i < this.clients.length; i += 1) {
          if (this.clients[i].email === this.buyerDetails.email) {
            this.client = this.clients[i];
            if (this.switchV) {
              this.openAlert(
                'success',
                this.$i18n.t('leko_employees.shippings.actions.alert.client_found'),
                false,
              );
            }
            return;
          }
        }
        if (this.switchV) {
          this.openAlert(
            'warning',
            this.$i18n.t('leko_employees.shippings.actions.alert.client_not_found'),
            false,
          );
        }
      } else if (this.switchV) {
        this.openAlert(
          'warning',
          this.$i18n.t('leko_employees.shippings.actions.alert.client_not_found'),
          false,
        );
      }
    },
    getMatchingClients() {
      if (this.switchV) {
        this.openAlert(
          'info',
          this.$i18n.t('leko_employees.shippings.actions.alert.getting_clients'),
          false,
        );
      }
      this.getClients()
        .then(() => {
          this.searchMatchingClient();
        })
        .catch(() => {
          this.openAlert(
            'error',
            this.$i18n.t('leko_employees.shippings.actions.alert.client_error'),
            false,
          );
          this.loading = false;
        });
    },
    addClient() {
      this.$emit('add-clients', this.buyerDetails);
    },
    hide() {
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
    openAlert(type, message, buttons) {
      this.alert.value = false;
      this.alert.buttons = buttons;
      this.alert.type = type;
      this.alert.message = message;
      this.confirm = false;
      this.alert.value = true;
    },
    closeAlert() {
      this.alert.value = false;
      this.alert.buttons = false;
      this.alert.type = 'warning';
      this.alert.message = '';
      this.alert.confirm = false;
    },
  },
};
</script>
