<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <v-card>
      <v-card-title>{{ this.$i18n.t('parts.inventory.actions.shareData') }}</v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <ShareNetwork
              network="whatsapp"
              class="pointer inline pa-1"
              :url="getUrl"
              title=""
            >
              <span
                class="fab fa-whatsapp-square"
                style="font-size: 30px"
              />
              Whatsapp
            </ShareNetwork>
          </v-flex>

          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <a @click="shareSubito">
              <img
                height="30"
                width="30"
                src="@/assets/icons/subito.png"
              >
              <span style="text-decoration-line: underline;">Subito</span>
            </a>
          </v-flex>

          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <a @click="shareEbay">
              <img
                height="30"
                width="30"
                src="@/assets/icons/ebay_colored.png"
              >
              <span style="text-decoration-line: underline;">Ebay</span>
            </a>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import JSZip from 'jszip';
import config from '../../config';
import getRequest from '../../api/getRequest';

export default {
  components: {},
  data: () => ({
    dialog: false,
    items: [],
  }),
  computed: {
    getUrl() { return this.items.reduce((accumulator, item) => `${accumulator}${config.getShopUri()}/share/part/${item._id}\n`, ''); },
  },
  methods: {
    open(data) {
      console.log(data);
      this.items = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    async shareSubito() {
      const promises = this.items.map(async (i) => {
        const d = await getRequest(`/info-car/id/${i.infoCar._id}`).catch(e => e);
        const brand = d && d.applicability && d.applicability.length ? d.applicability[0].brand : '';
        const model = d && d.applicability && d.applicability.length ? d.applicability[0].model : '';
        const version = d && d.applicability && d.applicability.length && d.applicability[0].versions && d.applicability[0].versions.length ? d.applicability[0].versions[0].version : '';

        // <email>${i.institution_id.email}</email>
        return `
        <ad>
          <type>sell</type>
          <category>accessori-auto</category>
          <ad_code>${i.old_id.slice(i.old_id.length - 6, i.old_id.length)}</ad_code>
          <subject><![CDATA[ ${i.infoCar.description.descriptionId.title.it} ]]></subject>
          <body>
            <![CDATA[ ${i.infoCar.description.descriptionId.category.it} ${i.infoCar.description.descriptionId.title.it} ${brand} ${model}
              DETTAGLI
              - Marca: ${brand}
              - ${model}
              - ${version}
              - Codice del Pezzo: ${i.manufacturer_code}
              - Codice OEM: ${i.infoCar.oemCode}
              - Note: ${i.notes}
            ]]>
          </body>
          <features>
            <geo>
              <town>
                <id>026086</id>
              </town>
              <province>
                <id><![CDATA[TV]]></id>
              </province>
            </geo>
            <price type="integer"> ${i.price} </price>
          </features>
          <advertiser>
            <email>tester.subito@gmail.com</email>
            <phone_hidden type="boolean">false</phone_hidden>
            <phone>${i.institution_id.phone}</phone>
            <company type="boolean">true</company>
            <name><![CDATA[${i.institution_id.title}]]></name>
          </advertiser>
          <images>
            ${i.images.map(img => `<image><![CDATA[http://api.lekotech.com/images?date=${i.date_created}&q=${i._id}-${img}]]></image>`).join('')}
          </images>
        </ad>
        `;
      });

      const items = await Promise.all(promises);
      const xml = `
      <?xml version="1.0" encoding="UTF-8"?>
        <ads>
          ${items.join('\n')}
        </ads>
      `;

      const zip = new JSZip();
      zip.file('data.xml', xml.trim());
      zip.generateAsync({ type: 'blob' }).then((result) => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.xml.zip');
        document.body.appendChild(link);
        link.click();
      });
    },
    shareEbay() {
      console.log(this.items);
      const xml = `
        <?xml version="1.0" encoding="UTF-8"?>
        <ebay>
          <data>
          </data>
        </ebay>
      `;
      const url = window.URL.createObjectURL(new Blob([xml]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ebay.xml');
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
