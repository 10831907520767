<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <!--Title-->
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('car_locations.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <!--Search-->
      <v-text-field
        v-model="search"
        :label="$t('car_locations.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer />
      <!--QR Scanner-->
      <v-btn
        color="grey darken-4"
        class="hidden-sm-and-down"
        dark
        @click="openQrScanner"
      >
        <v-icon left>
          fas fa-qrcode
        </v-icon>
        {{ $t('parts.inventory.scan_qr') }}
      </v-btn>
      <!--Print Locations-->
      <v-btn
        color="grey darken-4"
        class="hidden-sm-and-down"
        dark
        @click="printCarLocations"
      >
        {{ $t('car_locations.print') }}
      </v-btn>
      <!--New Location-->
      <v-btn
        color="grey darken-4"
        dark
        @click="newCarLocation"
      >
        {{ $t('car_locations.new_location') }}
      </v-btn>
    </v-toolbar>
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      :search="search"
      @qr="openQrDialog"
      @cars-list="viewCarsList"
      @edit="editCarLocation"
    />
    <!--CARS LIST-->
    <cars-list ref="carsList" />
    <!--DIALOG-->
    <edit-dialog
      ref="dialog"
      @save="getCarLocations"
    />
    <!--QR DIALOG-->
    <qr-dialog ref="qrDialog" />
    <!--QR SCANNER-->
    <qr-scanner
      ref="qrScanner"
      @list="viewCarsList"
      @edit="editCarLocation"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/CarLocations/datatable.vue';
import CarsList from '@/components/CarLocations/carsList.vue';
import EditDialog from '@/components/CarLocations/editDialog.vue';
import QrDialog from '@/components/qrDialog.vue';
import QrScanner from '@/components/CarLocations/qrScanner.vue';
import Print from '@/components/CarLocations/print';

export default {
  components: {
    datatable: Datatable,
    'cars-list': CarsList,
    'edit-dialog': EditDialog,
    'qr-dialog': QrDialog,
    'qr-scanner': QrScanner,
  },
  data: () => ({
    search: '',
  }),
  methods: {
    newCarLocation() {
      const { dialog } = this.$refs;
      dialog.open(false, null);
    },
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    openQrScanner() {
      const { qrScanner } = this.$refs;
      qrScanner.open();
    },
    viewCarsList(data) {
      const { carsList } = this.$refs;
      carsList.open(data);
    },
    editCarLocation(data) {
      const { dialog } = this.$refs;
      dialog.open(true, data);
    },
    getCarLocations() {
      const { datatable } = this.$refs;
      datatable.getCarLocations();
    },
    printCarLocations() {
      const { datatable } = this.$refs;
      Print(datatable.carLocations);
    },
  },
};
</script>
