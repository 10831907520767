<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <v-flex
          xs10
          sm11
        >
          <span class="headline">{{ $i18n.t('vehicle_entry.select_parts') }}</span>
        </v-flex>
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <!------------------------------------- CAR DETAILS ------------------------------------->
          <v-flex
            xs12
            mb-2
          >
            <v-layout
              v-if="version"
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ version.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
                md2
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ version.startingDate | moment }} - {{ version.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ version.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ version.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ version.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(version.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!---------------------------------------- LIST ----------------------------------------->
          <v-flex
            xs12
            mt-2
          >
            <v-layout
              v-if="loading"
              align-center
              justify-center
            >
              <v-progress-circular
                :size="50"
                color="red"
                indeterminate
              />
            </v-layout>

            <v-layout
              v-else
              column
            >
              <v-flex
                v-for="item in items"
                :key="item._id"
              >
                <v-divider />
                <v-layout
                  align-center
                  wrap
                >
                  <v-flex
                    xs2
                    sm1
                  >
                    <v-checkbox
                      v-model="item.selected"
                      class="mt-2"
                      color="red darken-1"
                    />
                  </v-flex>

                  <v-flex
                    xs10
                    sm11
                    md5
                  >
                    <div> {{ item.title[$store.getters.language] }}  </div>
                  </v-flex>

                  <v-flex
                    xs12
                    md6
                  >
                    <v-text-field
                      v-model="item.notes"
                      :label="$t('vehicle_entry.notes')"
                      color="red darken-1"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!--Comment-->
            <v-textarea
              v-model="comment"
              :label="$t('vehicle_entry.comment')"
              color="red darken-1"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          :disabled="loading"
          flat
          @click.native="save"
        >
          {{ $i18n.t('vehicle_entry.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

import getRequest from '../../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },

  data: () => ({
    dialog: false,
    loading: false,
    version: null,
    position: null,
    items: [],
    comment: null,
  }),
  methods: {
    open(position, vehicle, edit) {
      this.dialog = true;
      this.loading = true;
      this.position = position;
      this.version = vehicle && vehicle.details && vehicle.details.version
        ? vehicle.details.version : null;

      this.items = vehicle.parts && vehicle.parts[position] ? vehicle.parts[position] : [];

      if (vehicle.parts && vehicle.parts.comment) this.comment = vehicle.parts.comment;

      getRequest('/part-groups/', `position=${position}`)
        .then((response) => {
          console.log(response);
          const parts = response.parts || [];
          const newItems = parts.reduce((accumulator, part) => {
            if (!this.items.find(i => i.partCode === part.partCode)) {
              const _part = part;
              if (edit && edit === true) _part.selected = false; // Edit dialog
              else _part.selected = true;
              accumulator.push(_part);
            }
            return accumulator;
          }, []);

          this.items = this.items.concat(newItems);
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    close() {
      this.dialog = false;
      this.position = null;
      this.items = [];
      this.comment = null;
      this.$emit('close');
    },
    save() {
      this.$emit('save', { position: this.position, items: this.items, comment: this.comment });
      this.close();
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
