<template>
  <v-container grid-list-lg>
    <h3>{{ $t('settings.product_settings') }}</h3>
    <!----------------------------------------Default Lotto---------------------------------------->
    <v-layout wrap>
      <v-flex xs6>
        <v-select
          v-model="general.defaultShipment"
          :items="shipments"
          color="red darken-3"
          item-text="name"
          item-value="_id"
          :label="$t('settings.default_shipment')"
        />
      </v-flex>
    </v-layout>
    <!---------------------------------------Default Quality--------------------------------------->
    <v-layout row>
      <v-flex xs6>
        <v-select
          v-model="general.defaultQuality"
          :items="qualities"
          color="red darken-3"
          item-text="value"
          item-value="_id"
          :label="$t('settings.default_quality')"
        />
      </v-flex>
    </v-layout>
    <!--------------------------------------Default Warranty--------------------------------------->
    <v-layout row>
      <v-flex xs6>
        <v-select
          v-model="general.defaultWarranty"
          :items="warranties"
          color="red darken-3"
          item-text="title"
          item-value="_id"
          :label="$t('settings.default_warranty')"
        />
      </v-flex>
    </v-layout>
    <!----------------------------------------Default Price---------------------------------------->
    <v-layout row>
      <v-flex xs6>
        <v-text-field
          v-model="general.defaultPrice"
          color="red darken-3"
          :label="$t('settings.default_price')"
          type="number"
          min="min"
        />
      </v-flex>
    </v-layout>
    <!----------------------------------------Enable Weight---------------------------------------->
    <h3> {{ $t('settings.weight') }} </h3>
    <v-switch
      v-model="displayFeatures.partWeight"
      :label="$t('settings.enable_weight')"
      color="red darken-3"
    />
    <!------------------------------Enable Images Background Removal-------------------------------
    <h3> {{ $t('settings.remove_background') }} </h3>
    <v-switch
      v-model="displayFeatures.removeImagesBackground"
      :label="$t('settings.enable_remove_background')"
      color="red darken-3"
    />
    -->
  </v-container>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  name: 'Products',
  props: ['institution'], // eslint-disable-line vue/require-prop-types
  data() {
    return {
      shipments: [],
      qualities: [],
      warranties: [],
    };
  },
  computed: {
    general() {
      return this.$props.institution.preferences.general || {};
    },
    displayFeatures() {
      return this.$props.institution.preferences.displayFeatures || {};
    },
  },
  beforeMount() {
    this.getShipments();
    this.getQualities();
    this.getWarranties();
  },
  methods: {
    getShipments() {
      this.loadingShipments = true;
      getRequest('/shipment/institution', `id=${this.$store.getters.institutionId}`)
        .then((response) => {
          this.shipments = response.shipments;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingShipments = false;
        });
    },
    getQualities() {
      this.loadingQualities = true;
      getRequest('/quality')
        .then((response) => {
          console.log(response);
          this.qualities = response.qualities;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingQualities = false;
        });
    },
    getWarranties() {
      this.loadingWarranties = true;
      getRequest('/warranty')
        .then((response) => {
          this.warranties = response.warranties;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingWarranties = false;
        });
    },
  },
};
</script>
