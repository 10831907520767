<template>
  <v-container
    class="pa-0 justify-center"
    fluid
  >
    <!-- TOOLBAR -->
    <v-toolbar
      color="red darken-3"
      flat
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ institution.title }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-form
          ref="form2"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!------------------------------------UPLOAD LOGO------------------------------------>
              <v-flex xs6>
                <v-layout
                  row
                  wrap
                >
                  <!-- Text "Company Logo" in one column (xs4) -->
                  <v-flex xs4>
                    <label style="padding-right: 10px;">{{ $t('institutions.form.upload_logo') }}</label>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          info
                        </v-icon>
                      </template>
                      <span>72 x 72 {{ $t('institutions.form.preferred') }}</span>
                    </v-tooltip>
                  </v-flex>

                  <!-- Display institution's logo if available, or uploaded logo -->
                  <v-flex xs8>
                    <!-- File input -->
                    <v-layout row>
                      <v-flex xs12>
                        <input
                          ref="logoInput"
                          accept="image/*"
                          type="file"
                          @change="onInput"
                        >
                      </v-flex>
                    </v-layout>
                    <!-- Use a template with v-if and v-else for conditional rendering -->
                    <template v-if="!logo">
                      <!-- Display institution's logo with the server URL -->
                      <div class="logo-container pt-4">
                        <img
                          v-if="institution.logo"
                          :src="`${server}/images?q=${institution.logo}`"
                          :style="{ 'max-width': '300px' }"
                          alt="Company Logo"
                        >
                      </div>
                    </template>
                    <template v-else>
                      <!-- Display uploaded logo if no institution logo is available -->
                      <div class="logo-container pt-4">
                        <img
                          v-if="logo"
                          :src="tmpLogo"
                          :style="{ 'max-width': '300px' }"
                          alt="Company Logo"
                        >
                      </div>
                    </template>

                    <!-- Other content -->
                    <v-layout row>
                      <v-flex xs12>
                        <!-- Add your other content here -->
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>


              <v-flex xs6>
                <v-layout
                  row
                  wrap
                >
                  <!-- Text "Company Logo" in one column (xs3) -->
                  <v-flex xs3>
                    <label style="padding-right:10px;">{{ $t('inventory_details') }}</label>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout row>
                      <v-flex xs>
                        <v-icon>mdi-account</v-icon>
                      </v-flex>
                      <v-flex
                        class="pl-3"
                        xs12
                      >
                        <p><strong>{{ $t('history.users') }}: </strong>{{ institution.usersCount }}</p>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs>
                        <v-icon>mdi-archive</v-icon>
                      </v-flex>
                      <v-flex
                        class="pl-3"
                        xs12
                      >
                        <p><strong>{{ $t('institutions.table.inventory') }}: </strong>{{ institution.partsCount }}</p>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs>
                        <v-icon>mdi-car</v-icon>
                      </v-flex>
                      <v-flex
                        class="pl-3"
                        xs12
                      >
                        <p>
                          <strong>{{ $t('institutions.form.plates') }}: </strong> {{
                            institution.plates.used
                          }}/{{ institution.plates.remaining }}
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!---------------------------------------TITLE--------------------------------------->
              <v-toolbar
                class="mb-2 mt-5"
                color="grey darken-1"
                dense
              >
                <div style="color:white">
                  <strong>General Information</strong>
                </div>
              </v-toolbar>
              <v-flex
                class="pr-5"
                xs6
              >
                <v-text-field
                  v-if="institution && institution.title"
                  v-model="institution.title"
                  :label="$t('institutions.form.title')"
                  :rules="rules.requiredLetters"
                  color="red darken-1"
                  prepend-icon="domain"
                />
              </v-flex>
              <!--------------------------------------ADDRESS-------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="institution.address"
                  :label="$t('institutions.form.address')"
                  :rules="rules.requiredLetters"
                  color="red darken-1"
                  prepend-icon="list_alt"
                />
              </v-flex>

              <!---------------------------------------STATE--------------------------------------->
              <v-flex
                class="pr-5"
                xs6
              >
                <v-select
                  v-model="institution.state"
                  :items="states"
                  :label="$t('institutions.form.state')"
                  :loading="loadingState"
                  :rules="rules.required"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="public"
                  return-object
                />
              </v-flex>
              <!---------------------------------------CITY---------------------------------------->
              <v-flex xs6>
                <v-select
                  v-model="institution.city"
                  :items="cities"
                  :label="$t('institutions.form.city')"
                  :loading="loadingCity"
                  :rules="rules.required"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="location_city"
                />
              </v-flex>
              <!---------------------------------------PHONE--------------------------------------->
              <v-flex
                class="pr-5"
                xs6
              >
                <v-text-field
                  v-model="institution.phone"
                  :label="$t('institutions.form.phone')"
                  :rules="rules.requiredNumbers"
                  color="red darken-1"
                  prepend-icon="call"
                  type="number"
                />
              </v-flex>
              <!--------------------------------------WEBSITE-------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="institution.website"
                  :label="$t('institutions.form.website')"
                  :rules="rules.requiredLetters"
                  color="red darken-1"
                  prepend-icon="web"
                />
              </v-flex>
              <!-------------------------------------LEKOSHOP-------------------------------------->
              <v-flex
                class="pr-5"
                xs6
              >
                <v-text-field
                  v-model="institution.shop"
                  :label="$t('institutions.table.shop')"
                  color="red darken-1"
                  prepend-icon="shop"
                />
              </v-flex>
              <!---------------------------------------Email--------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="institution.email"
                  :label="$t('institutions.form.email')"
                  :rules="rules.email"
                  color="red darken-1"
                  prepend-icon="email"
                />
              </v-flex>
              <!-- TYPE -->
              <v-flex
                class="pr-5"
                xs6
              >
                <v-text-field
                  v-model="institution.type"
                  :label="$t('institutions.form.type')"
                  :rules="rules.requiredLetters"
                  color="red darken-1"
                  prepend-icon="domain"
                />
              </v-flex>
              <v-toolbar
                class="mb-2 mt-5"
                color="grey darken-1"
                dense
              >
                <div style="color:white">
                  <strong>Available Services</strong>
                </div>
              </v-toolbar>

              <v-flex xs6>
                <v-layout row>
                  <!-- First column with 4 elements -->
                  <v-flex
                    class="mr-4"
                    xs6
                  >
                    <!-- STATUS -->
                    <v-switch
                      v-model="institution.user_status"
                      :label="$t('institutions.form.user_status')"
                      class="mb-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                    <!-- EXPORT EBAY MULTIBREVES-->
                    <v-switch
                      v-model="institution.export_ebay"
                      :label="$t('institutions.table.exportEbay')"
                      class="mb-2 mr-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                    <!-- EXPORT EBAY LEKO-->
                    <v-switch
                      v-model="institution.export_ebay_leko"
                      :label="$t('institutions.table.exportEbayLeko')"
                      class="mb-2 mr-2"
                      color="red darken-1"
                      prepend-icon="poll"
                      hide-details
                      :disabled="!checkEbay()"
                    />
                    <span class="ml-5 grey--text"><v-icon
                                                    v-if="checkEbayDetails(institution)"
                                                    small
                                                    class="pl-4"
                                                  >check_circle</v-icon><v-icon
                                                    v-if="!checkEbayDetails(institution)"
                                                    small
                                                    class="pl-4"
                                                  >mdi-cancel</v-icon> <strong
                        class="pa-0 ma-0"
                        role="button"
                        @click="openEbayDetails(institution)"
                      >{{ $t('institutions.table.ebay_details') }}</strong>
                      <strong v-if="institution && institution.eBayDetails && institution.eBayDetails.tokenExpirationDate">
                        {{ '('+ institution.eBayDetails.tokenExpirationDate.slice(0,7) + ')' }}</strong></span>
                    <!-- EXPORT PARTS -->
                    <v-switch
                      v-model="institution.export_parts"
                      :label="$t('institutions.table.exportParts')"
                      class="mb-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                    <!-- EXPORT TEMPORARY PARTS -->
                    <v-switch
                      v-model="institution.export_temporary_parts"
                      :label="$t('institutions.table.exportTempParts')"
                      class="mb-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                    <!-- DISPLAY SHOP -->
                    <v-flex xs12>
                      <v-switch
                        v-model="institution.display_shop"
                        :label="$t('institutions.form.displayShop')"
                        color="red darken-1"
                        prepend-icon="poll"
                      />
                    </v-flex>
                  </v-flex>
                  <!-- Second column with 3 elements -->
                  <v-flex xs6>
                    <v-switch
                      v-model="institution.export_sales"
                      :label="$t('institutions.table.exportSales')"
                      color="red darken-1"
                      prepend-icon="poll"
                    />


                    <!-- STATS -->
                    <v-switch
                      v-model="institution.stats"
                      :disabled="!institution.email"
                      :label="$t('institutions.form.stats')"
                      class="mb-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                    <!-- INFOCAR CAD -->
                    <v-switch
                      v-if="institution && institution.preferences"
                      v-model="institution.preferences.displayFeatures.infoCarCad"
                      :label="$t('institutions.form.infoCarCad')"
                      class="mb-2"
                      color="red darken-1"
                      prepend-icon="poll"
                    />

                    <!--IMAGES BACKGROUND REMOVAL-->
                    <v-switch
                      v-if="institution && institution.preferences"
                      v-model="institution.preferences.displayFeatures.removeImagesBackground"
                      :label="$t('institutions.form.removeImagesBackground')"
                      color="red darken-1"
                      prepend-icon="poll"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>


              <!-- Right side with xs6 -->
              <v-flex xs6>
                <!-- PARTS -->
                <v-layout
                  v-if="institution.parts"
                  align-center
                  row
                  wrap
                >
                  <v-flex
                    sm4
                    xs12
                  >
                    <span class="subheading grey--text text--darken-1">
                      {{ $t('institutions.form.type_of_parts') }}:
                    </span>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="institution.parts.new"
                      :label="$t('institutions.form.new')"
                      color="primary"
                    />
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="institution.parts.used"
                      :label="$t('institutions.form.used')"
                      color="primary"
                    />
                  </v-flex>
                </v-layout>

                <!-- PLATES -->
                <v-layout row>
                  <v-flex xs6>
                    <v-text-field
                      v-if="institution.plates"
                      v-model="institution.plates.used"
                      :label="$t('institutions.form.used_plates')"
                      color="red darken-1"
                      disabled
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="institution.plates.remaining"
                      :label="$t('institutions.form.remaining_plates')"
                      color="red darken-1"
                      min="0"
                      oninput="validity.valid||(value='');"
                      step="1"
                      type="number"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!----------------------------------------------------------------------------------->
            </v-layout>
          </v-container>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              flat
              @click="onCancelClick"
            >
              {{ $t('institutions.form.close') }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="blue darken-1"
              flat
              @click.native="save"
            >
              {{ $t('institutions.form.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <ebay-dialog
      ref="ebayDialog"
      @fetchInstitutionDetails="fetchInstitutionDetails"
    />
  </v-container>
</template>

<script>
import postRequest from '@/api/postRequest';
import ebay from '@/components/Institutions/ebayDetails.vue';
import getRequest from '../../api/getRequest';


function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  components: {
    'ebay-dialog': ebay,
  },
  data() {
    return {
      uploadedImages: [],
      logo: null,
      tmpLogo: '',
      valid: false,
      edit: null,
      dialog: false,
      loading: false,
      loadingState: false,
      loadingCity: false,
      rules: {
        requiredLetters: [
          v => !!v || 'Please fill this field',
          v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
        ],
        requiredNumbers: [
          v => !!v || 'Please fill this field',
          v => /[0-9]/.test(v) || 'Only numbers are allowed',
        ],
        email: [
          v => !!v || 'Please fill this field',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      institution: {},
      states: [],
      state: {},
      cities: [],
    };
  },
  computed: {
    server() {
      return this.$serverUri;
    },

  },
  watch: {
    state(value) {
      if (value) {
        this.institution.state = value.name;
      }
    },
  },
  created() {
    this.fetchInstitutionDetails();
    this.getStates();
    this.getCities();
    console.log('id', this.$route.params.id);
  },
  methods: {


    open(edit, data) {
      this.edit = edit;
      // Add the plates object if it doesn't exist
      if (!data.plates) data.plates = { used: 0, remaining: 0 };
      this.institution = data;
      this.dialog = true;

      // Update the state property if institution's state is available
      if (data.state) {
        this.state = data.state;
        // this.getCities(data.state._id);
      } else {
        this.state = {}; // Reset the state
        this.cities = []; // Reset the cities
      }
    },
    close() {
      this.institution = { plates: { used: 0, remaining: 0 } };
      this.states = [];
      this.state = {};
      this.cities = [];
      this.logo = null;
      this.dialog = false;
      this.loading = false;
      // this.$emit('close');
    },
    onInput(input) {
      const { files } = input.target;
      if (files && files.length > 0) {
        const { type } = files[0];
        const extension = type.split('/')[1];
        const fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);

        fileReader.addEventListener('load', () => {
          const dataUrl = fileReader.result;
          this.tmpLogo = dataUrl;
          this.logo = { blob: dataURLtoBlob(dataUrl), extension }; // Set the logoUrl immediately
        });
      }
    },
    getStates() {
      this.loadingState = true;
      getRequest('/state')
        .then((response) => {
          this.states = response.states;
          this.loadingState = false;
          if (this.institution.state) {
            // select institution's state
            this.states.forEach((state) => {
              if (state.name === this.institution.state) this.state = state;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingState = false;
        });
    },
    getCities(id) {
      this.loadingCity = true;
      getRequest('/city', `state_id=${id}`)
        .then((response) => {
          this.cities = response.cities;
          this.loadingCity = false;
        })

        .catch((error) => {
          console.log(error);
          this.loadingCity = false;
        });
    },

    onCancelClick() {
      // Go back to the previous page
      this.$router.push('/institutions'); // Redirect to /institutions
    },


    save() {
      if (this.$refs.form2 && this.$refs.form2.validate()) {
        this.loading = true;

        const formData = new FormData();
        // Check if a new logo was uploaded and add it to the form data
        if (this.logo !== null) formData.append('logo', this.logo.blob, `logo.${this.logo.extension}`);
        formData.append('institution', JSON.stringify(this.institution));

        // Send the request to update the institution
        postRequest
          .formData('/institution/edit', `id=${this.institution._id}`, formData)
          .then(() => {
            this.$router.push('/institutions'); // Redirect to /institutions
            this.$emit('success', 'Successfully edited'); 
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },


    async fetchInstitutionDetails() {
      const institutionId = this.$route.params.id;

      try {
        const response = await getRequest('/institution', `id=${institutionId}`);
        console.log(response);

        // Check if the response contains an 'institution' property
        if (response.institution) {
          this.institution = response.institution[0];
          this.isLoading = true; // Data is loaded, set isLoading to false
          this.checkEbay();
        } else {
          this.error = 'Institution not found'; // Set an error message
          this.isLoading = false; // Set isLoading to false
        }
      } catch (error) {
        console.log(error);
        this.error = 'An error occurred while fetching data'; // Set an error message
        this.isLoading = false; // Set isLoading to false
      }
    },
    checkEbay() {
      console.log('checkEbay:', !this.institution.eBayDetails);

      // Check if eBayDetails exists and is an object
      if (!this.institution.eBayDetails) {
        return false;
      }

      // Check the required properties in eBayDetails
      return (
        this.institution.eBayDetails.token &&
    this.institution.eBayDetails.tokenExpirationDate &&
    this.institution.eBayDetails.shippingId &&
    this.institution.eBayDetails.paymentId &&
    this.institution.eBayDetails.returnId
      );
    },
    checkEbayDetails(institution) {
      if (!institution || !institution.eBayDetails) {
        return false;
      }
      const {
        token, tokenExpirationDate, shippingId, paymentId, returnId,
      } = institution.eBayDetails;
      if (token && tokenExpirationDate && shippingId && paymentId && returnId) {
        return true;
      }
      return false;
    },
    openEbayDetails(institution) {
      console.log('openEbayDetails', institution);
      this.$refs.ebayDialog.openEbayDetailsModal(institution);
    },
  },
};
</script>

