<template>
  <v-dialog v-model="dialog">
    <v-card v-if="parts">
      <v-layout
        justify-end
        class="pt-1 pr-1"
      >
        <v-btn
          outline
          icon
          large
          color="red"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-layout>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex v-if="loading">
            <v-layout
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="red"
              />
            </v-layout>
          </v-flex>

          <v-flex
            v-for="(part, index) in parts"
            v-else
            :key="index"
          >
            <v-card flat>
              <v-layout
                align-center
                justify-center
                column
              >
                <v-flex
                  pa-2
                  xs12
                >
                  <v-layout column>
                    <v-flex>
                      <qr-code
                        :size="150"
                        :text="part.id"
                      />
                    </v-flex>
                    <v-flex>
                      <v-layout
                        align-center
                        justify-center
                        row
                        wrap
                      >
                        <v-flex
                          pl-5
                          xs8
                        >
                          <h3 class="text-xs-center">
                            {{ part.id.slice(part.id.length-6, part.id.length) }}
                          </h3>
                        </v-flex>
                        <v-flex xs4>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="printSingle(part)"
                              >
                                <v-icon>
                                  {{ part.printed ? 'print_disabled' : 'print' }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="part.printed">
                              <v-layout align-center>
                                <v-icon> print_disabled</v-icon>
                                &nbsp;
                                {{ $t('printed_alert') }}
                              </v-layout>
                            </span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          color="primary"
          @click="print"
        >
          {{ $t('parts.insert_parts.qr_codes.print_qr_codes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrCode from 'vue-qr-generator'; // eslint-disable-line import/extensions
import QrCode from 'qrcode';
import { Printd } from 'printd';
import postRequest from '../../api/postRequest';
import getRequest from '../../api/getRequest';

function printSingleQrCode(vehicle, supply, part) {
  QrCode.toDataURL(part.id, (error, url) => {
    if (error) { console.log(error); }

    const qrImage = document.createElement('img');
    qrImage.setAttribute('id', 'qrImage');
    qrImage.setAttribute('src', url);
    qrImage.style.width = '100px';
    qrImage.style.height = '100px';
    const idDiv = document.createElement('div');
    const slicedId = part.id.slice(part.id.length - 6, part.id.length);
    idDiv.textContent = slicedId;
    idDiv.setAttribute('id', 'idText');

    const row1 = document.createElement('div');
    row1.setAttribute('id', 'carText');
    row1.textContent = vehicle.brand.title;

    const row2 = document.createElement('div');
    row2.setAttribute('id', 'carText');
    const startingDate = vehicle.model.startingDate
      ? new Date(vehicle.model.startingDate) : null;
    const endingDate = vehicle.model.endingDate
      ? new Date(vehicle.model.endingDate) : null;
    row2.textContent = `${vehicle.model.description} ${startingDate ? `${startingDate.getMonth() + 1}/${startingDate.getFullYear()}` : ''} - ${endingDate ? `${endingDate.getMonth() + 1}/${endingDate.getFullYear()}` : ''}`;

    const row3 = document.createElement('div');
    row3.setAttribute('id', 'carText');
    row3.textContent = vehicle.version.description;

    const row4 = document.createElement('div');
    row4.setAttribute('id', 'carText');
    row4.textContent = `${vehicle.version.engineCode || ''} / ${vehicle.version.power.kw} kw / ${vehicle.km ? `${vehicle.km} km` : ''}`;

    const row5 = document.createElement('div');
    row5.setAttribute('id', 'carText');
    row5.textContent = `${supply} / ${vehicle.transmissionType} / ${vehicle.transmissionLayout}`;

    const row6 = document.createElement('div');
    row6.setAttribute('id', 'carText');
    row6.textContent = `PRA: ${vehicle.pra || ''}`;

    const row7 = document.createElement('div');
    row7.setAttribute('id', 'carText');
    [row7.textContent] = vehicle.date_created.split('T');

    const table = document.createElement('table');
    table.setAttribute('class', 'center');
    const tr = document.createElement('tr');

    const td1 = document.createElement('td');
    const td2 = document.createElement('td');

    td1.appendChild(idDiv);
    td1.appendChild(qrImage);

    td2.appendChild(row1);
    td2.appendChild(row2);
    td2.appendChild(row3);
    td2.appendChild(row4);
    td2.appendChild(row5);
    td2.appendChild(row6);
    td2.appendChild(row7);

    tr.appendChild(td1);
    tr.appendChild(td2);
    table.appendChild(tr);

    const css = [
      `
      @media print {
        @page {
          size: 10cm, 4cm;
          size: landscape;
        }
        body {
          height: 100%;
          width: 100%;
        }
        .hideOnPrint {
          display: none;
        }
        .center {
          margin-left: auto;
          margin-right: auto;
        }
        #qrImage {
          display: block;
          margin: 0 auto;
        }
        #idText {
          font-family: "Roboto";
          font-size: 18px;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
        }
        #carText {
          font-family: "Roboto";
          font-size: 14px;
          text-align: left;
          font-weight: bold;
        }
      }
      `,
      'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons',
    ];

    const d = new Printd();
    return d.print(table, css, '', (callback) => {
      const { iframe, launchPrint } = callback;
      const { contentWindow } = iframe;
      contentWindow.addEventListener('afterprint', () => { postRequest.object('/print', { type: part.type, id: part.id }); });
      launchPrint();
    });
  });
}

function printMultipleQrCodes(vehicle, supply, parts) {
  const table = document.createElement('table');
  table.setAttribute('class', 'center');

  parts.forEach((part) => {
    QrCode.toDataURL(part.id, (error, url) => {
      if (error) { console.log(error); }

      const qrImage = document.createElement('img');
      qrImage.setAttribute('id', 'qrImage');
      qrImage.setAttribute('src', url);
      qrImage.style.width = '100px';
      qrImage.style.height = '100px';
      const idDiv = document.createElement('div');
      const slicedId = part.id.slice(part.id.length - 6, part.id.length);
      idDiv.textContent = slicedId;
      idDiv.setAttribute('id', 'idText');

      const row1 = document.createElement('div');
      row1.setAttribute('id', 'carText');
      row1.textContent = vehicle.brand.title;

      const row2 = document.createElement('div');
      row2.setAttribute('id', 'carText');
      const startingDate = vehicle.model.startingDate
        ? new Date(vehicle.model.startingDate) : null;
      const endingDate = vehicle.model.endingDate
        ? new Date(vehicle.model.endingDate) : null;
      row2.textContent = `${vehicle.model.description} ${startingDate ? `${startingDate.getMonth() + 1}/${startingDate.getFullYear()}` : ''} - ${endingDate ? `${endingDate.getMonth() + 1}/${endingDate.getFullYear()}` : ''}`;

      const row3 = document.createElement('div');
      row3.setAttribute('id', 'carText');
      row3.textContent = vehicle.version.description;

      const row4 = document.createElement('div');
      row4.setAttribute('id', 'carText');
      row4.textContent = `${vehicle.version.engineCode || ''} / ${vehicle.version.power.kw} kw / ${vehicle.km ? `${vehicle.km} km` : ''}`;

      const row5 = document.createElement('div');
      row5.setAttribute('id', 'carText');
      row5.textContent = `${supply} / ${vehicle.transmissionType} / ${vehicle.transmissionLayout}`;

      const row6 = document.createElement('div');
      row6.setAttribute('id', 'carText');
      row6.textContent = `PRA: ${vehicle.pra || ''}`;

      const row7 = document.createElement('div');
      row7.setAttribute('id', 'carText');
      [row7.textContent] = vehicle.date_created.split('T');

      const tr1 = document.createElement('tr');
      tr1.setAttribute('class', 'spacer');

      const td1 = document.createElement('td');
      const td2 = document.createElement('td');

      td1.appendChild(idDiv);
      td1.appendChild(qrImage);

      td2.appendChild(row1);
      td2.appendChild(row2);
      td2.appendChild(row3);
      td2.appendChild(row4);
      td2.appendChild(row5);
      td2.appendChild(row6);
      td2.appendChild(row7);

      tr1.appendChild(td1);
      tr1.appendChild(td2);
      table.appendChild(tr1);
    });
  });

  const css = [
    `
    @media print {
      @page {
        size: 10cm, 4cm;
        size: landscape;
      }
      body {
        height: 100%;
        width: 100%;
      }
      .hideOnPrint {
        display: none;
      }
      .center {
        margin-left: auto;
        margin-right: auto;
      }
      .spacer {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      #qrImage {
        display: block;
        margin: 0 auto;
      }
      #idText {
        font-family: "Roboto";
        font-size: 18px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
      #carText {
        font-family: "Roboto";
        font-size: 14px;
        text-align: left;
        font-weight: bold;
      }
    }
    `,
    'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons',
  ];

  const d = new Printd();
  return d.print(table, css, '', (callback) => {
    const { iframe, launchPrint } = callback;
    const { contentWindow } = iframe;
    contentWindow.addEventListener('afterprint', () => {
      parts.forEach((part) => { postRequest.object('/print', { type: part.type, id: part.id }); });
    });
    launchPrint();
  });
}

export default {
  components: {
    'qr-code': VueQrCode,
  },
  data: () => ({
    dialog: false,
    loading: false,
    parts: [],
    vehicle: null,
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.loading = true;
      const { vehicle, parts } = data;
      this.vehicle = vehicle;

      getRequest('/car/parts', `ids=${parts.toString()}`)
        .then((result) => { this.parts = result; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    close() {
      this.dialog = false;
      this.parts = [];
      this.vehicle = null;
      this.$emit('close');
    },
    print() {
      printMultipleQrCodes(
        this.vehicle,
        this.getSupplyLocale(this.vehicle.version.supplyCode),
        this.parts,
      );
    },
    printSingle(part) {
      printSingleQrCode(
        this.vehicle,
        this.getSupplyLocale(this.vehicle.version.supplyCode),
        part,
      );
    },

    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.infoCar.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.infoCar.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.infoCar.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
