<template>
  <v-container>
    <v-layout row>
      <v-flex
        xs8
        class="mr-4 align-center"
      >
        <v-img
          v-if="image.src"
          :src="image.src"
          alt="Uploaded Image"
          max-height="600"
          contain
        />
        <div
          v-else
          class="default-image-text"
        >
          Test image will be shown here
        </div>
      </v-flex>

      <v-flex
        xs4
        class="ml-4 mt-4"
      >
        <h2 class="mt-4">
          Upload Image
        </h2>
        <v-form class="mt-4">
          <input
            ref="fileInput"
            type="file"
            accept="image/*"
            @change="handleFileUpload"
          >
          <v-text-field
            v-model="image.url"
            class="mt-4"
            label="Enter Image URL"
            outlined
          />
          <v-btn
            color="primary"
            class="mt-4"
            :loading="loading"
            @click="classifyImage"
          >
            Classify Image
          </v-btn>
          <div
            v-if="predictions.length > 0"
            class="mt-4"
          >
            <h3 class="mb-2 mt-2">
              Top 3 Predictions:
            </h3>
            <ul>
              <li
                v-for="(prediction, index) in predictions.slice(0, 3)"
                :key="index"
              >
                <strong>{{ prediction.tagName }}</strong>:
                {{ (prediction.probability * 100).toFixed(2) }}%
              </li>
            </ul>
          </div>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';

import { dataURLtoBlob } from '../utils/files';

export default {
  data() {
    return {
      image: {
        type: null, // 'string' | 'file'
        src: null,
        url: null,
      },
      loading: false,
      predictions: [],
    };
  },
  watch: {
    'image.url': {
      handler(value) {
        if (value) {
          this.image.type = 'string';
          this.image.src = value;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleFileUpload() {
      // Get the file input element using the ref
      const { fileInput } = this.$refs;

      if (fileInput.files.length > 0) {
        const image = fileInput.files[0];
        const reader = new FileReader();
        reader.onload = (event) => { this.image = { type: 'file', src: event.target.result, url: null }; };
        reader.readAsDataURL(image);
      }
    },
    classifyImage() {
      this.loading = true;
      const details = {
        url: this.image.type === 'string'
          ? 'https://imagerecognitionlekotech.cognitiveservices.azure.com/customvision/v3.0/Prediction/0eabb224-dc3c-451c-ac47-da1e814bc98a/classify/iterations/Iteration5/url'
          : 'https://imagerecognitionlekotech.cognitiveservices.azure.com/customvision/v3.0/Prediction/0eabb224-dc3c-451c-ac47-da1e814bc98a/classify/iterations/Iteration5/image',
        headers: {
          'Content-Type': this.image.type === 'string' ? 'application/json' : 'application/octet-stream',
          'Prediction-Key': '21c696823b3f40a889e06e10105e84b0',
        },
        data: this.image.type === 'string' ? { url: this.image.url } : dataURLtoBlob(this.image.src),
      };

      axios
        .post(details.url, details.data, { headers: details.headers })
        .then((response) => {
          this.predictions = response.data.predictions
            .sort((a, b) => b.probability - a.probability);
          this.predictions = this.predictions.slice(0, 3);
        })
        .catch((error) => { console.error('API Error:', error.message); })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>

<style scoped>
.default-image-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  background-color: #ccc;
  color: #555;
  font-size: 18px;
  font-weight: bold;
}
</style>
