import axios from 'axios';

import config from '../config';

const serverUri = config.getServerUri();

const object = (routeString, dataObj) => new Promise((resolve, reject) => {
  const { token } = localStorage;

  axios.post(`${serverUri}${routeString}`, dataObj, {
    headers: {
      authorization: token,
    },
  }).then((res) => {
    resolve(res);
  }).catch((err) => {
    reject(err);
  });
});

const formData = (routeString, queryString, dataObj) => new Promise((resolve, reject) => {
  const { token } = localStorage;

  axios.post(`${serverUri}${routeString}?${queryString}`, dataObj, {
    headers: {
      authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }).then((res) => {
    resolve(res);
  }).catch((err) => {
    reject(err);
  });
});

export default {
  object,
  formData,
};
