<template>
  <div>
    <v-dialog
      v-model="ebayDetailsModal"
      persistent
      width="550"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.ebay_details') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayDetailsModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loading"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <div>
          <v-alert
            :value="true"
            type="error"
            border="bottom"
            outline
            prominent
            text
          >
            {{ $t('institutions.form.ebay_condition') }}
          </v-alert>
        </div>
        <div>
          <v-text-field
            v-model="item.token"
            :label="$t('institutions.form.token')"
            color="red darken-1"
            prepend-icon="security"
          />
        </div>
        <div>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedTokenExpirationDate"
                :label="$t('institutions.form.tokenExpirationDate')"
                color="black"
                persistent-hint
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>

            <v-card class="d-md-flex">
              <!-- Date Picker -->
              <v-date-picker
                v-model="date"
                color="red darken-3"
                @input="updateTokenExpirationDate"
              />

              <!-- Time Picker -->
              <v-time-picker
                v-model="time"
                color="red darken-3"
                full-width
                format="24hr"
                @input="updateTokenExpirationDate"
              />
              <!-- <v-card-actions>
                    <v-btn text @click="saveDateTime">{{ $t('institutions.form.save') }}</v-btn>
                  </v-card-actions> -->
            </v-card>
          </v-menu>
        </div>
        <div>
          <v-text-field
            v-model="item.shippingId"
            :label="$t('institutions.form.shippingId')"
            color="red darken-1"
            prepend-icon="shop"
          />
        </div><div>
          <v-text-field
            v-model="item.paymentId"
            :label="$t('institutions.form.paymentId')"
            color="red darken-1"
            prepend-icon="payment"
          />
        </div>
        <div>
          <v-text-field
            v-model="item.returnId"
            :label="$t('institutions.form.returnId')"
            color="red darken-1"
            prepend-icon="refresh"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeEbayDetailsModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            :disabled="checkEbayDetails"
            @click="save"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayErrorDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in normalizedEbayResponse"
          :key="index"
          :value="true"
          type="error"
        >
          {{ item.ShortMessage }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="ebayErrorDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alert"
      max-width="500"
    >
      <v-alert
        v-model="alert"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.verification_success') }}
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  data: () => ({
    institution_id: null,
    ebayDetailsModal: false,
    item: null,
    dateMenu: false,
    date: null,
    time: null,
    ebayResponse: null,
    ebayErrorDialog: false,
    loading: false,
    alert: false,
    inst: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    checkEbayDetails() {
      return !(this.item.token &&
             this.item.tokenExpirationDate &&
             this.item.shippingId &&
             this.item.paymentId &&
             this.item.returnId);
    },
    formattedTokenExpirationDate() {
      if (!this.item.tokenExpirationDate) return '';
      const date = new Date(this.item.tokenExpirationDate);
      return date.toString().slice(0, 28);
    },
    normalizedEbayResponse() {
      if (this.ebayResponse && typeof this.ebayResponse === 'object' && !Array.isArray(this.ebayResponse)) {
        return [this.ebayResponse];
      }
      return this.ebayResponse || [];
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    async  openEbayDetailsModal(institution) {
      this.institution_id = institution._id;
      this.inst = institution;
      console.log('institution', institution.eBayDetails);
      this.item = await institution.eBayDetails || {
        token: '',
        tokenExpirationDate: '',
        shippingId: '',
        paymentId: '',
        returnId: '',
      };
      this.ebayDetailsModal = true;
      console.log('inside openEbayDetailsModal');
    },
    save() {
      console.log('item', this.item);
      this.loading = true;
      axios.put(
        `http://localhost:3000/institution/edit-ebay-details/${this.institution_id}`,
        { eBayDetails: this.item }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          console.log('res:', response.data);
          if (response && response.data && response.data.errorMsg) {
            this.ebayResponse = response.data.errorMsg;
            this.ebayErrorDialog = true;
          } else {
            this.alert = true;
            this.ebayDetailsModal = false;
            this.item = response.data && response.data.eBayDetails ? response.data.eBayDetails : {
              token: '',
              tokenExpirationDate: '',
              shippingId: '',
              paymentId: '',
              returnId: '',
            };
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error adding items to eBay:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeEbayDetailsModal() {
      this.ebayDetailsModal = false;
      this.$emit('fetchInstitutionDetails');
    },
    updateTokenExpirationDate() {
      if (this.date && this.time) {
        const [hours, minutes] = this.time.split(':');
        const fullDate = new Date(this.date);
        fullDate.setHours(hours, minutes, 0, 0);
        this.item.tokenExpirationDate = fullDate.toISOString(); 
      }
    },
    saveDateTime() {
      this.dateMenu = false;
    },
    closeDateTimeMenu() {
      this.dateMenu = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard:', text);
      }).catch((err) => {
        console.error('Failed to copy text:', err);
      });
    },
  },
};
</script>
