<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
  >
    <v-card flat>
      <!--TOOLBAR-->
      <v-toolbar
        flat
        color="red darken-2"
      >
        <v-toolbar-title> {{ $t('part_requests.assign_institutions') }} </v-toolbar-title>
      </v-toolbar>
      <v-select
        v-model="selectedInstitutions"
        :items="institutions"
        item-text="title"
        item-value="_id"
        chips
        multiple
        color="red"
        :label="$t('part_requests.assigned_institutions')"
        class="mt-3 ml-2 mr-2"
      />
      <v-card-actions>
        <v-spacer />
        <!--CLOSE-->
        <v-btn
          flat
          color="error"
          @click="close"
        >
          {{ $t('part_requests.close') }}
        </v-btn>
        <!--SAVE-->
        <v-btn
          flat
          color="success"
          @click="save"
        >
          {{ $t('part_requests.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    loadingAssignment: false,
    loadingInstitutions: false,
    selectedInstitutions: [],
    institutions: [],
    requestId: null,
  }),
  methods: {
    open(data) {
      console.log(data);
      this.dialog = true;
      this.getInstitutions();
      this.requestId = data.id;
      this.selectedInstitutions = data.institutions.map(i => i.id);
    },
    close() {
      this.dialog = false;
      this.requestId = null;
      this.selectedInstitutions = [];
      this.institutions = [];
    },
    save() {
      this.loadingAssignment = true;
      putRequest('/part-request/assign-institutions', `id=${this.requestId}`, {
        institutions: this.selectedInstitutions,
      }).then((response) => {
        console.log(response);
        this.loadingAssignment = false;
        this.$emit('saved');
        this.close();
      }).catch((error) => {
        console.log(error);
        this.loadingAssignment = false;
      });
    },
    getInstitutions() {
      this.loadingInstitutions = true;
      getRequest('/institution').then((response) => {
        console.log(response);
        this.institutions = response.institutions;
        this.loadingInstitutions = false;
      })
        .catch((error) => {
          console.log(error);
          this.loadingInstitutions = false;
        });
    },
  },
};
</script>
