<template>
  <div>
    <v-data-table
      :pagination.sync="pagination"
      :headers="headers"
      :items="orders"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :class="{tableRowColorSelected : props.index === activeRow}"
          class="tableRowColor"
          @click="activeRow = props.index"
        >
          <!----------------------------------------STATUS----------------------------------------->
          <td v-if="props.item.status === 1">
            <v-chip
              label
              outline
              color="success"
            >
              {{ $t('leko_employees.shippings.table.confirmed') }}
            </v-chip>
          </td>
          <td v-else-if="props.item.status === 2">
            <v-chip
              label
              outline
              color="success"
            >
              {{ $t('leko_employees.shippings.table.verified') }}
            </v-chip>
          </td>
          <td v-else-if="props.item.status === 3">
            <v-chip
              label
              outline
              color="primary"
            >
              {{ $t('leko_employees.shippings.table.shipped') }}
            </v-chip>
          </td>
          <td v-else-if="props.item.status === 4">
            <v-chip
              label
              outline
              color="error"
            >
              {{ $t('leko_employees.shippings.table.blocked') }}
            </v-chip>
          </td>

          <td v-else-if="props.item.status === 6">
            <v-chip
              label
              outline
              color="amber"
            >
              {{ $t('leko_employees.shippings.table.return_confirmed') }}
            </v-chip>
          </td>
          <td v-else-if="props.item.status === 7">
            <v-chip
              label
              outline
              color="primary"
            >
              {{ $t('leko_employees.shippings.table.returned') }}
            </v-chip>
          </td>
          <!------------------------------------------ID------------------------------------------->
          <td v-if="props.item.part_id.old_id && props.item.part_id.old_id !== undefined">
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog(props.item.part_id.old_id)"
            >
              <qr-code
                :size="60"
                :text="props.item.part_id.old_id"
              />
              {{ props.item.part_id.old_id.slice(
                props.item.part_id.old_id.length-6,
                props.item.part_id.old_id.length
              ) }}
            </v-layout>
          </td>
          <td
            v-else
            class="text-xs"
          >
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog(props.item.part_id._id)"
            >
              <qr-code
                :size="60"
                :text="props.item.part_id._id"
              />
              {{ props.item.part_id._id.slice(
                props.item.part_id._id.length-4,
                props.item.part_id._id.length
              ) }}
            </v-layout>
          </td>
          <!-----------------------------------------GROUP----------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.part_id.group"
                  class="text-xs font-weight-bold"
                >
                  {{ multilanguage(props.item.part_id.group.description) }}
                </span>
              </v-flex>
              <v-flex>
                <span
                  v-if="props.item.part_id.group"
                  class="text-xs font-italic"
                >
                  {{ multilanguage(props.item.part_id.group.subgroup.description) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.part_id.title"
                  class="text-xs font-weight-bold"
                >
                  {{ multilanguage(props.item.part_id.title) }}
                </span>
              </v-flex>
              <v-flex>
                <span
                  v-if="props.item.part_id.group"
                  class="text-xs font-italic"
                >
                  {{
                    positions(
                      props.item.part_id.group.item.positions.x,
                      props.item.part_id.group.item.positions.y
                    )
                  }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------MANUFACTURER CODE----------------------------------->
          <td class="text-xs">
            {{ props.item.part_id.manufacturer_code }}
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout row>
              <p style="display: none">
                {{ props.item.part_id.old_id }}
              </p>
              <img
                :src="`${server}/images/thumb?q=${props.item.part_id._id
                }-${props.item.part_id.images[0]}&date=${props.item.part_id.date_created}`"
                class="ma-1 pointer"
                height="75"
                width="100"
                @click="showImages(props.item.part_id._id, props.item.part_id.images)"
              >
            </v-layout>
          </td>
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            {{ props.item.part_id.notes }}
          </td>
          <!----------------------------------------QUALITY---------------------------------------->
          <td v-if="props.item.part_id.quality">
            <div style="width: 150px">
              <v-rating
                :value="props.item.part_id.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                small
              />
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          />
          <!---------------------------------------LOCATION---------------------------------------->
          <td
            v-if="props.item.part_id && props.item.part_id.shelf_data"
            class="text-xs"
          >
            <span>
              {{ props.item.part_id.shelf_data.shelf.room_name }} -
              {{ props.item.part_id.shelf_data.shelf.location_code }}:&nbsp;
              {{ props.item.part_id.shelf_data.shelf.x }},
              {{ props.item.part_id.shelf_data.shelf.y }}
            </span>

            <div class="text-no-wrap ">
              <span class="caption">Mag: </span>
              <span class="font-weight-bold caption">{{ props.item.part_id.shelf_data.repo }}</span>
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          >
            -
          </td>
          <!-----------------------------------------PRICE----------------------------------------->
          <td class="text-xs">
            <v-btn
              outline
              @click="openPriceDialog(props.item.price)"
            >
              {{ props.item.price.logistic_price }} {{ props.item.part_id.currency }}
            </v-btn>
          </td>
          <!-------------------------------------DATE CREATED-------------------------------------->
          <td>{{ props.item.part_id.date_created | moment }}</td>
          <!--------------------------------------ORDER DATE--------------------------------------->
          <td>{{ props.item.date_created | moment }}</td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout
              row
              align-center
            >
              <p style="display: none">
                {{ props.item.part_id._id }}
              </p>
              <v-btn
                icon
                @click="showBuyerDetails({
                  id: props.item._id,
                  buyerDetails: props.item.buyer_details,
                })"
              >
                <v-icon color="grey darken-2">
                  account_box
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="props.item.status === 2 || props.item.status === 3"
                icon
                @click="openActionsDialog(props.item)"
              >
                <v-icon color="grey darken-2">
                  compare_arrows
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="props.item.status !== 2"
                icon
                @click="shipped(props.item)"
              >
                <v-icon color="grey darken-2">
                  done
                </v-icon>
              </v-btn>
            </v-layout>
          </td>
          <!--------------------------------------------------------------------------------------->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';

import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    loading: false,
    activeRow: null,
    orders: [],
    totalItems: 0,
    pagination: {
      rowsPerPage: 5,
      page: 1,
    },
    headers: [
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
      {
        text: 'ID',
        sortable: true,
        value: '_id',
      },
      {
        text: 'Group',
        sortable: true,
        value: 'group',
      },
      {
        text: 'Title',
        sortable: true,
        value: 'title',
      },
      {
        text: 'Manufacturer Code',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: 'Images',
        sortable: false,
        value: 'old_id',
      },

      {
        text: 'Notes',
        sortable: false,
        value: 'notes',
      },
      {
        text: 'Quality',
        sortable: false,
        value: 'quality',
      },
      {
        text: 'Room',
        sortable: false,
        value: 'shelf_data.shelf.room_name',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'price',
      },
      {
        text: 'Date Created',
        sortable: false,
        value: 'part._id.date_created',
      },
      {
        text: 'Order Date',
        sortable: true,
        value: 'date_created',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '_id',
      },
    ],
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getOrders();
      },
      deep: true,
    },
    orders(value) {
      this.$emit('items', value);
    },
  },
  beforeMount() {
    this.getOrders();
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('leko_employees.shippings.table.status');
      this.headers[2].text = this.$i18n.t('leko_employees.shippings.table.group');
      this.headers[3].text = this.$i18n.t('leko_employees.shippings.table.title');
      this.headers[4].text = this.$i18n.t('leko_employees.shippings.table.manufacturer_code');
      this.headers[5].text = this.$i18n.t('leko_employees.shippings.table.images');
      this.headers[6].text = this.$i18n.t('leko_employees.shippings.table.notes');
      this.headers[7].text = this.$i18n.t('leko_employees.shippings.table.quality');
      this.headers[8].text = this.$i18n.t('leko_employees.shippings.table.location');
      this.headers[9].text = this.$i18n.t('leko_employees.shippings.table.price');
      this.headers[10].text = this.$i18n.t('leko_employees.shippings.table.date_created');
      this.headers[11].text = this.$i18n.t('leko_employees.shippings.table.order_date');
      this.headers[12].text = this.$i18n.t('leko_employees.shippings.table.actions');
    },
    getOrders(status) {
      this.loading = true;
      getRequest(
        '/order/shipping',
        `institution_id=${this.$store.getters.institutionId}&role=${
          this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage}&page=${
          this.pagination.page
        }&sort_by=${this.pagination.sortBy}&descending=${
          this.pagination.descending
        }&status=${status}`,
      )
        .then((response) => {
          console.log(response);
          this.orders = response.orders;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openQrDialog(id) {
      this.$emit('qr', id);
    },
    openPriceDialog(data) {
      this.$emit('price', data);
    },
    showBuyerDetails(data) {
      this.$emit('buyer-details', data);
    },
    showImages(id, images) {
      this.$emit('images', { type: 'string', id, images });
    },
    shipped(data) {
      const orderId = data._id;
      putRequest('/order/shipped', `order_id=${orderId}`)
        .then((response) => {
          console.log(response);
          this.getOrders();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openActionsDialog(data) {
      this.$emit('actions', data);
    },
    updatePagination() {
      this.activeRow = null;
    },
    multilanguage(textObject) {
      switch (this.$store.getters.language) {
        case 'en':
          return textObject.en;
        case 'it':
          return textObject.it;
        case 'sq':
          return textObject.al;
        default:
          return '';
      }
    },
    positions(x, y) {
      let posX = '';
      let posY = '';
      switch (this.$store.getters.language) {
        case 'en':
          if (x === 'sx.') {
            posX = 'left';
          } else if (x === 'dx.') {
            posX = 'right';
          } else {
            posX = '';
          }
          if (y === 'post.') {
            posY = 'back';
          } else if (y === 'ant.') {
            posY = 'front';
          } else {
            posY = '';
          }
          if (posX.length > 0 || posY.length > 0) { return `${posX}, ${posY}`; }
          return '';
        case 'it':
          if (x === 'sx.') {
            posX = 'sinistra';
          } else if (x === 'dx.') {
            posX = 'destra';
          } else {
            posX = '';
          }
          if (y === 'post.') {
            posY = 'posteriore';
          } else if (y === 'ant.') {
            posY = 'anteriore';
          } else {
            posY = '';
          }
          if (posX.length > 0 || posY.length > 0) { return `${posX}, ${posY}`; }
          return '';
        case 'sq':
          if (x === 'sx.') {
            posX = 'majtas';
          } else if (x === 'dx.') {
            posX = 'djathtas';
          } else {
            posX = '';
          }
          if (y === 'post.') {
            posY = 'mbrapa';
          } else if (y === 'ant.') {
            posY = 'para';
          } else {
            posY = '';
          }
          if (posX.length > 0 || posY.length > 0) { return `${posX}, ${posY}`; }
          return '';
        default:
          return '';
      }
    },
  },
};
</script>
