<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card flat>
      <v-layout
        column
        wrap
      >
        <!-------------------------------------CAMERA SCANNER-------------------------------------->
        <v-flex
          v-if="!physicalScanner"
          xs12
        >
          <qrcode-stream
            v-if="dialog"
            :camera="constraints"
            :paused="pauseCam"
            @decode="decodeId"
          />
        </v-flex>
        <!-------------------------------------PHYSICAL SCANNER------------------------------------>
        <v-flex
          v-if="physicalScanner"
          xs12
        >
          <v-layout
            mt-2
            mb-2
            justify-center
          >
            <div
              v-if="foundId === false && !physicalScannerError"
              class="ma-3 blue--text subheading"
            >
              {{ $t('parts.inventory.id_changer.physical_scanner') }}
            </div>
            <div
              v-if="physicalScannerError"
              class="ma-3 red--text subheading"
            >
              {{ $t('parts.inventory.id_changer.physical_scanner_error') }}
            </div>
          </v-layout>
        </v-flex>
        <!----------------------------------------------------------------------------------------->
        <v-flex xs12>
          <v-layout
            mt-2
            column
            align-center
            justify-center
          >
            <span
              v-if="foundId"
              class="green--text subheading"
            >
              {{ $t('parts.inventory.id_changer.found_message') }}
              {{ decodedString }}
            </span>
            <v-btn
              v-if="foundId"
              outline
              color="success"
              @click="changeId"
            >
              {{ $t('parts.inventory.id_changer.change_id') }}
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              flat
              icon
              @click="reScan"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              flat
              icon
              @click="switchScanner"
            >
              <v-icon>fas fa-qrcode</v-icon>
            </v-btn>
            <v-btn
              flat
              icon
              @click="switchQrCamera"
            >
              <v-icon>switch_camera</v-icon>
            </v-btn>
            <v-btn
              outline
              color="error"
              @click="close"
            >
              {{ $t('parts.inventory.id_changer.close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    front: false,
    pauseCam: false,
    foundId: false,
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    decodedString: '',
    physicalScanner: false,
    physicalScannerError: false,
    buffer: '',
  }),
  methods: {
    decodeId(value) {
      this.decodedString = value;
      this.foundId = true;
      this.pauseCam = true;
    },
    switchQrCamera() {
      this.physicalScanner = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: false,
      };
      console.log(this.constraints.video.facingMode);
    },
    switchScanner() {
      this.physicalScanner = true;
      document.addEventListener('keydown', this.listenToEvent);
    },
    listenToEvent(e) {
      // console.log(e);
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
      } else {
        this.decodeId(this.buffer);
        this.buffer = '';
      }
    },
    reScan() {
      this.decodedString = null;
      this.foundId = false;
      this.pauseCam = false;
      this.physicalScannerError = false;
    },
    changeId() {
      this.$emit('change', this.decodedString);
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.foundId = false;
      this.physicalScanner = false;
      this.physicalScannerError = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.$emit('close');
    },
  },
};
</script>
