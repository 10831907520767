<template>
  <div>
    <!-------------------------------------------TOOLBAR------------------------------------------->
    <toolbar />
    <!--------------------------------------------TABS--------------------------------------------->
    <v-layout
      v-if="loading"
      align-center
      justify-center
      ma-4
    >
      <v-progress-circular
        :size="80"
        color="red darken-3"
        indeterminate
      />
    </v-layout>

    <v-card
      v-else
      flat
    >
      <v-tabs
        v-model="active"
        color="transparent"
        slider-color="red darken-3"
      >
        <v-tab
          v-for="(item,index) in tabs"
          :key="index"
        >
          {{ item.title }}
        </v-tab>
        <v-tab-item
          v-for="(item,index) in tabs"
          :key="index"
        >
          <v-card flat>
            <v-card-text v-if="item.value === 'general'">
              <general
                ref="generalTab"
                :institution="institution"
              />
            </v-card-text>
            <v-card-text v-if="item.value === 'products'">
              <products :institution="institution" />
            </v-card-text>
            <v-card-text v-if="item.value == 'whatsAppChatBot'">
              <whatsapp :institution="institution" />
            </v-card-text>
            <v-card-text v-if="item.value == 'payments'">
              <payment :institution="institution" />
            </v-card-text>
            <v-card-text v-if="item.value == 'plates'">
              <plates :institution="institution" />
            </v-card-text>
            <v-card-text v-if="item.value == 'optIn'">
              <optIn :institution="institution" />
            </v-card-text>
            <v-card-text v-if="item.value == 'twoFactorAuth'">
              <twoFactorAuth :institution="institution" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>

      <v-card-actions>
        <v-btn
          depressed
          color="success"
          :loading="loadingSave"
          @click="save"
        >
          {{ $t('settings.save') }}
        </v-btn>
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          @click="next"
        >
          {{ $t('settings.nextPage') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Toolbar from '@/components/Settings/toolbar.vue';
import General from '@/components/Settings/general.vue';
import Products from '@/components/Settings/products.vue';
import WhatsApp from '@/components/Settings/whatsapp.vue';
import Payment from '@/components/Settings/payment.vue';
import Plates from '@/components/Settings/plates.vue';
import OptIn from '@/components/Settings/optIn.vue';
import TwoFactorAuth from '@/components/Settings/twoFactorAuth.vue';

import getRequest from '../api/getRequest';
import postRequest from '../api/postRequest';

export default {
  name: 'Settings',
  components: {
    toolbar: Toolbar,
    general: General,
    products: Products,
    whatsapp: WhatsApp,
    payment: Payment,
    plates: Plates,
    optIn: OptIn,
    twoFactorAuth: TwoFactorAuth,
  },
  data() {
    return {
      tabs: [
        {
          title: this.$i18n.t('settings.general'),
          value: 'general',
        },
        {
          title: this.$i18n.t('settings.products'),
          value: 'products',
        },
        {
          title: this.$i18n.t('settings.whatsAppChatBot'),
          value: 'whatsAppChatBot',
        },
        {
          title: this.$i18n.t('settings.payments'),
          value: 'payments',
        },
        {
          title: this.$i18n.t('settings.plates'),
          value: 'plates',
        },
        {
          title: this.$i18n.t('settings.optIn'),
          value: 'optIn',
        },
        {
          title: this.$i18n.t('settings.twoFactorAuth'),
          value: 'twoFactorAuth',
        },

      ],
      active: null,
      loading: false,
      loadingSave: false,
      institution: null,
    };
  },
  beforeMount() {
    this.getInstitution();
  },
  methods: {
    next() {
      const active = parseInt(this.active, 10);
      this.active = (active < 4 ? active + 1 : 0);
    },
    getInstitution() {
      this.loading = true;
      getRequest('/institution', `id=${this.$store.getters.institutionId}`)
        .then((response) => {
          [this.institution] = response.institution;
        })
        .catch((error) => {
          console.log(error);
          this.$emit('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.loadingSave = true;
      const formData = new FormData();

      const { generalTab } = this.$refs;
      if (generalTab.logo) {
        formData.append('logo', generalTab.logo.blob, `logo.${generalTab.logo.extension}`);
      }
      formData.append('institution', JSON.stringify(this.institution));
      postRequest.formData('/institution/edit', `id=${this.institution._id}`, formData)
        .then(() => {
          this.getInstitution();
          this.$store.dispatch('institutionDisplayFeaturesAction', this.institution.preferences.displayFeatures);
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingSave = false; });
    },
  },
};
</script>
