<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('quality.form.edit_quality') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('quality.form.create_quality') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-layout
                  align-center
                  justify-center
                  row
                >
                  <v-rating
                    v-model="quality.rating"
                    :label="$t('quality.form.rating')"
                    color="red"
                    background-color="red darken-4"
                  />
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="quality.title"
                  :label="$t('quality.form.title')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="quality.value"
                  :label="$t('quality.form.value')"
                  color="red darken-1"
                  type="number"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="quality.description"
                  :label="$t('quality.form.description')"
                  color="red darken-1"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click="close"
        >
          {{ $t('quality.form.close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click="save"
        >
          {{ $t('quality.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    edit: null,
    valid: false,
    quality: {},
  }),
  methods: {
    open(edit, data) {
      this.edit = edit;
      if (data) this.quality = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.edit = null;
      this.quality = {};
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.edit) {
          putRequest('/quality/', `id=${this.quality._id}`, this.quality)
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.$emit('save');
              this.close();
            }).catch((error) => {
              console.log(error);
              this.loading = false;
              this.$emit('error', error);
              this.close();
            });
        } else {
          postRequest.object('/quality/', this.quality)
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.$emit('save');
              this.close();
            }).catch((error) => {
              console.log(error);
              this.loading = false;
              this.$emit('error', error);
              this.close();
            });
        }
      }
    },
  },
};
</script>
