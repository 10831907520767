<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <!-----------------------------------------TITLE BAR----------------------------------------->
      <v-card-title class="redColor">
        <!--TITLE-->
        <span class="headline">
          {{ $t('parts.inventory.form.edit_part') }}
          {{ editId }}
        </span>

        <v-spacer />

        <!--CLOSE BUTTON-->
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <!-------------------------------------------FORM-------------------------------------------->
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!-------------------------------------SHIPMENT-------------------------------------->
              <v-flex
                xs12
                sm6
                lg6
              >
                <v-select
                  v-model="part.shipment_id"
                  :items="shipments"
                  :label="$t('parts.inventory.form.shipment')"
                  color="red darken-1"
                  outline
                  item-text="name"
                  item-value="_id"
                  disabled
                />
              </v-flex>
              <!-------------------------------------EBAY CODE------------------------------------->
              <v-flex
                xs12
                sm6
                lg6
              >
                <v-text-field
                  v-model="part.ebayCode"
                  :label="$t('parts.inventory.form.ebay_code')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!---------------------------------------NOTES--------------------------------------->
              <v-flex
                xs12
                sm4
                lg4
              >
                <v-text-field
                  v-model="part.notes"
                  :label="$t('parts.inventory.form.notes')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!----------------------------------------PRA---------------------------------------->
              <v-flex
                xs12
                sm4
                lg4
              >
                <v-text-field
                  v-model="part.pra"
                  :label="$t('parts.inventory.form.pra')"
                  color="red darken-1"
                  outline
                />
              </v-flex>

              <!---------------------------------------BRAND--------------------------------------->
              <v-flex
                xs12
                sm4
                lg4
              >
                <v-text-field
                  v-model="part.brand"
                  :label="$t('parts.inventory.form.brand')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!------------------------------------REPOSITORY------------------------------------->
              <v-flex
                xs6
                lg4
              >
                <v-select
                  v-model="repository"
                  :items="repositories"
                  :label="$t('parts.inventory.form.select_repository')"
                  color="red darken-1"
                  outline
                  item-text="name"
                  return-object
                />
              </v-flex>
              <!---------------------------------------ROOM---------------------------------------->
              <v-flex
                xs6
                lg3
              >
                <v-select
                  v-model="room"
                  :items="repository.rooms"
                  :label="$t('parts.inventory.form.select_room')"
                  color="red darken-1"
                  outline
                  item-text="name"
                  return-object
                />
              </v-flex>
              <!-------------------------------------LOCATION-------------------------------------->
              <v-flex
                xs4
                lg3
              >
                <v-select
                  v-model="location"
                  :items="room.locations"
                  :label="$t('parts.inventory.form.select_location')"
                  color="red darken-1"
                  outline
                  item-text="code"
                  return-object
                />
              </v-flex>
              <!------------------------------------POSITION X------------------------------------->
              <v-flex
                xs4
                lg1
              >
                <v-select
                  v-model="position_x"
                  :items="pos_x"
                  :label="$t('parts.inventory.form.select_x')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!------------------------------------POSITION Y------------------------------------->
              <v-flex
                xs4
                lg1
              >
                <v-select
                  v-model="position_y"
                  :items="pos_y"
                  :label="$t('parts.inventory.form.select_y')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!--------------------------------------QUALITY-------------------------------------->
              <v-flex xs12>
                <div class="subheading">
                  {{ $t('parts.inventory.form.quality') }}
                </div>

                <v-radio-group
                  v-model="part.quality"
                  row
                >
                  <v-radio
                    v-for="(quality, index) in qualities"
                    :key="index"
                    :value="quality._id"
                    color="red darken-2"
                  >
                    <template v-slot:label>
                      <v-rating
                        v-model="quality.rating"
                        color="amber"
                        small
                        background-color="amber"
                        readonly
                      />
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-flex>
              <!---------------------------------------PRICE--------------------------------------->
              <!--PRICE-->
              <v-flex
                xs6
                sm3
                :md2="$store.getters.institutionDisplayFeatures.partWeight"
              >
                <v-text-field
                  v-model="part.price"
                  :disabled="$store.getters.role === 'User'"
                  :label="$t('parts.inventory.form.price')"
                  outline
                  color="red darken-1"
                  type="number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <!--COST-->
              <v-flex
                xs6
                sm3
                :md2="$store.getters.institutionDisplayFeatures.partWeight"
              >
                <v-text-field
                  v-model="part.cost"
                  :disabled="$store.getters.role === 'User'"
                  :label="$t('parts.inventory.form.cost')"
                  color="red darken-1"
                  outline
                  type="number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex
                xs6
                sm3
                :md2="$store.getters.institutionDisplayFeatures.partWeight"
              >
                <v-text-field
                  v-if="part.infoCar && part.infoCar.price"
                  v-model="part.infoCar.price"
                  :label="$t('parts.inventory.form.reference_price')"
                  color="red darken-1"
                  outline
                  disabled
                />
                <v-text-field
                  v-else
                  :label="$t('parts.inventory.form.reference_price')"
                  color="red darken-1"
                  outline
                  disabled
                />
              </v-flex>
              <!--CURRENCY-->
              <v-flex
                xs6
                sm3
                :md2="$store.getters.institutionDisplayFeatures.partWeight"
              >
                <v-select
                  v-model="part.currency"
                  :disabled="$store.getters.role === 'User'"
                  :items="currency"
                  :label="$t('parts.inventory.form.currency')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!--------------------------------------WEIGHT--------------------------------------->
              <v-flex
                v-if="$store.getters.institutionDisplayFeatures.partWeight"
                xs8
                md2
              >
                <v-text-field
                  v-model="weight.value"
                  :label="$t('parts.inventory.form.weight')"
                  color="red darken-1"
                  outline
                  type="number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex
                v-if="$store.getters.institutionDisplayFeatures.partWeight"
                xs4
                md2
              >
                <v-select
                  v-model="weight.unit"
                  :items="weightUnits"
                  :label="$t('parts.inventory.form.unit')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!-------------------------------------WARRANTY-------------------------------------->
              <v-flex
                xs12
                sm4
              >
                <v-select
                  v-model="part.warranty_id"
                  :disabled="$store.getters.role === 'User'"
                  :items="warranties"
                  :label="$t('parts.inventory.form.warranty')"
                  color="red darken-1"
                  outline
                  item-text="title"
                  item-value="_id"
                >
                  <template v-slot:item="{ item }">
                    {{ item.return_days / 86400 }} {{ $t('warranties.days') }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ item.return_days / 86400 }} {{ $t('warranties.days') }}
                  </template>
                </v-select>
              </v-flex>
              <!---------------------------------MANUFACTURER CODE--------------------------------->
              <v-flex
                xs12
                sm4
              >
                <v-text-field
                  v-model="part.manufacturer_code"
                  :label="$t('parts.inventory.form.manufacturer_code')"
                  color="red darken-1"
                  outline
                />
              </v-flex>
              <!---------------------------------REPLACEMENT CODES--------------------------------->
              <v-flex
                xs12
                sm4
              >
                <v-text-field
                  v-if="part.infoCar && part.infoCar.replacementCodes"
                  v-model="part.infoCar.replacementCodes"
                  :label="$t('parts.inventory.form.replacement_codes')"
                  color="red darken-1"
                  outline
                  disabled
                />
                <v-text-field
                  v-else
                  :label="$t('parts.inventory.form.replacement_codes')"
                  color="red darken-1"
                  outline
                  disabled
                />
              </v-flex>
              <!--------------------------------------BUTTONS-------------------------------------->
              <v-flex xs12>
                <v-layout
                  align-center
                  justify-center
                  wrap
                >
                  <v-btn
                    v-if="!changePhoto"
                    outline
                    color="primary"
                    @click="changePhoto = true"
                  >
                    {{ $t('parts.inventory.form.change_photo') }}
                  </v-btn>
                  <v-btn
                    v-if="role === 'LekoAdmin' || role === 'LekoEmployee'"
                    outline
                    color="primary"
                    @click="openIdChanger"
                  >
                    {{ $t('parts.inventory.form.change_id') }}
                  </v-btn>
                  <v-btn
                    v-if="role === 'LekoAdmin' || role === 'LekoEmployee'"
                    outline
                    color="primary"
                    @click="changeInfoCarCode = !changeInfoCarCode"
                  >
                    {{ $t('parts.inventory.form.change_info_car_code') }}
                  </v-btn>
                  <v-btn
                    v-if="role === 'LekoAdmin' || role === 'LekoEmployee'"
                    outline
                    color="primary"
                    :disabled="!!part.infoCar && !!part.infoCar._id"
                    @click="setCustomTitle = !setCustomTitle"
                  >
                    {{ $t('parts.inventory.form.set_custom_title') }}
                  </v-btn>
                </v-layout>
              </v-flex>
              <!-----------------------------------INFOCAR CODE------------------------------------>
              <v-flex
                v-if="changeInfoCarCode === true"
                xs12
              >
                <v-text-field
                  v-model="infoCarCode"
                  :color="validInfoCarCode ? 'success': 'error'"
                  :append-icon="validInfoCarCode ? 'check_circle': 'error'"
                  :label="$t('parts.inventory.form.info_car_code')"
                  :loading="loadingInfoCar"
                  @keyup.enter="searchCode()"
                  @click:append="searchCode()"
                />
              </v-flex>
              <!-----------------------------------CUSTOM TITLE------------------------------------>
              <v-flex
                v-if="setCustomTitle === true"
                xs12
              >
                <v-autocomplete
                  v-model="customInfoCarTitle"
                  :loading="loadingCustomInfoCarTitle"
                  :items="infoCarTitles"
                  :search-input.sync="searchInfoCarTitle"
                  :label="$t('parts.inventory.form.custom_info_car_title')"
                  :filter="() => true"
                  :item-text="`title.${$store.getters.language}`"
                  item-value="partCode"
                  color="red darken-1"
                  return-object
                  :append-icon="customInfoCarTitle === null ? 'search' : 'keyboard_backspace'"
                  @click:append="customInfoCarTitle = null"
                />
              </v-flex>
              <!--------------------------------------CAMERA--------------------------------------->
              <v-flex
                v-if="changePhoto === true"
                xs12
              >
                <camera
                  v-if="dialog"
                  @takePicture="takePicture"
                />
              </v-flex>
              <!------------------------------------PHOTO GRID------------------------------------->
              <v-flex xs12>
                <v-container
                  grid-list-sm
                  fluid
                >
                  <v-layout
                    row
                    wrap
                  >
                    <!--NEW PHOTOS-->
                    <v-flex
                      v-for="(data, index) in tempImages"
                      :key="index"
                      xs6
                      sm4
                      md3
                      lg2
                      xl1
                      d-flex
                    >
                      <v-hover>
                        <v-card
                          slot-scope="{ hover }"
                          flat
                          tile
                          class="d-flex"
                        >
                          <v-img :src="data.dataUrl">
                            <v-layout
                              row
                              ma-1
                            >
                              <v-icon
                                v-if="hover"
                                x-large
                                color="primary"
                                class="pointer"
                                @click="viewImage('dataUrl', data.dataUrl)"
                              >
                                image
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="orange"
                                class="pointer"
                                @click="openImageEditor('dataUrl', index, data.dataUrl)"
                              >
                                edit
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="red"
                                class="pointer"
                                @click="deleteTempImage(index)"
                              >
                                delete_forever
                              </v-icon>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-flex>

                    <!--EXISTING PHOTOS-->
                    <v-flex
                      v-for="(image, index) in part.images"
                      :key="image.image"
                      xs6
                      sm4
                      md3
                      lg2
                      xl1
                      d-flex
                    >
                      <v-hover>
                        <v-card
                          slot-scope="{ hover }"
                          flat
                          tile
                          class="d-flex"
                          draggable="true"
                          :data-index="index"
                          @dragstart="dragStartHandler"
                          @dragover.prevent
                          @drop="dropHandler"
                          @dragend="dragEndHandler"
                        >
                          <v-img
                            :src="`${server}/images/?q=${image.thumb}`"
                          >
                            <v-layout
                              row
                              ma-1
                            >
                              <v-icon
                                v-if="hover"
                                x-large
                                color="primary"
                                class="pointer"
                                @click="viewImage('string', image)"
                              >
                                image
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="orange"
                                class="pointer"
                                @click="openImageEditor('string', index, image)"
                              >
                                edit
                              </v-icon>
                              <v-spacer />
                              <v-icon
                                v-if="hover"
                                x-large
                                color="red"
                                class="pointer"
                                @click="deleteImage(part._id, image, index)"
                              >
                                delete_forever
                              </v-icon>
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <!----------------------------------------------------------------------------------->
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.inventory.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid || (changeInfoCarCode && !validInfoCarCode)"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('parts.inventory.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Camera from '../camera.vue';

import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import ebayService from '../../services/ebayService';


export default {
  components: {
    camera: Camera,
  },
  props: ['hide'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    draggingIndex: -1,
    dropIndex: -1,
    valid: false,
    dialog: false,
    changePhoto: false,
    changeInfoCarCode: false,
    setCustomTitle: false,
    validInfoCarCode: false,
    customInfoCarTitle: null,
    searchInfoCarTitle: null,
    infoCarTitles: [],
    loading: false,
    loadingInfoCar: false,
    loadingCustomInfoCarTitle: false,
    tempImages: [],
    part: {},
    client: {},
    clients: [],
    currency: ['EUR'], // 'ALL'
    weight: { value: 0, unit: 'kg' },
    weightUnits: ['kg'],
    infoCarCode: null,
    tests: [],
    warranties: [],
    shipments: [],
    qualities: [],
    repositories: [],
    repository: {},
    room: {},
    location: {},
    pos_x: [],
    pos_y: [],
    position_x: null,
    position_y: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    role() {
      return this.$store.getters.role;
    },
    hideDialog() {
      return this.$props.hide;
    },
    editId() {
      if (this.part.old_id && this.part.old_id !== undefined) {
        const id = this.part.old_id;
        return id.slice(id.length - 6, id.length);
      } else if (this.part._id && this.part._id !== undefined) {
        const id = this.part._id;
        return id.slice(id.length - 6, id.length);
      }
      return null;
    },
  },
  watch: {
    hideDialog(value) {
      if (value) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    },
    dialog(value) {
      if (value) {
        this.getClients();
        this.getWarranties();
        this.getRepositories();
        this.getShipments();
        this.getQualities();
      }
    },
    location(value) {
      this.generateCoordinates(value.position_x, value.position_y);
    },
    changeInfoCarCode(value) {
      if (!value) {
        this.validInfoCarCode = false;
        this.infoCarCode = null;
      }
    },
    searchInfoCarTitle(value) {
      if (value) {
        this.loadingCustomInfoCarTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}&all=true`)
          .then((response) => { this.infoCarTitles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingCustomInfoCarTitle = false; });
      } else {
        this.customInfoCarTitle = null;
      }
    },
    customInfoCarTitle(value) {
      if (value) {
        this.part.infoCar = {
          description: {
            descriptionCode: value.partCode,
            positionCode: 'S000', // Can't determine the postition.
            descriptionId: value._id,
          },
        };
      } else { delete this.part.infoCar; }
    },
  },
  methods: {
    open(data) {
      console.log('openFuncrtion', data);
      this.part = data;
      if (data.weight) this.weight = data.weight;
      this.getRepository(data.repository_id);
      this.dialog = true;
    },
    close() {
      this.part = {};
      this.clients = [];
      this.warranties = [];
      this.repositories = [];
      this.qualities = [];
      this.tempImages = [];
      this.weight = { value: 0, unit: 'kg' };
      this.changePhoto = false;
      this.changeInfoCarCode = false;
      this.infoCarCode = null;
      this.setCustomTitle = false;
      this.customInfoCarTitle = null;
      this.searchInfoCarTitle = null;
      this.infoCarTitles = [];
      this.dialog = false;
      this.loading = false;
      this.$emit('close');
    },
    async save() {
      this.loading = true;
      // REPOSITORY ID's
      if (this.repository._id) {
        this.part.repository_id = this.repository._id;
        if (this.room._id) {
          this.part.room_id = this.room._id;
          if (this.location._id) {
            this.part.location_id = this.location._id;
            if (this.position_x && this.position_y) {
              this.getShelfId(this.position_x, this.position_y, (callback) => {
                this.part.shelf_id = callback;
              });
            }
          }
        }
      }
      this.part.weight = this.weight;
      // FORMDATA
      const formData = new FormData();
      // APPEND DATA
      formData.append('data', JSON.stringify({
        part: this.part,
        infoCar: {
          code: this.infoCarCode,
          changeInfoCarCode: !!(this.infoCarCode && this.changeInfoCarCode),
        },
      }));
      // APPEND PHOTOS
      if (this.tempImages.length > 0) {
        this.tempImages.forEach((image) => {
          formData.append('photos', image.blob, image.title);
        });
      }
      console.log('formData', formData);
      console.log('this.part: ', this.part);
      // POST
      // postRequest.formData('/part/edit/', `id=${this.part._id}`, formData)
      //   .then((response) => {

      //     if (this.part.eBayStatus) {
      //   const ebayServiceResponse =  ebayService.editItemEbay(this.part._id);
      //   if(!ebayServiceResponse.success){
      //     console.log('ebayServiceResponse',ebayServiceResponse)
      //     let msg = 'Could not edit item on ebay. '+ebayServiceResponse.msg.ShortMessage;
      //     this.$emit('show-error-modal',msg);
      //   }
      // }
      //     this.close();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.close();
      //   });
      try {
        // POST request with formData
        const response = await postRequest.formData('/part/edit/', `id=${this.part._id}`, formData);
        console.log(response);

        // Check eBay status after part edit
        if (this.$store.getters.institutionExportEbayLeko && this.part.eBayStatus) {
          const ebayServiceResponse = await ebayService.editItemEbay(this.part._id, this.$store.getters.institutionId);

          // Handle possible undefined or incorrect structure of msg
          if (!ebayServiceResponse.success) {
            const msg = `Impossibile modificare l'articolo su eBay. ${ebayServiceResponse.msg.LongMessage}`;
            this.$emit('show-error-modal', msg);
          }
        }

        // Close modal or do something else after success
        this.close();
      } catch (error) {
        console.error(error);
        this.close();
      } finally {
        this.loading = false;
      }
    },
    getClients() {
      getRequest(
        '/client/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          console.log(response);
          this.clients = response.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWarranties() {
      getRequest('/warranty/')
        .then((response) => {
          console.log(response);
          this.warranties = response.warranties;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getShipments() {
      getRequest(
        '/shipment/',
        `role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => {
          console.log(response);
          this.shipments = response.shipments;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRepository(id) {
      getRequest('/repository', `id=${id}`).then((response) => {
        this.repository = response.repository[0]; // eslint-disable-line prefer-destructuring
        this.repository.rooms.forEach((room) => {
          if (room._id === this.part.room_id) {
            this.room = room;
          }
        });
        this.room.locations.forEach((location) => {
          if (location._id === this.part.location_id) {
            this.location = location;
          }
        });
        this.location.shelves.forEach((shelf) => {
          if (shelf._id === this.part.shelf_id) {
            console.log(shelf);
            this.position_x = shelf.x;
            this.position_y = shelf.y;
          }
        });
      });
    },
    getRepositories() {
      getRequest(
        '/repository/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          this.repositories = response.repositories;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQualities() {
      getRequest('/quality/')
        .then((response) => {
          console.log(response);
          this.qualities = response.qualities;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateCoordinates(x, y) {
      const xArr = [];
      const yArr = [];
      for (let _x = 1; _x <= x; _x += 1) {
        xArr.push(_x);
      }
      for (let _y = 1; _y <= y; _y += 1) {
        yArr.push(_y);
      }
      this.pos_x = xArr;
      this.pos_y = yArr;
      this.scannerPos_x = xArr;
      this.scannerPos_y = yArr;
    },
    getShelfId(x, y, cb) {
      if (this.location.shelves && this.location.shelves.length > 0) {
        this.location.shelves.forEach((shelf) => {
          if (shelf.x === x && shelf.y === y) {
            cb(shelf._id);
          }
        });
      } else {
        cb('');
      }
    },
    openIdChanger() {
      this.$emit('open-id-changer');
    },
    changeId(id) {
      this.part.backup_id = this.part.old_id;
      this.part.old_id = id;
    },
    viewImage(type, data) {
      this.$emit('open-img-dialog', { type, data });
    },
    openImageEditor(type, index, data) {
      this.$emit('open-img-editor', { type, index, data });
    },
    setEditedImage(image) {
      let type = '';
      // Remove the existing unedited image.
      if (image.type === 'dataUrl') { type = 'n'; this.deleteTempImage(image.index); }
      if (image.type === 'string') { type = 'e'; this.deleteImage(null, null, image.index); }
      // Add the edited one.
      this.tempImages.push({ title: `${type}-${image.index}-${image.title}`, dataUrl: image.dataUrl, blob: image.blob });
    },
    takePicture(data) {
      const randomString = Math.random().toString(36).substring(9);
      const imgObj = {
        title: `${randomString}-photo${this.tempImages.length}.png`,
        dataUrl: data.dataUrl,
        blob: data.blob,
      };
      this.tempImages.push(imgObj);
    },
    deleteImage(id, image, index) {
      this.part.images.splice(index, 1);
    },
    deleteTempImage(index) {
      this.tempImages.splice(index, 1);
    },
    dragStartHandler(e) {
      this.draggingIndex = parseInt(e.currentTarget.getAttribute('data-index'), 10);
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', null);
    },
    dropHandler(e) {
      this.dropIndex = parseInt(e.currentTarget.getAttribute('data-index'), 10);
      if (this.draggingIndex !== this.dropIndex) {
        const draggedImage = this.part.images[this.draggingIndex];
        this.part.images.splice(this.draggingIndex, 1);
        this.part.images.splice(this.dropIndex, 0, draggedImage);
      }
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },
    dragEndHandler() {
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },
    searchCode() {
      this.loadingInfoCar = true;
      getRequest('/info-car/search', `code=${this.infoCarCode}`)
        .then((response) => {
          console.log(response);
          this.infoCarCode = response[0].oemCode;
          this.validInfoCarCode = true;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInfoCar = false; });
    },
  },
};
</script>
