<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!---------->
    <toolbar
      ref="toolbar"
      :items="datatableItems"
      @search="search"
      @advanced-search="advancedSearch"
      @clear-search="clearSearch"
    />
    <datatable
      :search="searchObject"
      @items="getDatatableItems"
      @movement="openMovementDialog"
      @images="openImagesCarousel"
      @details="openDetailsDialog"
      @return="openReturnDialog"
      @print="printReceipt"
      @qr="openQrDialog"
    />
    <movement-dialog ref="movementDialog" />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
    <details-dialog
      ref="detailsDialog"
      @close="''"
      @delete-applicability="getSales"
    />
    <return-dialog
      ref="returnDialog"
      @close="getSales"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { Printd } from 'printd';

import Toolbar from '@/components/Sales/toolbar.vue';
import Datatable from '@/components/Sales/datatable.vue';
import ReturnDialog from '@/components/Sales/returnDialog.vue';

import DetailsDialog from '@/components/detailsDialog.vue';
import MovementDialog from '@/components/movementDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';


export default {
  components: {
    toolbar: Toolbar,
    datatable: Datatable,
    'return-dialog': ReturnDialog,

    'details-dialog': DetailsDialog,
    'movement-dialog': MovementDialog,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    searchObject: {
      type: null,
      data: {},
    },
    datatableItems: [],
  }),
  mounted() {
    const { id } = this.$route.query;

    if (id) {
      const { toolbar } = this.$refs;
      toolbar.setId(id);
      toolbar.searchMethod();
    }
  },
  methods: {
    getSales() {
      // Fired when closing edit and actions dialog.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      }
    },
    /* Search ----------------------------------------------------------------------------------- */
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    /* Datatable Items -------------------------------------------------------------------------- */
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* Details Dialog --------------------------------------------------------------------------- */
    openDetailsDialog(data) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(data);
    },
    /* Return Dialog ---------------------------------------------------------------------------- */
    openReturnDialog(data) {
      const { returnDialog } = this.$refs;
      returnDialog.open(data);
    },
    openMovementDialog(data) {
      const { movementDialog } = this.$refs;
      movementDialog.open(data);
    },
    /* QR Dialog -------------------------------------------------------------------------------- */
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    /* Img Carousel ----------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* Print Receipt ---------------------------------------------------------------------------- */
    printReceipt(data) {
      console.log(data);
      const content = document.createElement('div');
      content.innerHTML = `
      <div class="main">
        <div class="header">
          <h3>LEKO Tech</h3>
          <p>
            ${this.$i18n.t('sales.table.date_created')}: ${moment(data.date_created).format('LLL')}
          </p>
        </div>
        <p>-----------------------------------------</p>
        <div class="row">
          <div class="column">
            <p>${data.part.title}: </p>
          </div>
          <div class="column">
            <span class="price">
              <p>${data.part.price} ${data.part.currency}</p>
            </span>
          </div>
        </div>
        <p>-----------------------------------------</p>
        <div class="row">
          <div class="column">
            <p>TOTAL: </p>
          </div>
          <div class="column">
            <span class="price">
              <p>${data.part.price} ${data.part.currency}</p>
            </span>
          </div>
        </div>
        <p>-----------------------------------------</p>
        <div class="footer">
          <p>${this.$i18n.t('sales.client')}: ${data.client.name} ${data.client.surname}</p>
          <p>${this.$i18n.t('sales.seller')}: ${data.user.name}</p>
          <h4>
            ${this.$i18n.t('sales.table.return_date')}: ${moment(data.return_date).format('LL')}
          </h4>
        </div>
      </div>
      `;
      const css = [`
        .main {
          font-family: "Verdana";
          max-width: 300px;
          margin: 0 auto;
        }
        .header {
          text-align: center;
        }
        .footer {
          text-align: center;
        }
        .row {
          display: flex;
        }
        .column {
          width: 100%;
          float: left;
          display: 50%;
        }
        .price {
          float: right;
        }
      `];
      const d = new Printd();
      d.print(content, css);
    },
  },
};
</script>
