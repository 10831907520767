<template>
  <div>
    <v-data-table
      :pagination.sync="pagination"
      :headers="$store.getters.role === 'LekoAdmin' ? headersAdmin : headers"
      :items="parts"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          v-if="props.item.order && props.item.order.id"
          :class="{tableRowColorSelected : props.index === activeRow}"
          class="tableRowColor"
          @click="activeRow = props.index"
        >
          <!------------------------------------------ID------------------------------------------->
          <!--OLD ID-->
          <td v-if="props.item.old_id && props.item.old_id !== undefined">
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item.old_id,
                type: 'inventory',
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item.old_id"
              />
              {{ props.item.old_id.slice(props.item.old_id.length-6, props.item.old_id.length) }}
            </v-layout>
          </td>
          <!--NEW ID-->
          <td
            v-else
            class="text-xs"
          >
            <v-layout
              class="pointer"
              ma-1
              align-center
              justify-center
              column
              @click="openQrDialog({
                id: props.item._id,
                type: 'inventory',
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item._id"
              />
              {{ props.item._id.slice(props.item._id.length-4, props.item._id.length) }}
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout
              column
              align-center
            >
              <p style="display: none">
                {{ props.item._id }}
              </p>
              <v-btn
                icon
                @click="showBuyerDetails({
        id: props.item.order.id._id,
        checkout_information: props.item.order.id.checkout_information,
        buyerDetails: props.item.order.id.buyer_details,
      })"
              >
                <v-icon color="grey darken-2">
                  account_box
                </v-icon>
              </v-btn>

              <v-btn
                :disabled="props.item.order.id.status !== 0
        && props.item.order.id.status !== 5
        && props.item.order.id.status !== 7"
                icon
                @click="openActionsDialog(props.item)"
              >
                <v-icon color="grey darken-2">
                  compare_arrows
                </v-icon>
              </v-btn>
            </v-layout>
          </td>

          <!--------------------------------------ORDER DATE--------------------------------------->
          <td>{{ props.item.order.id.date_created | moment }}</td>
          <!----------------------------------------ORIGIN----------------------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <v-icon
                v-if="props.item.order
                  && props.item.order.id
                  && props.item.order.id.origin
                  && props.item.order.id.origin === 'whatsapp'"
              >
                fab fa-whatsapp
              </v-icon>
              <img
                v-else
                height="32"
                width="32"
                src="@/assets/icons/online-shopping.png"
              >
            </v-layout>
          </td>
          <!--------------------------------------LEKO GROUP--------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.group"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.group[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------GROUP----------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.category"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.category[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout column>
              <v-flex v-if="props.item.infoCar && props.item.infoCar.description">
                <span
                  v-if="props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.title"
                  class="text-xs font-weight-bold"
                >
                  {{ props.item.infoCar.description.descriptionId.title[$store.getters.language] }}
                </span>
                <span
                  v-if="props.item.infoCar.description.positionCode"
                  class="text-xs"
                >
                  {{ getPosition(props.item.infoCar.description.positionCode) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------MANUFACTURER CODE----------------------------------->
          <td class="text-xs">
            {{ props.item.manufacturer_code }}
          </td>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <td
            v-if="$store.getters.role === 'LekoAdmin' && props.item.institution_id"
            class="text-xs"
          >
            {{ props.item.institution_id.title }}
          </td>
          <td v-else-if="$store.getters.role === 'LekoAdmin' && !props.item.institution_id" />
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout row>
              <p style="display: none">
                {{ props.item.old_id }}
              </p>
              <img
                :src="`${server}/images?q=${props.item.images && props.item.images.length
                  ? props.item.images[0].thumb : ''}`"
                class="ma-1 pointer"
                height="75"
                width="100"
                @click="showImages(props.item.images)"
              >
            </v-layout>
          </td>
          <!-----------------------------------------PRICE----------------------------------------->
          <td class="text-xs">
            <v-btn
              outline
              @click="openPriceDialog(props.item.order.id.price)"
            >
              {{ props.item.order.id.price.logistic_price }} {{ props.item.currency }}
            </v-btn>
          </td>
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            {{ props.item.notes }}
          </td>
          <!---------------------------------------LOCATION---------------------------------------->
          <td
            v-if="props.item.shelf_data"
            class="text-xs"
          >
            <span>
              {{ props.item.shelf_data.shelf.room_name }} -
              {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},
              {{ props.item.shelf_data.shelf.y }}
            </span>

            <div class="text-no-wrap ">
              <span class="caption">Mag: </span>
              <span class="font-weight-bold caption">{{ props.item.shelf_data.repo }}</span>
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          >
            -
          </td>
          <!---------------------------------------SHIPMENT---------------------------------------->
          <td v-if="props.item.shipment_id && props.item.shipment_id.name">
            {{ props.item.shipment_id.name }}
          </td>
          <td v-else>
            -
          </td>
          <!----------------------------------------QUALITY---------------------------------------->
<!--          <td v-if="props.item.quality">-->
<!--            <div style="width: 75px">-->
<!--              <v-rating-->
<!--                :value="props.item.quality.rating"-->
<!--                color="red"-->
<!--                background-color="red darken-4"-->
<!--                readonly-->
<!--                dense-->
<!--                size="12"-->
<!--              />-->
<!--            </div>-->
<!--          </td>-->
<!--          <td v-else />-->
          <!-------------------------------------DATE CREATED-------------------------------------->

          <!----------------------------------------STATUS----------------------------------------->
          <td class="pt-2 pb-1">
          <div v-if="props.item.quality">
            <div style="width: 150px">
              <v-rating
                :value="props.item.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                size="14"
              />
            </div>
          </div>
          <div v-else />
            <div class="pl-2">{{ props.item.date_created | moment }}</div>
          <div v-if="props.item.order.id.status === 0">
            <v-chip
              label
              outline
              color="amber"
            >
              {{ $t('parts.status.waiting_confirmation') }}
            </v-chip>
          </div>
          <div v-else-if="props.item.order.id.status === 1">
            <v-chip
              label
              outline
              color="success"
            >
              {{ $t('parts.status.confirmed') }}
            </v-chip>
          </div>
          <div v-else-if="props.item.order.id.status === 2">
            <v-chip
              label
              outline
              color="success"
            >
              {{ $t('parts.status.verified') }}
            </v-chip>
          </div>
          <div v-else-if="props.item.order.id.status === 3">
            <v-chip
              label
              outline
              color="primary"
            >
              {{ $t('parts.status.shipped') }}
            </v-chip>

          </div>
          <div v-else-if="props.item.order.id.status === 4">
            <v-chip
              label
              outline
              color="error"
            >
              {{ $t('parts.status.blocked') }}
            </v-chip>

          </div>
          <div v-else-if="props.item.order.id.status === 5">
            <v-chip
              label
              outline
              color="amber"
            >
              {{ $t('parts.status.waiting_return_confirmation') }}
            </v-chip>

          </div>
          <div v-else-if="props.item.order.id.status === 6">
            <v-chip
              label
              outline
              color="amber"
            >
              {{ $t('parts.status.return_confirmed') }}
            </v-chip>
          </div>
          <div v-else-if="props.item.order.id.status === 7">
            <v-chip
              label
              outline
              color="amber"
            >
              {{ $t('parts.status.returned') }}
            </v-chip>

          </div>
          </td>
          <!--------------------------------------------------------------------------------------->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    activeRow: null,
    parts: [],
    totalItems: 0,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    headers: [
      {
        text: '',
        sortable: true,
        value: '_id',
      },
      {
        text: '',
        sortable: false,
        value: 'actions',
      },
      {
        text: '',
        sortable: true,
        value: 'order.id.date_created',
      },
      {
        text: '',
        sortable: true,
        value: 'origin',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: '',
        sortable: true,
        value: 'group',
      },
      {
        text: '',
        sortable: true,
        value: 'title',
      },
      {
        text: '',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: '',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: false,
        value: 'notes',
      },
      {
        text: '',
        sortable: true,
        value: 'shelf_data.shelf.room_name',
      },
      {
        text: '',
        sortable: true,
        value: 'shipment_id',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
    headersAdmin: [
      {
        text: '',
        sortable: true,
        value: '_id',
      },
      {
        text: '',
        sortable: false,
        value: 'actions',
      },
      {
        text: '',
        sortable: true,
        value: 'order.id.date_created',
      },
      {
        text: '',
        sortable: true,
        value: 'origin',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: '',
        sortable: true,
        value: 'group',
      },
      {
        text: '',
        sortable: true,
        value: 'title',
      },
      {
        text: '',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: '',
        sortable: false,
        value: 'institution_id',
      },
      {
        text: '',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: true,
        value: 'price',
      },
      {
        text: '',
        sortable: false,
        value: 'notes',
      },
      {
        text: '',
        sortable: true,
        value: 'shelf_data.shelf.room_name',
      },
      {
        text: '',
        sortable: true,
        value: 'shipment_id',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: '',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
  }),
  computed: {
    server() { return this.$serverUri; },
    searchObject() { return this.$props.search; },
  },
  watch: {
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getParts();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getParts();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data);
        } else {
          console.log('search type is invalid');
        }
      },
    },
    parts(value) {
      this.$emit('items', value);
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.table.qr_code');
      this.headers[1].text = this.$i18n.t('parts.table.actions');
      this.headers[2].text = this.$i18n.t('parts.table.order_date');
      this.headers[3].text = this.$i18n.t('parts.table.origin');
      this.headers[4].text = this.$i18n.t('parts.table.leko_group');
      this.headers[5].text = this.$i18n.t('parts.table.group');
      this.headers[6].text = this.$i18n.t('parts.table.title');
      this.headers[7].text = this.$i18n.t('parts.table.manufacturer_code');
      this.headers[8].text = this.$i18n.t('parts.table.images');
      this.headers[9].text = this.$i18n.t('parts.table.price');
      this.headers[10].text = this.$i18n.t('parts.table.notes');
      this.headers[11].text = this.$i18n.t('parts.table.location');
      this.headers[12].text = this.$i18n.t('parts.table.shipment');
      // this.headers[13].text = this.$i18n.t('parts.table.quality');
      this.headers[13].text = this.$i18n.t('parts.table.date_created');
      // this.headers[14].text = this.$i18n.t('parts.table.status');
      this.headersAdmin[0].text = this.$i18n.t('parts.table.qr_code');
      this.headersAdmin[1].text = this.$i18n.t('parts.table.actions');
      this.headersAdmin[2].text = this.$i18n.t('parts.table.order_date');
      this.headersAdmin[3].text = this.$i18n.t('parts.table.origin');
      this.headersAdmin[4].text = this.$i18n.t('parts.table.leko_group');
      this.headersAdmin[5].text = this.$i18n.t('parts.table.group');
      this.headersAdmin[6].text = this.$i18n.t('parts.table.title');
      this.headersAdmin[7].text = this.$i18n.t('parts.table.manufacturer_code');
      this.headersAdmin[8].text = this.$i18n.t('parts.table.institution');
      this.headersAdmin[9].text = this.$i18n.t('parts.table.images');
      this.headersAdmin[10].text = this.$i18n.t('parts.table.price');
      this.headersAdmin[11].text = this.$i18n.t('parts.table.notes');
      this.headersAdmin[12].text = this.$i18n.t('parts.table.location');
      this.headersAdmin[13].text = this.$i18n.t('parts.table.shipment');
      // this.headersAdmin[14].text = this.$i18n.t('parts.table.quality');
      this.headersAdmin[14].text = this.$i18n.t('parts.table.date_created');
      // this.headersAdmin[15].text = this.$i18n.t('parts.table.status');
    },
    getParts() {
      this.loading = true;
      getRequest(
        '/part/ordered/datatable',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.parts = response.parts;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    regularSearch(data) {
      this.loading = true;
      getRequest(
        '/part/ordered/search/regular',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&language=${this.$store.getters.language}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    advancedSearch(data) {
      this.loading = true;
      getRequest(
        '/part/ordered/search/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&brand=${data.advancedSearchFields.brand
        }&model=${data.advancedSearchFields.model
        }&version=${data.advancedSearchFields.version
        }&institution=${data.advancedSearchFields.institution
        }&shipment=${data.advancedSearchFields.shipment
        }&status=${data.advancedSearchFields.status
        }&quality=${data.advancedSearchFields.quality
        }&price=${data.advancedSearchFields.price
        }&start_date=${data.advancedSearchFields.startDate
        }&end_date=${data.advancedSearchFields.endDate}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    details(data) {
      this.$emit('details', data);
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    openPriceDialog(data) {
      this.$emit('price', data);
    },
    showBuyerDetails(data) {
      this.$emit('buyer-details', data);
    },
    showImages(images) {
      this.$emit('images', { type: 'string', images });
    },
    openActionsDialog(data) {
      this.$emit('actions', data);
    },
    updatePagination() {
      this.activeRow = null;
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
  },
};
</script>
