export default {
  zoomIn(zoomLevel, event, rotation) {
    const image = event.currentTarget;
    const bounds = image.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    const centerX = bounds.width / 2;
    const centerY = bounds.height / 2;
    const deltaX = (centerX - x) / centerX;
    const deltaY = (centerY - y) / centerY;
    image.style.transform = `scale(${zoomLevel}) translate(${deltaX * 30}%, ${deltaY * 30}%) rotate(${rotation}deg) `;
  },

  zoomOut(event,rotation) {
    this.zoomLevel = 1;
    const image = event.currentTarget;
    image.style.transform = `rotate(${rotation}deg)`;
  },
};

