<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-center
      >
        <!------------------------------------------TITLE------------------------------------------>
        <v-toolbar-title class="hidden-xs-only">
          {{ $t("leko_employees.approve_parts.label") }}
        </v-toolbar-title>
        <!----------------------------------------------------------------------------------------->
        <v-divider
          class="mx-5 hidden-xs-only"
          inset
          vertical
        />
        <!-------------------------------------EXPORT BUTTONS-------------------------------------->
        <download-excel
          :fetch="exportData"
          type="xls"
          name="ApproveParts"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark
          >
            {{ $t('leko_employees.approve_parts.export') }}
            <v-icon right>
              fas fa-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <!--
        <download-excel
          :fetch="exportData"
          type="csv"
          name="ApproveParts"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark>
            {{ $t('leko_employees.approve_parts.export') }}
            <v-icon right>fas fa-file-csv</v-icon>
          </v-btn>
        </download-excel>
        -->
        <!----------------------------------------------------------------------------------------->
        <!-- <v-spacer class="hidden-md-and-down"/> -->
        <!--------------------------------------SEARCH FIELD--------------------------------------->
        <v-text-field
          v-model="search"
          :class="{'ml-5' : $vuetify.breakpoint.lgAndUp}"
          :label="$t('leko_employees.approve_parts.search')"
          flat
          solo
          color="red darken-1"
          append-icon="search"
          single-line
          hide-details
          @keyup.enter="searchMethod"
          @click:append="searchMethod"
        />
        <!----------------------------------------------------------------------------------------->

        <!--SEARCH TYPE MD-AND-UP-->
        <v-flex
          ml-2
          md2
          lg1
          class="hidden-sm-and-down"
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
          />
        </v-flex>

        <!--ADVANCED MD-AND-UP-->
        <v-btn
          class=" mr-5 hidden-sm-and-down"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('leko_employees.approve_parts.advanced') }}
        </v-btn>

        <!--
        <v-btn
          dark
          small
          @click="searchById = !searchById"
        >
          <v-icon left>
            {{ searchById ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('leko_employees.approve_parts.id_only') }}
        </v-btn>
        -->
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-md-and-down" />
        <!-----------------------------------AUTOMATIC APPROVAL------------------------------------>
        <v-btn
          dark
          @click="automaticApproval"
        >
          {{ $t('leko_employees.approve_parts.automatic_approval.label') }}
        </v-btn>
        <!----------------------------------------------------------------------------------------->
      </v-layout>
    </v-toolbar>
    <div class="redColor">
      <!------------------------------------------------------------------------------------------->
      <v-layout
        align-center
        justify-center
        :class="{ 'pt-1 pb-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-spacer />

        <!--SEARCH TYPE SM-AND-DOWN-->
        <v-flex
          xs4
          sm2
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
            class="hidden-md-and-up"
          />
        </v-flex>

        <!--ADVANCED SM-AND-DOWN-->
        <v-btn
          class="hidden-md-and-up"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('leko_employees.approve_parts.advanced') }}
        </v-btn>

        <v-spacer />
      </v-layout>
      <!------------------------------------------------------------------------------------------->
      <div v-if="advancedSearch">
        <v-layout
          row
          color="red darken-3"
          class="pb-2"
          wrap
        >
          <!--------------------------------------INSTITUTION-------------------------------------->
          <v-flex
            xs12
            sm6
          >
            <v-autocomplete
              v-model="institution"
              :items="institutions"
              :loading="loadingInstitution"
              :label="$t('parts.advanced_search.institution')"
              item-text="title"
              item-value="_id"
              flat
              solo
              color="red darken-1"
              class="ml-2 mr-2"
            />
          </v-flex>
          <!--------------------------------SENT FOR APPROVAL DATE--------------------------------->
          <v-flex
            xs6
            sm3
          >
            <!--STARTING DATE-->
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDate"
                  :label="$t('parts.advanced_search.starting_date')"
                  :hint="$t('parts.advanced_search.select_starting_date')"
                  solo
                  flat
                  color="black"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  class="ml-2 mr-2"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                color="red darken-3"
                @input="startDateMenu = false"
              />
            </v-menu>
          </v-flex>
          <v-flex
            xs6
            sm3
          >
            <!--ENDING DATE-->
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDate"
                  :label="$t('parts.advanced_search.ending_date')"
                  :hint="$t('parts.advanced_search.select_ending_date')"
                  solo
                  flat
                  color="black"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  class="ml-2 mr-2"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                color="red darken-3"
                @input="endDateMenu = false"
              />
            </v-menu>
          </v-flex>
        </v-layout>

        <v-layout
          align-center
          justify-center
          row
          wrap
        >
          <v-btn
            dark
            color="grey darken-4"
            @click="clearSearchFields"
          >
            {{ $t('parts.advanced_search.clear') }}
            <v-icon right>
              clear_all
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="grey darken-4"
            @click="searchMethod"
          >
            {{ $t('parts.advanced_search.search') }}
            <v-icon right>
              search
            </v-icon>
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel';
import getRequest from '../../api/getRequest';

export default {
  components: {
    'download-excel': JsonExcel,
  },
  props: ['items'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    search: '',
    searchTypes: [
      { label: 'Other', value: 'other' },
      { label: 'ID', value: 'id' },
      { label: 'PRA', value: 'pra' },
    ],
    searchType: 'other',
    advancedSearch: false,
    institution: null,
    institutions: [],
    loadingInstitution: false,
    startDateMenu: false,
    startDate: '',
    endDateMenu: false,
    endDate: '',
  }),
  watch: {
    search(value) {
      if (value.length === 0) {
        this.$emit('clear-search');
      }
    },
  },
  created() {
    this.getInstitutions();
    this.getLocale();
  },
  methods: {
    searchMethod() {
      if (this.advancedSearch) {
        this.$emit('adv-search', {
          searchField: this.search,
          searchType: this.searchType,
          institution: this.institution,
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else if (this.search.length > 0) {
        this.$emit('search', {
          searchField: this.search,
          searchType: this.searchType,
        });
      } else { console.log('Invalid search params.'); }
    },
    clearSearchFields() {
      this.institution = null;
      this.search = '';
      this.startDate = '';
      this.endDate = '';
      this.searchMethod();
    },
    exportData() {
      const items = [];
      this.$props.items.forEach((item) => {
        const itemObject = {
          ID: item._id,
          'Institution ID': item.institution_id.title,
          User: `${item.user_id.name} ${item.user_id.surname}`,
          Shipment: item.shipment_id.name,
        };
        items.push(itemObject);
      });
      return items;
    },
    automaticApproval() {
      this.$emit('automatic-approval');
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/')
        .then((response) => { this.institutions = response.institutions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitution = false; });
    },
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('parts.inventory.other');
      this.searchTypes[1].label = this.$i18n.t('parts.inventory.id');
      this.searchTypes[2].label = this.$i18n.t('parts.inventory.pra');
    },
  },
};
</script>
