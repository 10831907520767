<template>
  <v-app>
    <global-events @mousemove="refresh" />
    <!--------------------------------------NAVIGATION DRAWER-------------------------------------->
    <v-navigation-drawer
      v-if="logged == true"
      v-model="drawer"
      :mini-variant="mini"
      dark
      fixed
      clipped
      app
      width="320"
      mini-variant-width="112"
    >
      <!-- USER DETAILS (SM AND DOWN) -->
      <v-layout
        v-if="this.$vuetify.breakpoint.smAndDown"
        column
        class="grey darken-4 pa-1"
      >
        <v-flex>
          <span class="white--text body-2">{{ $store.getters.userName }}</span>
        </v-flex>
        <v-flex>
          <span class="white--text body-2">{{ getRole($store.getters.role) }}</span>
        </v-flex>
        <v-flex>
          <span class="white--text body-2">
            {{ $t('approved_parts') }}: {{ $store.getters.approvedPartsNumber }}
          </span>
        </v-flex>
      </v-layout>

      <!-- NAVIGATION LIST -->
      <v-list>
        <!------------------------------------------HOME------------------------------------------->
        <v-list-tile
          :href="items.home.href"
          :to="{name: items.home.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-tachometer-alt</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ items.home.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!------------------------------------------PARTS------------------------------------------>
        <!-- <v-list-group
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :value="true"
        > -->
        <!--MANAGE PARTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          slot="activator"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.title }} </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip
              v-if="!mini"
              label
              small
              color="primary"
              text-color="white"
            >
              {{ $store.getters.totalParts }}
            </v-chip>
          </v-list-tile-action>
        </v-list-tile>
        <!--INSERT PARTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href"
          :to="{name: items.parts.href}"
          active-class="title black--text redColor"
          class="menuList listHover blueText"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/insert_parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle }} </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip
              v-if="!mini"
              label
              small
              color="primary"
              text-color="white"
            >
              {{ $store.getters.temporaryParts }}
            </v-chip>
          </v-list-tile-action>
        </v-list-tile>
        <!--INVENTORY-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href1"
          :to="{name: items.parts.href1}"
          active-class="title black--text redColor"
          class="menuList listHover blueText"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/inventory.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle1 }} </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip
              v-if="!mini"
              label
              small
              color="primary"
              text-color="white"
            >
              {{ $store.getters.inventory }}
            </v-chip>
          </v-list-tile-action>
        </v-list-tile>
        <!--DAMAGED PARTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href3"
          :to="{name: items.parts.href3}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/damaged_parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle3 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--LOST PARTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href4"
          :to="{name: items.parts.href4}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/lost_parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle4 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--RECYCLED PARTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href5"
          :to="{name: items.parts.href5}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/recycled_parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle5 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--MOVEMENTS-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href6"
          :to="{name: items.parts.href6}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/movements.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle6 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-- </v-list-group> -->
        <!------------------------------------------SALES------------------------------------------>
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.sales.href"
          :to="{name: items.sales.href}"
          active-class="title black--text redColor"
          class="menuList listHover blueText"
        >
          <v-list-tile-action>
            <v-icon>fas fa-money-check-alt</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.sales.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--------------------------------------ORDERED PARTS-------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.parts.href2"
          :to="{name: items.parts.href2}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/ordered_parts.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.parts.subtitle2 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--------------------------------------PART REQUESTS-------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "

          :href="items.partRequests.href"
          :to="{name: items.partRequests.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-shopping-bag</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.partRequests.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--------------------------------------RESERVATIONS--------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "

          :href="items.reservations.href"
          :to="{name: items.reservations.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-calendar-alt</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.reservations.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!----------------------------------------VEHICLES----------------------------------------->
        <!-- <v-list-group
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :value="true"
        > -->
        <!--MANAGE VEHICLES-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          slot="activator"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/vehicles.svg"
            >
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>  {{ items.vehicles.title }} </v-list-tile-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-chip
              v-if="!mini"
              label
              small
              color="primary"
              text-color="white"
            >
              {{ $store.getters.vehicles }}
            </v-chip>
          </v-list-tile-action>
        </v-list-tile>
        <!--VEHICLE ENTRY-->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.vehicles.href2"
          :to="{name: items.vehicles.href2}"
          active-class="title black--text redColor"
          class="menuList listHover blueText"
        >
          <v-list-tile-action>
            <img
              height="24"
              width="24"
              src="@/assets/icons/cars.svg"
            >
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.vehicles.subtitle2 }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-- </v-list-group> -->
        <!-------------------------------------LEKO EMPLOYEES-------------------------------------->
        <v-list-group
          v-if="$store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
            || $store.getters.role == 'LekoBot'
          "
        >
          <v-list-tile
            slot="activator"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/leko_employees.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ items.leko.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            :href="items.leko.href"
            :to="{name: items.leko.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-cogs</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-if="$store.getters.role !== 'LekoBot'"
            :href="items.leko.href2"
            :to="{name: items.leko.href2}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-user-clock</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle2 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-if="$store.getters.role !== 'LekoBot'"
            :href="items.leko.href3"
            :to="{name: items.leko.href3}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-cash-register</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle3 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-if="$store.getters.role !== 'LekoBot'"
            :href="items.leko.href4"
            :to="{name: items.leko.href4}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-cogs</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle4 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-if="$store.getters.role !== 'LekoBot'"
            :href="items.leko.href5"
            :to="{name: items.leko.href5}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-user-cog</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle5 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            :href="items.leko.href1"
            :to="{name: items.leko.href1}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-tachometer-alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.leko.subtitle1 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <!-------------------------------------SETTINGS MODULE------------------------------------->
        <v-list-group
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
        >
          <v-list-tile
            slot="activator"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>{{ items.settings2.icon }}</v-icon>
              <!-- <img
                height="24"
                width="24"
                src="@/assets/icons/leko_employees.svg"
              > -->
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class=" listHover blueText">
                {{ items.settings2.title }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!---------------------------------------SETTINGS---------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "
            :href="items.settings.href"
            :to="{name: items.settings.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-user-cog</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ items.settings.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!---------------------------------------SHIPMENTS--------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'User'
              || $store.getters.role == 'InstitutionManager'
              || $store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "
            :href="items.vehicles.href1"
            :to="{name: items.vehicles.href1}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-boxes</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.vehicles.subtitle1 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!-------------------------------------REPOSITORIES-------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'User'
              || $store.getters.role == 'InstitutionManager'
              || $store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "

            :href="items.repositories.href"
            :to="{name: items.repositories.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/repositories.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.repositories.title }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--------------------------------------WARRANTIES--------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
              || $store.getters.role == 'InstitutionAdmin'"
            :href="items.warranties.href"
            :to="{name: items.warranties.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-receipt</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.warranties.title }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!-----------------------------------VEHICLE LOCATION------------------------------------>
          <v-list-tile
            v-if="$store.getters.role == 'User'
              || $store.getters.role == 'InstitutionManager'
              || $store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "
            :href="items.vehicles.href4"
            :to="{name: items.vehicles.href4}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/car-location.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.vehicles.subtitle4 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!-------------------------------------CAR VERSIONS-------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'User'
              || $store.getters.role == 'InstitutionManager'
              || $store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "
            :href="items.vehicles.href3"
            :to="{name: items.vehicles.href3}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/vehicles.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.vehicles.subtitle3 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!-----------------------------------------USERS----------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
              || $store.getters.role == 'InstitutionAdmin'"
            :href="items.users.href"
            :to="{name: items.users.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/users.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.users.title }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!----------------------------------------CLIENTS---------------------------------------->
          <v-list-tile
            v-if="$store.getters.role == 'User'
              || $store.getters.role == 'InstitutionManager'
              || $store.getters.role == 'InstitutionAdmin'
              || $store.getters.role == 'LekoAdmin'
              || $store.getters.role == 'LekoEmployee'
            "

            :href="items.clients.href"
            :to="{name: items.clients.href}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/clients.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.clients.title }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <!--------------------------------------INSTITUTIONS--------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.institutions.href"
          :to="{name: items.institutions.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-building</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.institutions.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!------------------------------------------CARS------------------------------------------->
        <!--
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.cars.href"
          :to="{name: items.cars.href}"
          active-class="title black--text redColor"
          class="listHover"
        >
          <v-list-tile-action>
            <v-icon> {{ items.cars.icon }} </v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.cars.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        -->
        <!-----------------------------------------CITIES------------------------------------------>
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.cities.href"
          :to="{name: items.cities.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-city</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.cities.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-----------------------------------------STATES------------------------------------------>
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.states.href"
          :to="{name: items.states.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-globe</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.states.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!-----------------------------------------QUALITY----------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.quality.href"
          :to="{name: items.quality.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-star-half-alt</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.quality.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--------------------------------------COMMON CODES--------------------------------------->
        <v-list-tile
          v-if="$store.getters.role == 'User'
            || $store.getters.role == 'InstitutionManager'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
          "
          :href="items.commonCodes.href"
          :to="{name: items.commonCodes.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>info</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.commonCodes.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!------------------------------------------TESTS------------------------------------------>
        <v-list-tile
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          :href="items.tests.href"
          :to="{name: items.tests.href}"
          active-class="title black--text redColor"
          class="menuList listHover"
        >
          <v-list-tile-action>
            <v-icon>fas fa-vial</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.tests.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--------------------------------------DOCUMENTATION-------------------------------------->
        <v-list-group value="true">
          <!--MANAGE DOCUMENTATION-->
          <v-list-tile
            slot="activator"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-file-invoice</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.documentation.title }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--Lekotech AI-->
          <v-list-tile
            :href="items.documentation.href3"
            :to="{name: items.documentation.href3}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-file-alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.documentation.subtitle4 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--INTRODUCTION-->
          <v-list-tile
            :href="items.documentation.href2"
            :to="{name: items.documentation.href2}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <v-icon>fas fa-file-alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.documentation.subtitle2 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--SEARCH TERMS-->
          <v-list-tile
            active-class="title black--text redColor"
            class="menuList listHover"
            @click="openSearchTermsDocument"
          >
            <v-list-tile-action>
              <v-icon>fas fa-file-alt</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.documentation.subtitle3 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--Documentation-->
          <v-list-tile
            :href="items.documentation.href1"
            :to="{name: items.documentation.href1}"
            active-class="title black--text redColor"
            class="menuList listHover"
          >
            <v-list-tile-action>
              <img
                height="24"
                width="24"
                src="@/assets/icons/api.svg"
              >
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title> {{ items.documentation.subtitle1 }} </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
        <!---------------------------------------CAR VERSIONS-------------------------------------->
        <!--
        <v-list-tile
          v-if="$store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'InstitutionManager' || $store.getters.role == 'User'"
          :href="items.vehicleEntry.href"
          :to="{name: items.vehicleEntry.href}"
          active-class="title black--text redColor"
          class="listHover"
        >
          <v-list-tile-action>
            <v-icon> {{ items.vehicleEntry.icon }} </v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title> {{ items.vehicleEntry.title }} </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        -->
        <!----------------------------------------------------------------------------------------->
      </v-list>

      <v-spacer />

      <!-- LANGUAGE -->
      <v-layout
        v-if="!mini"
        column
        class="grey darken-4 pa-1"
      >
        <v-flex>
          <v-select
            v-model="language"
            :items="languages"
            solo
            flat
            color="red"
            item-text="title"
            item-value="locale"
            hide-details
          />
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <!-------------------------------------------TOOLBAR------------------------------------------->
    <v-toolbar
      v-if="logged == true"
      class="grey darken-4"
      dark
      fixed
      clipped-left
      app
    >
      <!-- TOOLBAR DRAWER ICON -->
      <v-toolbar-side-icon
        v-if="this.$vuetify.breakpoint.mdAndDown"
        @click.stop="drawer = !drawer"
      />
      <!--LOGO-->
      <v-toolbar-title>
        <router-link to="/inventory">
          <v-layout>
            <img
              v-if="mini"
              height="50"
              src="@/assets/logo_small_w.png"
            >
            <img
              v-else
              height="50"
              src="@/assets/logo.png"
            >
          </v-layout>
        </router-link>
      </v-toolbar-title>
      <v-spacer />

      <!-- TOOLBAR ITEMS -->
      <v-toolbar-items>
        <!-- USER DETAILS -->
        <v-layout
          v-if="this.$vuetify.breakpoint.mdAndUp"
          align-center
          justify-center
          column
          class="mr-4"
        >
          <v-flex class="mt-3">
            <span class="white--text body-2">
              {{ $store.getters.userName }} | {{ getRole($store.getters.role) }}
            </span>
          </v-flex>
          <v-flex class="mb-3">
            <span class="white--text body-2">
              {{ $t('approved_parts') }}: {{ $store.getters.approvedPartsNumber }}
              {{ $t('used_plates') }}: {{ $store.getters.usedPlatesNumber }}
            </span>
          </v-flex>
        </v-layout>

        <!--LOGOUT-->
        <v-btn
          v-if="this.$vuetify.breakpoint.smAndUp"
          depressed
          @click="logout"
        >
          {{ $t('logout') }}
          <v-icon
            right
            light
          >
            fas fa-sign-out-alt
          </v-icon>
        </v-btn>
        <v-btn
          v-if="this.$vuetify.breakpoint.xs"
          icon
          large
          @click="logout"
        >
          <v-icon light>
            fas fa-sign-out-alt
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!--------------------------------------------------------------------------------------------->
    <v-content>
      <v-fade-transition mode="out-in">
        <div>
          <!-- NAVIGATION BUTTON -->
          <v-btn
            v-if="logged == true && this.$vuetify.breakpoint.lgAndUp"
            absolute
            small
            fab
            icon
            color="red darken-3"
            dark
            style="margin-top: 12px; margin-left: -20px"
            @click.stop="mini = !mini"
          >
            <v-icon>
              {{ mini ? 'arrow_forward_ios' : 'arrow_back_ios_new' }}
            </v-icon>
          </v-btn>
          <!--LOADING DIALOG-->
          <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="red darken-3"
              dark
            >
              <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
          <!--PAGE-->
          <router-view />
        </div>
      </v-fade-transition>
    </v-content>
    <!--------------------------------------------------------------------------------------------->
    <v-footer
      class="grey darken-4 pl-3"
      dark
      app
    >
      <span>© {{ currentYear }} - LEKO Tech, All Right Reserved.</span>
      <v-spacer /><a
        href="https://lekotech.com/privacy-policy/"
        target="blank"
        style="color:#e84a4a;padding-right:10px;"
      >Termini e condizioni</a>
    </v-footer>
  </v-app>
</template>

<script type="text/babel">

import Vue from 'vue';
import GlobalEvents from 'vue-global-events';

import postRequest from './api/postRequest';

Vue.component('global-events', GlobalEvents);

let timeout;

export default {
  components: {
    GlobalEvents,
  },
  data: () => ({
    token: localStorage.token,
    drawer: true,
    mini: false,
    items: {
      home: {
        href: 'home',
        router: true,
        title: '',
        icon: 'home',
      },
      settings: {
        href: 'settings',
        router: true,
        title: '',
        icon: 'settings',
      },
      settings2: {
        title: '',
        icon: 'settings',
      },
      leko: {
        href: 'approve-parts',
        href1: 'test-its',
        href2: 'approve-users',
        href3: 'shippings',
        href4: 'car-part-groups',
        href5: 'tools',
        router: true,
        title: 'LEKO Employees',
        subtitle: 'Approve Parts',
        subtitle1: 'Test ITS',
        subtitle2: 'Approve Users',
        subtitle3: 'Shippings',
        subtitle4: 'Car Part Groups',
        subtitle5: 'Tools',
        icon: 'engineering',
      },
      partRequests: {
        href: 'part-requests',
        router: true,
        title: '',
        icon: 'store',
      },
      sales: {
        href: 'sales',
        router: true,
        title: '',
        icon: 'attach_money',
      },
      reservations: {
        href: 'reservations',
        router: true,
        title: '',
        icon: 'event',
      },
      users: {
        href: 'users',
        router: true,
        title: '',
        icon: 'people',
      },
      clients: {
        href: 'clients',
        router: true,
        title: '',
        icon: 'people',
      },
      institutions: {
        href: 'institutions',
        router: true,
        title: '',
        icon: 'domain',
      },
      repositories: {
        href: 'repositories',
        router: true,
        title: '',
        icon: 'meeting_room',
      },
      parts: {
        title: 'Manage Parts',
        subtitle: 'Insert Parts',
        subtitle1: 'Inventory',
        subtitle2: 'Ordered Parts',
        subtitle3: 'Damaged Parts',
        subtitle4: 'Lost Parts',
        subtitle5: 'Recycled Parts',
        subtitle6: 'Movements',
        href: 'insert-parts',
        href1: 'inventory',
        href2: 'ordered',
        href3: 'damaged',
        href4: 'lost',
        href5: 'recycled',
        href6: 'movements',
        icon: 'settings',
      },
      cities: {
        href: 'cities',
        router: true,
        title: '',
        icon: 'location_city',
      },
      states: {
        href: 'states',
        router: true,
        title: '',
        icon: 'public',
      },
      quality: {
        href: 'quality',
        router: true,
        title: '',
        icon: 'star_half',
      },
      warranties: {
        href: 'warranties',
        router: true,
        title: '',
        icon: 'assignment',
      },
      commonCodes: {
        href: 'common-codes',
        router: true,
        title: '',
        icon: 'info',
      },
      tests: {
        href: 'tests',
        router: true,
        title: '',
        icon: 'build',
      },
      documentation: {
        href1: 'documentation',
        href2: 'introduction',
        href3: 'lekoTechAi',
        router: true,
        title: '',
        icon: 'description',
        subtitle1: 'API Documentation',
        subtitle2: 'Introduction',
        subtitle3: 'Search Terms',
      },
      vehicles: {
        title: 'Manage Vehicles',
        subtitle1: 'Shipments',
        subtitle2: 'Vehicle Entry',
        subtitle3: 'Car Versions',
        subtitle4: 'Car Locations',
        href1: 'shipments',
        href2: 'vehicle-entry',
        href3: 'car-versions',
        href4: 'car-locations',
        router: true,
        icon: 'directions_car',
      },
    },
    languages: [
      { title: 'Italiano', locale: 'it' },
      // { title: 'English', locale: 'en' },
    ],
    language: null,
  }),
  computed: {
    logged() {
      if (this.$store.getters.logged === true) {
        this.setLanguage();
        this.getLocale();
        this.checkToken();
      }
      return this.$store.getters.logged;
    },
    role() {
      return this.$store.getters.role;
    },
    loading() {
      return this.$store.getters.loading;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    '$vuetify.breakpoint': {
      handler(data) { // Disable mini drawer on small devices.
        if (data && data.mdAndDown) this.mini = false;
      },
    },
    language: {
      handler(value) {
        localStorage.setItem('language', value);
        this.$store.dispatch('languageAction', value);
        this.$i18n.locale = this.$store.getters.language;
      },
    },
  },
  created() {
    this.setInactiveTimeout();
  },
  methods: {
    setLanguage() {
      this.$i18n.locale = this.$store.getters.language;
      this.language = this.$store.getters.language;
    },
    getLocale() {
      this.items.home.title = this.$i18n.t('home.home');
      this.items.leko.title = this.$i18n.t('leko_employees.leko_employees');
      this.items.leko.subtitle = this.$i18n.t('leko_employees.approve_parts.label');
      this.items.leko.subtitle1 = this.$i18n.t('leko_employees.test_its.label');
      this.items.leko.subtitle2 = this.$i18n.t('leko_employees.approve_users.label');
      this.items.leko.subtitle3 = this.$i18n.t('leko_employees.shippings.label');
      this.items.leko.subtitle4 = this.$i18n.t('leko_employees.car_part_groups.label');
      this.items.leko.subtitle5 = this.$i18n.t('leko_employees.tools.label');
      this.items.sales.title = this.$i18n.t('sales.manage_sales');
      this.items.reservations.title = this.$i18n.t('reservations.label');
      this.items.users.title = this.$i18n.t('users.manage_users');
      this.items.clients.title = this.$i18n.t('clients.manage_clients');
      this.items.institutions.title = this.$i18n.t('institutions.manage_institutions');
      // this.items.cars.title = this.$i18n.t('cars.manage_cars');
      this.items.parts.title = this.$i18n.t('parts.manage_parts');
      this.items.parts.subtitle = this.$i18n.t('parts.insert_parts.label');
      this.items.parts.subtitle1 = this.$i18n.t('parts.inventory.label');
      this.items.parts.subtitle2 = this.$i18n.t('parts.ordered_parts.label');
      this.items.parts.subtitle3 = this.$i18n.t('parts.damaged_parts.label');
      this.items.parts.subtitle4 = this.$i18n.t('parts.lost_parts.label');
      this.items.parts.subtitle5 = this.$i18n.t('parts.recycled_parts.label');
      this.items.parts.subtitle6 = this.$i18n.t('parts.movements.label');
      this.items.repositories.title = this.$i18n.t('repositories.manage_repositories');
      this.items.cities.title = this.$i18n.t('cities.manage_cities');
      this.items.states.title = this.$i18n.t('states.manage_states');
      this.items.quality.title = this.$i18n.t('quality.manage_quality');
      this.items.warranties.title = this.$i18n.t('warranties.manage_warranties');
      this.items.tests.title = this.$i18n.t('tests.manage_tests');
      this.items.commonCodes.title = this.$i18n.t('common_codes.label');
      this.items.documentation.title = this.$i18n.t('documentation.label');
      this.items.documentation.subtitle4 = this.$i18n.t('documentation.subtitle4');
      this.items.documentation.subtitle1 = this.$i18n.t('documentation.subtitle1');
      this.items.documentation.subtitle2 = this.$i18n.t('documentation.subtitle2');
      this.items.documentation.subtitle3 = this.$i18n.t('documentation.subtitle3');
      this.items.partRequests.title = this.$i18n.t('part_requests.label');
      this.items.vehicles.title = this.$i18n.t('vehicle_entry.manage_vehicles');
      this.items.vehicles.subtitle1 = this.$i18n.t('shipments.manage_shipments');
      this.items.vehicles.subtitle2 = this.$i18n.t('vehicle_entry.label');
      this.items.vehicles.subtitle3 = this.$i18n.t('institutions.cars.label');
      this.items.vehicles.subtitle4 = this.$i18n.t('car_locations.label');
      this.items.settings.title = this.$i18n.t('settings.label');
      this.items.settings2.title = this.$i18n.t('settings2.label');
    },
    getRole(role) {
      switch (role) {
        case 'LekoAdmin':
          return this.$i18n.t('roles.leko_admin');
        case 'LekoEmployee':
          return this.$i18n.t('roles.leko_employee');
        case 'InstitutionAdmin':
          return this.$i18n.t('roles.institution_admin');
        case 'InstitutionManager':
          return this.$i18n.t('roles.institution_manager');
        case 'User':
          return this.$i18n.t('roles.user');
        default:
          return role;
      }
    },
    setInactiveTimeout() {
      timeout = setTimeout(() => {
        this.logout();
      }, 60 * 60 * 1000);
    },
    refresh() {
      clearTimeout(timeout);
      this.setInactiveTimeout();
    },
    renewToken(token) {
      postRequest.object('/renew-token', { token })
        .then((response) => {
          localStorage.setItem('exp', response.data.exp);
          localStorage.setItem('token', response.data.token);
          console.log('Token is successfully renewed.');
          this.checkToken();
        }).catch((error) => {
          console.log(error);
          this.logout();
        });
    },
    checkToken() {
      const exp = localStorage.getItem('exp');
      const token = localStorage.getItem('token');

      const expTime = exp - Date.now();
      const rnTime = expTime - 100000; // Set renew time 100 Seconds before the token expires.

      console.log(`Token renewal time: ${rnTime}`);

      if (rnTime < 0) {
        this.renewToken(token);
      } else {
        setTimeout(() => {
          this.renewToken(token);
        }, rnTime);
      }
    },
    openSearchTermsDocument() { window.open('./MappaTerminiPerLaRicerca.pdf', '_blank'); },
    logout() {
      console.log('logged out...');
      localStorage.removeItem('exp');
      localStorage.removeItem('token');
      localStorage.removeItem('language');
      this.$store.dispatch('logoutAction');
      this.$router.push({ path: '/auth' });
    },
  },
};
</script>

<style lang="stylus">
@import '../node_modules/vuetify/src/stylus/main';
@import 'css/main.css';
</style>
