import axios from 'axios';
import getRequest from '../api/getRequest';

export default () => new Promise(async (resolve, reject) => {
  try {
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const geoData = await getRequest(`/determine-ip/${ipResponse.data.ip}`);
    resolve(geoData);
  } catch (error) {
    reject(error);
  }
});
