<template>
  <v-container grid-list-lg>
    <h3>Plates</h3>
    <!---------------------------------------Used----------------------------------------->
    <v-layout wrap>
      <v-flex xs6>
        <v-text-field
          v-model="institution.plates.used"
          color="red darken-3"
          disabled
          label="Used"
        />
      </v-flex>
    </v-layout>
    <!---------------------------------------Remaining----------------------------------------->
    <v-layout row>
      <v-flex xs6>
        <v-text-field
          v-model="institution.plates.remaining"
          color="red darken-3"
          disabled
          label="Remaining"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  name: 'Plates',
  props: ['institution'], // eslint-disable-line vue/require-prop-types
  data() {
    return {
      institutions: [],
    };
  },
  computed: {
    plates() {
      return this.$props.institution.preferences.plates || {};
    },
  },
  methods: {
    getInstitutions() {
      this.loading = true;
      getRequest('/institution/')
        .then((response) => {
          console.log(response);
          this.institutions = response.institutions;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$emit('error', error);
        });
    },
  },
};
</script>
