<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <v-flex
          xs10
          sm11
        >
          <span class="headline">{{ $i18n.t('vehicle_entry.selected_parts') }}</span>
        </v-flex>
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-layout column>
          <!---------------------------------------- LIST ----------------------------------------->
          <v-flex
            v-for="item in items"
            :key="item._id"
          >
            <v-divider />

            <v-layout
              align-center
              wrap
            >
              <v-flex
                xs12
                sm6
                class="pa-1"
              >
                <div class="caption">
                  {{ $t('vehicle_entry.title') }}
                </div>
                <div class="subheading">
                  {{ item.title[$store.getters.language] }}
                </div>
              </v-flex>
              <v-flex
                xs12
                sm2
                class="pa-1"
              >
                <div class="caption">
                  {{ $t('vehicle_entry.selected') }}
                </div>
                <div class="subheading">
                  <v-icon
                    v-if="item.selected"
                    color="red darken-1"
                  >
                    check_box
                  </v-icon>
                  <v-icon
                    v-else
                    color="red darken-1"
                  >
                    check_box_outline_blank
                  </v-icon>
                </div>
              </v-flex>

              <v-flex
                xs12
                sm4
              >
                <div class="caption">
                  {{ $t('vehicle_entry.notes') }}
                </div>
                <div class="body-2">
                  {{ item.notes }}
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-2">
          <v-flex xs12>
            <v-textarea
              :label="$t('vehicle_entry.comment')"
              disabled
              :value="comment"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    loading: false,
    position: null,
    comment: null,
    items: [],
  }),
  watch: {
    dialog(value) {
      if (!value) { this.close(); }
    },
  },
  methods: {
    open(position, items, comment) {
      this.dialog = true;
      this.position = position;
      this.comment = comment;
      this.items = items || [];
    },
    close() {
      this.dialog = false;
      this.position = null;
      this.comment = null;
      this.items = [];
      this.$emit('close');
    },
  },
};
</script>
