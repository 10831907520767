<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('reservations.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'InstitutionAdmin'
          || $store.getters.role == 'InstitutionManager'
        "
        color="grey darken-4"
        dark
        @click="newReservation"
      >
        {{ $t('reservations.new_reservation') }}
      </v-btn>
    </v-toolbar>

    <!--DETAILS DIALOG-->
    <details-dialog
      ref="detailsDialog"
      @close="closeDetails"
    />
    <!--ADD CLIENT-->
    <client-dialog
      ref="clientDialog"
      @closed="closeClientDialog"
      @saved="getClients"
    />
    <!--NEW RESERVATION-->
    <reservation-dialog
      ref="reservationDialog"
      @details="showDetails"
      @saved="getReservations"
      @add-client="openClientDialog"
    />
    <!--ACTIVE RESERVATIONS-->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
      class="pointer"
      @click="activeReservations = !activeReservations"
    >
      <v-toolbar-title>
        {{ $t('reservations.active_reservations') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          flat
          icon
        >
          <v-icon v-if="activeReservations">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <reservations-datatable
      v-if="activeReservations"
      ref="activeReservations"
      type="active"
      @sold="getReservations"
      @details="showDetails"
      @images="openImagesCarousel"
    />
    <!--INACTIVE RESERVATIONS-->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
      class="pointer"
      @click="inactiveReservations = !inactiveReservations"
    >
      <v-toolbar-title>
        {{ $t('reservations.inactive_reservations') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          flat
          icon
        >
          <v-icon v-if="inactiveReservations">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <reservations-datatable
      v-if="inactiveReservations"
      ref="inactiveReservations"
      type="inactive"
      @deleted="getReservations"
    />
    <!--IMAGES CAROUSEL-->
    <img-carousel ref="imgCarousel" />
  </v-container>
</template>

<script>
import ReservationDialog from '@/components/Reservations/reservationDialog.vue';
import ReservationsDatatable from '@/components/Reservations/reservationsDatatable.vue';

import DetailsDialog from '@/components/detailsDialog.vue';
import ClientDialog from '@/components/Clients/editDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';

export default {
  components: {
    'reservation-dialog': ReservationDialog,
    'reservations-datatable': ReservationsDatatable,
    'details-dialog': DetailsDialog,
    'client-dialog': ClientDialog,
    'img-carousel': ImgCarousel,
  },
  data: () => ({
    search: '',
    activeReservations: true,
    inactiveReservations: false,
    rd: false,
  }),
  methods: {
    newReservation() {
      const { reservationDialog } = this.$refs;
      reservationDialog.open();
    },
    showDetails(data) {
      const { reservationDialog } = this.$refs;
      if (reservationDialog.dialog) {
        this.rd = true;
        reservationDialog.dialog = false;
      }

      const { detailsDialog } = this.$refs;
      detailsDialog.open(data);
    },
    closeDetails() {
      if (this.rd) {
        const { reservationDialog } = this.$refs;
        reservationDialog.dialog = true;
        this.rd = false;
      }
    },
    openClientDialog() {
      const { reservationDialog } = this.$refs;
      const { clientDialog } = this.$refs;
      reservationDialog.dialog = false;
      clientDialog.open(false, null, this.$store.getters.institutionId);
    },
    closeClientDialog() {
      const { reservationDialog } = this.$refs;
      reservationDialog.dialog = true;
    },
    getClients() {
      const { reservationDialog } = this.$refs;
      reservationDialog.getClients();
    },
    getReservations() {
      const { activeReservations } = this.$refs;
      const { inactiveReservations } = this.$refs;
      if (this.activeReservations) activeReservations.getReservations();
      if (this.inactiveReservations) inactiveReservations.getReservations();
    },
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
    },
  },
};
</script>
