<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="600"
  >
    <v-card
      flat
      tile
      color="grey lighten-4"
    >
      <v-card-text class="pa-0">
        <v-layout
          row
          align-center
          justify-center
          wrap
        >
          <v-flex
            xs12
            :class="{ 'd-none': isZooming }"
          >
            <v-layout align-center>
              <v-spacer />
              <v-btn
                class="pa-2"
                outline
                icon
                large
                color="red"
                @click="close"
              >
                <v-icon x-large>
                  close
                </v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-img
              v-if="dialog"
              :style="{ 'transform': `rotate(${rotation}deg)` }"
              :src="source"
              aspect-ratio="1"
              contain
              @mousemove="zoomIn"
              @mouseleave="zoomOut"
            >
              <template v-slot:placeholder>
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-layout>
              </template>
            </v-img>
          </v-flex>
          <v-flex
            xs12
            :class="{ 'd-none': isZooming }"
          >
            <v-layout
              align-center
              justify-center
            >
              <v-btn
                icon
                @click="rotateL"
              >
                <v-icon>rotate_left</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="rotateR"
              >
                <v-icon>rotate_right</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.getters.institutionDisplayFeatures.removeImagesBackground"
                outline
                color="primary"
                @click="removeBackground = !removeBackground"
              >
                <v-icon left>
                  {{ removeBackground ? 'check_box': 'check_box_outline_blank' }}
                </v-icon>
                {{ $t('remove_background') }}
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions :class="{ 'd-none': isZooming }">
        <v-btn
          flat
          color="red darken-2"
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          color="primary"
          :disabled="edited"
          :loading="loading"
          @click="edit"
        >
          {{ $t('preview') }}
        </v-btn>
        <v-btn
          flat
          color="success"
          :disabled="!edited"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import zoom from '../utils/zoom';

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}


export default {
  data: () => ({
    dialog: false,
    source: '', // must be a dataurl
    details: { type: null, index: null },
    isZooming: false,
    rotation: 0,
    removeBackground: false,
    edited: false,
    loading: false,
  }),
  watch: {
    dialog(value) { if (!value) { this.close(); } },
  },
  methods: {
    open(image) {
      this.dialog = true;
      // Set the details.
      this.details = { type: image.type, index: image.index };
      // Set the image source.
      if (image.type === 'dataUrl') this.source = image.data;
      else if (image.type === 'string') {
        axios.get(`${this.$serverUri}/images?q=${image.data.image}`, { responseType: 'blob' })
          .then((response) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = () => { this.source = reader.result; };
          })
          .catch((error) => { console.log(error); });
      }
    },
    rotateR() { this.rotation += 90; },
    rotateL() { this.rotation -= 90; },
    zoomIn(event) { this.isZooming = true; zoom.zoomIn(2.4, event, this.rotation); },
    zoomOut(event) { this.isZooming = false; zoom.zoomOut(event, this.rotation); },

    edit() {
      this.loading = true;
      // Set the oprations.
      const operations = [];
      if (this.removeBackground) operations.push('removeBackground');
      if (this.rotation !== 0) operations.push('rotate');
      // Send the edit request.
      const formData = new FormData();
      formData.append('image', dataURLtoBlob(this.source));
      axios.post(`${this.$serverUri}/images/edit?operations=${operations.join(',')}&rotate=${this.rotation}`, formData, {
        headers: { authorization: localStorage.token, 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      })
        .then((response) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => { this.source = reader.result; };
          this.rotation = 0;
          this.removeBackground = false;
          this.edited = true;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },

    save() {
      this.$emit('save', {
        type: this.details.type,
        index: this.details.index,
        dataUrl: this.source,
        blob: dataURLtoBlob(this.source),
        title: `${Math.random().toString(36).substring(2)}.${this.source.split(';')[0].split('/')[1]}`,
      });
      this.close();
    },
    close() {
      this.source = '';
      this.details = { type: null, index: null };
      this.dialog = false;
      this.isZooming = false;
      this.rotation = 0;
      this.rotation = 0;
      this.removeBackground = false;
      this.edited = false;
      this.loading = false;
      this.$emit('close');
    },
  },
};
</script>

