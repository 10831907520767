<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="600"
  >
    <v-card
      flat
      color="grey darken-4"
      class="d-flex"
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <!--TOOLBAR-->
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              dark
              icon
              @click="rotateL"
            >
              <v-icon>rotate_left</v-icon>
            </v-btn>
            <v-btn
              dark
              icon
              @click="rotateR"
            >
              <v-icon>rotate_right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <!--IMAGES-->
        <v-flex xs12>
          <v-img
            v-if="type === 'single'"
            :style="{ 'transform': `rotate(${rotation}deg)` }"
            :src="`${server}/images/part-request?q=${source.id}-${source.title}`"
            aspect-ratio="1"
            contain
          >
            <template v-slot:placeholder>
              <v-layout>
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-layout>
              </v-layout>
            </template>
          </v-img>

          <v-carousel
            v-if="type === 'multiple'"
            :cyle="false"
            height="auto"
          >
            <v-carousel-item
              v-for="(image,i) in source.images"
              :key="i"
              :src="`${server}/images/part-request?q=${source.id}-${image}`"
            />
          </v-carousel>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    source: null,
    type: null,
    rotation: 0,
  }),
  computed: {
    server() { return this.$serverUri; },
  },
  watch: {
    dialog(v) { if (!v) this.close(); },
  },
  methods: {
    open(type, source) {
      this.type = type;
      this.source = source;
      this.dialog = true;
    },
    rotateR() { this.rotation += 90; },
    rotateL() { this.rotation -= 90; },
    close() {
      this.dialog = false;
      this.type = null;
      this.source = null;
      this.rotation = 0;
      this.$emit('close');
    },
  },
};
</script>
