<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('quality.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('quality.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />

      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'"
        color="grey darken-4"
        dark
        @click="newQuality"
      >
        {{ $t('quality.button') }}
      </v-btn>
    </v-toolbar>

    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      @save="getQualities"
      @error="showAlert"
    />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      @edit="editQuality"
      @error="showAlert"
    />
  </v-container>
</template>

<script>
import EditDialog from '@/components/Quality/dialog.vue';
import Datatable from '@/components/Quality/datatable.vue';

export default {
  components: {
    'edit-dialog': EditDialog,
    datatable: Datatable,
  },
  data: () => ({
    search: '',
    alert: {
      value: false,
      message: '',
    },
  }),
  watch: {
    search(value) {
      const { datatable } = this.$refs;
      datatable.search(value);
    },
  },
  methods: {
    showAlert(error) {
      console.log(error);
      this.alert = {
        value: true,
        message: '',
      };
      setTimeout(() => {
        this.alert.value = false;
      }, 2000);
    },
    getQualities() {
      const { datatable } = this.$refs;
      datatable.getQualities();
    },
    newQuality() {
      const { editDialog } = this.$refs;
      editDialog.open(false, null);
    },
    editQuality(data) {
      const { editDialog } = this.$refs;
      editDialog.open(true, data);
    },
  },
};
</script>
