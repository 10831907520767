<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <div>
      <!--TOOLBAR-->
      <v-toolbar
        dark
        color="grey darken-4"
        class="elevation-0"
      >
        <v-toolbar-title>{{ $t('repositories.rooms.label') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          class="ma-2"
          outline
          icon
          large
          color="red"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <!--CARD-->
      <v-card>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-alert
              :value="alert"
              type="warning"
            >
              {{ $t('repositories.print_alert') }}
            </v-alert>
            <v-list two-line>
              <v-list-group
                v-for="(room, r) in repository.rooms"
                :key="r"
              >
                <v-list-tile slot="activator">
                  <v-list-tile-content>
                    {{ $t('repositories.rooms.room') }}: {{ room.name }}
                    {{ $t('repositories.rooms.locations') }}: {{ room.locations.length }}
                    {{ $t('repositories.rooms.shelves') }}: {{ room.shelves_number }}
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      flat
                      icon
                      @click="getShelves(room)"
                    >
                      <v-icon>list</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile
                  v-for="(location, l) in room.locations"
                  :key="l"
                  @click="''"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ $t('repositories.table.code') }}: {{ location.code }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title class="text--primary">
                      {{ $t('repositories.table.position_x') }}: {{ location.position_x }}
                      {{ $t('repositories.table.position_y') }}: {{ location.position_y }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      {{ $t('repositories.table.notes') }}: {{ location.notes }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      flat
                      icon
                      @click="getShelves(room, location)"
                    >
                      <v-icon>list</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list-group>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    alert: false,
    repository: {},
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  methods: {
    open(repository) {
      this.repository = repository;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.repository = {};
      this.$emit('close');
    },
    getShelves(room, location) {
      if (!location && room.shelves_number > 500) {
        this.alert = true;
        setTimeout(() => { this.alert = false; }, 2000);
      } else {
        this.$emit('shelves', {
          repositoryId: this.repository._id,
          roomId: room._id,
          locationId: location ? location._id : null,
        });
      }
    },
    showAlert(shelvesNumber) {
      if (shelvesNumber > 500) this.alert = true;
      setTimeout(() => { this.alert = false; }, 2000);
    },
  },
};
</script>
