<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('repositories.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('repositories.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'InstitutionAdmin'
          || $store.getters.role == 'InstitutionManager'
        "
        color="grey darken-4"
        dark
        @click="newRepository"
      >
        {{ $t('repositories.button') }}
      </v-btn>
    </v-toolbar>

    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <!--ALERT DIALOG-->
    <alert-dialog
      ref="alert"
      @confirmed="operationConfirmed"
      @cancelled="operationCancelled"
    />

    <!--DETAILS-->
    <details-dialog
      ref="detailsDialog"
      @shelves="viewShelves"
    />
    <!--SHELVES-->
    <shelves
      ref="shelves"
      @parts="viewParts"
      @close="viewDetails(__details)"
    />
    <!--PARTS-->
    <parts
      ref="parts"
      @close="viewShelves(__shelves)"
    />
    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      @location="openSEditDialog"
      @save="getRepositories"
      @remove-room="confirmRemoveRoom"
      @remove-location="confirmRemoveLocation"
    />
    <!--SECONDARY EDIT DIALOG-->
    <s-edit-dialog
      ref="sEditDialog"
      @close="closeSEditDialog"
      @save="closeSEditDialog"
    />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      @details="viewDetails"
      @edit="editRepository"
      @delete="confirmRepositoryDelete"
    />
  </v-container>
</template>

<script>
import Details from '@/components/Repositories/details.vue';
import Shelves from '@/components/Repositories/shelves.vue';
import Parts from '@/components/Repositories/parts.vue';
import EditDialog from '@/components/Repositories/editDialog.vue';
import SEditDialog from '@/components/Repositories/sEditDialog.vue';
import Datatable from '@/components/Repositories/datatable.vue';
import AlertDialog from '@/components/alert.vue';

import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    'details-dialog': Details,
    shelves: Shelves,
    parts: Parts,
    'edit-dialog': EditDialog,
    's-edit-dialog': SEditDialog,
    'alert-dialog': AlertDialog,
    datatable: Datatable,
  },
  data: () => ({
    search: '',
    alert: {
      value: false,
      message: '',
    },
    __details: null,
    __shelves: null,
    __editDialogData: null,
    __hideDetails: false, // Used to prevent opening details dialog when viewing parts.
  }),
  watch: {
    search(value) {
      const { datatable } = this.$refs;
      datatable.search(value);
    },
  },
  mounted() {
  },
  methods: {
    showAlert(message) {
      this.alert = {
        value: true,
        message,
      };
      setTimeout(() => {
        this.alert.value = false;
      }, 2000);
    },
    getRepositories() {
      const { datatable } = this.$refs;
      datatable.getRepositories();
    },
    newRepository() {
      const { editDialog } = this.$refs;
      editDialog.open(false, {});
    },
    editRepository(data) {
      const { editDialog } = this.$refs;
      editDialog.open(true, data);
    },
    confirmRepositoryDelete(id) {
      const { alert } = this.$refs;
      alert.open({ op: 'deleteRepository', id });
    },
    confirmRemoveRoom(index) {
      const { alert } = this.$refs;
      const { editDialog } = this.$refs;
      editDialog.hide();
      alert.open({ op: 'removeRoom', index });
    },
    confirmRemoveLocation(index) {
      const { alert } = this.$refs;
      const { editDialog } = this.$refs;
      editDialog.hide();
      alert.open({ op: 'removeLocation', index });
    },
    operationConfirmed(data) {
      const { alert } = this.$refs;
      const { editDialog } = this.$refs;
      if (data.op && data.op === 'deleteRepository') {
        deleteRequest('/repository', `id=${data.id}`)
          .then((response) => {
            console.log(response);
            alert.close();
            this.getRepositories();
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 403) this.showAlert(this.$i18n.t('repositories.not_empty'));
            alert.close();
          });
      } else if (data.op && data.op === 'removeRoom') {
        editDialog.removeRoom(data.index);
        alert.close();
        editDialog.show();
      } else if (data.op && data.op === 'removeLocation') {
        editDialog.removeLocation(data.index);
        alert.close();
        editDialog.show();
      }
    },
    operationCancelled(data) {
      if (data.op && (data.op === 'removeRoom' || data.op === 'removeLocation')) {
        const { editDialog } = this.$refs;
        editDialog.show();
      }
    },
    openSEditDialog(data) {
      const { editDialog } = this.$refs;
      const { sEditDialog } = this.$refs;
      this.__editDialogData = data;
      editDialog.close();
      sEditDialog.open(data);
    },
    closeSEditDialog(data) {
      const { editDialog } = this.$refs;
      if (data.saved) {
        editDialog.open(true, data.editedRepository);
      } else {
        editDialog.open(true, this.__editDialogData.repository);
      }
      editDialog.selectRoom(this.__editDialogData.room.index);
      this.__editDialogData = null;
    },
    viewDetails(data) {
      if (!this.__hideDetails) {
        this.__details = data;
        const { detailsDialog } = this.$refs;
        detailsDialog.open(data);
      }
    },
    viewShelves(data) {
      this.__shelves = data;
      this.__hideDetails = false;
      const { shelves } = this.$refs;
      const { detailsDialog } = this.$refs;
      detailsDialog.close();
      shelves.open(data);
    },
    viewParts(data) {
      const { parts } = this.$refs;
      const { shelves } = this.$refs;
      this.__hideDetails = true;
      shelves.close();
      parts.open(data);
    },
  },
};
</script>
