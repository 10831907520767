<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-start
      >
        <v-toolbar-title>{{ $t('leko_employees.shippings.label') }}</v-toolbar-title>
        <v-divider
          class="mx-5 hidden-md-and-down"
          inset
          vertical
        />

        <download-excel
          :fetch="exportData"
          type="xls"
          name="Shipping"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark
          >
            {{ $t('leko_employees.shippings.export') }}
            <v-icon right>
              fas fa-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <!--
        <download-excel
          :fetch="exportData"
          type="csv"
          name="Shipping"
          class="hidden-md-and-down">
          <v-btn
            color="grey darken-4"
            dark>
            {{ $t('leko_employees.shippings.export') }}
            <v-icon right>fas fa-file-csv</v-icon>
          </v-btn>
        </download-excel>
        -->
        <v-spacer class="hidden-sm-and-down" />
      </v-layout>
    </v-toolbar>
  </div>
</template>

<script>
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions

export default {
  components: {
    'download-excel': JsonExcel,
  },
  props: ['items'], // eslint-disable-line vue/require-prop-types
  data: () => ({}),
  methods: {
    exportData() {
      console.log(this.$props.items);
      const items = [];
      this.$props.items.forEach((item) => {
        if (item.status === 2) {
          const itemObject = {
            ID: item.part_id.old_id,
            [this.$i18n.t('leko_employees.shippings.table.title')]: item.part_id.title,
            [this.$i18n.t('leko_employees.shippings.table.manufacturer_code')]: item.part_id.manufacturer_code,
            [this.$i18n.t('leko_employees.shippings.table.quality')]: item.part_id.quality.title,
            [this.$i18n.t('leko_employees.shippings.table.price')]: item.price.logistic_price,
            [this.$i18n.t('leko_employees.shippings.dimensions')]: `${item.part_id.dimensions.length}x${item.part_id.dimensions.width}x${item.part_id.dimensions.height} ${item.part_id.dimensions.unit}`,
            [this.$i18n.t('leko_employees.shippings.weight')]: `${item.part_id.weight.value} ${item.part_id.weight.unit}`,
            [this.$i18n.t('leko_employees.shippings.actions.tracking_number')]: item.tracking_number,
            [this.$i18n.t('leko_employees.shippings.name')]: `${item.buyer_details.name} ${item.buyer_details.surname}`,
            [this.$i18n.t('leko_employees.shippings.address')]: item.buyer_details.address,
            [this.$i18n.t('leko_employees.shippings.phone')]: item.buyer_details.phone,
            [this.$i18n.t('leko_employees.shippings.email')]: item.buyer_details.email,
          };
          items.push(itemObject);
        }
      });
      return items;
    },
  },
};
</script>
