const QrCode = require('qrcode');
const { Printd } = require('printd');
const postRequest = require('../../api/postRequest').default;

const generateQrComponent = shipment => new Promise((resolve, reject) => {
  QrCode.toDataURL(shipment._id, (error, dataUrl) => {
    if (error) return reject(error);
    // Shipment ID
    const code = document.createElement('div');
    code.textContent = shipment._id.slice(shipment._id.length - 6, shipment._id.length);
    code.setAttribute('id', 'code');
    // Shipment Name
    const name = document.createElement('div');
    name.textContent = shipment.name;
    name.setAttribute('id', 'name');
    // Image Component
    const image = document.createElement('img');
    image.setAttribute('id', 'image');
    image.setAttribute('src', dataUrl);
    image.style.width = '100px';
    image.style.height = '100px';
    // Wrapper
    const content = document.createElement('div');
    content.setAttribute('id', 'wrapper');
    content.appendChild(code);
    content.appendChild(name);
    content.appendChild(image);
    return resolve(content);
  });
});

const css = `
  @page{
      size:A4;
  }
  body{
      height:100%;
      width:100%;
      font: 12px "Lucida Grande", Helvetica, Arial, sans-serif;
  }
  #container{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  #wrapper{
    margin: 8px 5px 8px 5px;
  }
  #image{
      display:block;
      margin:0 auto;
  }
  #name{
      font-size: 12px;
      text-align:center;
      display:block;
      max-width: 100px;
      padding-top: 10px;
  }
  #code{
      font-size:18px;
      text-align:center;
      font-weight:bold;
      text-transform:uppercase;
  }
`;

export default async (shipments) => {
  const promises = shipments.map(shipment => generateQrComponent(shipment));
  const response = await Promise.all(promises);
  const content = document.createElement('div');
  content.setAttribute('id', 'container');
  response.forEach(c => content.appendChild(c));
  const d = new Printd();
  d.print(content, [css], '', (callback) => {
    const { iframe, launchPrint } = callback;
    const { contentWindow } = iframe;
    contentWindow.addEventListener('afterprint', () => { postRequest.object('/print', { type: 'shipments', ids: shipments.map(s => s._id) }); });
    launchPrint();
  });
};
