<template>
  <v-layout
    column
    align-center
    justify-center
  >
    <!--VIDEO STREAM-->
    <v-flex xs12>
      <video
        id="video"
        ref="video"
        :width="videoWidth"
        :class="{'redBorder': recording, 'border': !recording}"
      />
    </v-flex>
    <!--BUTTONS-->
    <v-flex xs12>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-btn
          v-if="!recording"
          :disabled="disabled"
          color="#05CBCD"
          fab
          outline
          @click="startRecording"
        >
          <v-icon
            color="#747474"
            x-large
          >
            camera
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          :disabled="disabled"
          color="#D32F2F"
          fab
          outline
          @click="stopRecording"
        >
          <v-icon
            color="#747474"
            x-large
          >
            camera
          </v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <!--TIMER-->
    <v-flex xs12>
      <div v-if="recording">
        {{ timerValue | timerFilter }}
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
let video;
let stream;
let recorder;
let chunks = [];


function initMediaDevices(v, constraints) {
  video = v;
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((str) => {
      stream = str;
      stream.onremovetrack = () => console.log('stream ended');
      if (video.srcObject !== undefined) video.srcObject = stream;
      else if (video.mozSrcObject !== undefined) video.mozSrcObject = stream;
      else if (window.URL.createObjectURL) video.src = window.URL.createObjectURL(stream);
      else if (window.webkitURL) video.src = window.webkitURL.createObjectURL(stream);
      else video.src = stream;

      video.play();
    })
    .catch((err) => { console.log(err); });
}

function initMediaRecorder() {
  const rec = new MediaRecorder(stream);
  recorder = rec;
  recorder.ondataavailable = (ev) => { chunks.push(ev.data); };
}

export default {
  filters: {
    timerFilter(value) {
      const minutes = Math.floor(value / 60);
      const seconds = value - (minutes * 60);
      const min = minutes.toString().length > 1 ? `${minutes}` : `0${minutes}`;
      const sec = seconds.toString().length > 1 ? `${seconds}` : `0${seconds}`;
      return `${min}:${sec}`;
    },
  },
  data: () => ({
    front: false,
    recording: false,
    disabled: false,
    timerValue: 0,
    timerInverval: null,
  }),
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 240;
        case 'sm':
          return 480;
        case 'md':
          return 680;
        default:
          return 680;
      }
    },
  },
  watch: {
    timerValue(value) {
      if (value >= 30) this.stopRecording(); // Limit recording time to 30s.
    },
  },
  mounted() {
    this.startCamera();
  },
  methods: {
    startCamera() {
      this.disabled = false;
      const constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: true,
      };
      initMediaDevices(this.$refs.video, constraints);
    },
    stopCamera() {
      this.disabled = true;
      if (video && video.srcObject) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
          console.log('video track stopped.');
        });
      }
    },
    switchCamera() {
      this.front = !this.front;
      const constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: true,
      };
      this.stopCamera();
      this.startCamera(constraints);
    },
    startRecording() {
      this.recording = true;
      this.timerInverval = setInterval(() => { this.timerValue += 1; }, 1000);
      initMediaRecorder();
      recorder.start();
    },
    stopRecording() {
      this.recording = false;
      clearInterval(this.timerInverval);
      this.timerInverval = null;
      this.timerValue = 0;
      recorder.stop();
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/mp4' });
        this.$emit('video', { blob });
        chunks = [];
      };
    },
  },
};
</script>

<style scoped>
.border {
  display: block;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  color: #455a64;
  /*color: #616161;*/
}
.redBorder {
  display: block;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  color: #D32F2F;
}
</style>
