<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-alert
      v-model="dialog"
      :type="type"
      dismissible
    >
      {{ message }}
    </v-alert>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    value: false,
    message: null,
    type: 'error',
    timeout: 5000,
  }),
  watch: {
    dialog(value) { if (!value) this.close(); },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.message = data.message;
      if (data.type) this.type = data.type;
      if (data.timeout) this.timeout = data.timeout;
      setTimeout(() => { this.close(); }, this.timeout);
    },
    close() {
      this.dialog = false;
      this.message = null;
      this.type = 'error';
    },
  },
};
</script>
