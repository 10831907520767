<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container grid-list-md>
      <v-layout wrap>
        <!---------------------------------------TITLE--------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.title"
            :label="$t('institutions.form.title')"
            :rules="rules.requiredLetters"
            color="red darken-1"
            prepend-icon="domain"
          />
        </v-flex>
        <!--------------------------------------ADDRESS-------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.address"
            :label="$t('institutions.form.address')"
            :rules="rules.requiredLetters"
            color="red darken-1"
            prepend-icon="list_alt"
          />
        </v-flex>
        <!------------------------------------UPLOAD LOGO------------------------------------>
        <v-flex xs12>
          <label style="padding-right:10px;">{{ $t('institutions.form.upload_logo') }}</label>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>72 x 72 {{ $t('institutions.form.preferred') }}</span>
          </v-tooltip>
          <br>
          <input
            ref="logo"
            accept="image/*"
            type="file"
            @change="onInput"
          >
        </v-flex>
        <!---------------------------------------STATE--------------------------------------->
        <v-flex xs6>
          <v-select
            v-model="state"
            :items="states"
            :label="$t('institutions.form.state')"
            :loading="loadingState"
            :rules="rules.required"
            color="red darken-1"
            item-text="name"
            item-value="name"
            prepend-icon="public"
            return-object
          />
        </v-flex>
        <!---------------------------------------CITY---------------------------------------->
        <v-flex xs6>
          <v-select
            v-model="institution.city"
            :items="cities"
            :label="$t('institutions.form.city')"
            :loading="loadingCity"
            :rules="rules.required"
            color="red darken-1"
            item-text="name"
            item-value="name"
            prepend-icon="location_city"
          />
        </v-flex>
        <!---------------------------------------PHONE--------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.phone"
            :label="$t('institutions.form.phone')"
            :rules="rules.requiredNumbers"
            color="red darken-1"
            prepend-icon="call"
            type="number"
          />
        </v-flex>
        <!--------------------------------------WEBSITE-------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.website"
            :label="$t('institutions.form.website')"
            :rules="rules.requiredLetters"
            color="red darken-1"
            prepend-icon="web"
          />
        </v-flex>
        <!-------------------------------------LEKOSHOP-------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.shop"
            :label="$t('institutions.table.shop')"
            color="red darken-1"
            prepend-icon="shop"
          />
        </v-flex>
        <!---------------------------------------Email--------------------------------------->
        <v-flex xs12>
          <v-text-field
            v-model="institution.email"
            :label="$t('institutions.form.email')"
            :rules="rules.email"
            color="red darken-1"
            prepend-icon="email"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>
import getRequest from '../../api/getRequest';

import { dataURLtoBlob } from '../../utils/files';

export default {
  name: 'GeneralSettings',
  props: ['institution'], // eslint-disable-line vue/require-prop-types
  data() {
    return {
      shop: false,
      website: false,
      valid: false,
      edit: null,
      logo: null,
      dialog: false,
      loading: false,
      loadingState: false,
      loadingCity: false,
      rules: {
        requiredLetters: [
          v => !!v || 'Please fill this field',
          v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
        ],
        requiredNumbers: [
          v => !!v || 'Please fill this field',
          v => /[0-9]/.test(v) || 'Only numbers are allowed',
        ],
        email: [
          v => !!v || 'Please fill this field',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      states: [],
      state: {},
      cities: [],
    };
  },
  watch: {
    state(value) {
      if (value) {
        this.institution.state = value.name;
        this.getCities(value._id);
      }
    },
  },
  beforeMount() {
    this.getStates();
  },
  methods: {
    onInput(input) {
      const { files } = input.target;
      if (files && files.length > 0) {
        const { type } = files[0];
        const extension = type.split('/')[1]; // eslint-disable-line prefer-destructuring
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', (e) => {
          const dataUrl = fileReader.result;
          this.logo = e.target.result;
        });
      }
    },
    getStates() {
      this.loadingState = true;
      getRequest('/state')
        .then((response) => {
          this.states = response.states;
          this.loadingState = false;
          if (this.institution.state) {
            // select institution's state
            this.states.forEach((state) => {
              if (state.name === this.institution.state) this.state = state;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingState = false;
        });
    },
    getCities(id) {
      this.loadingCity = true;
      getRequest('/city', `state_id=${id}`)
        .then((response) => {
          this.cities = response.cities;
          this.loadingCity = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCity = false;
        });
    },
  },
};
</script>
