<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card flat>
      <v-layout
        column
        wrap
      >
        <!-----------------------------------------SCANNER----------------------------------------->
        <v-flex
          v-if="!physicalScanner"
          xs12
        >
          <!-- CAMERA SCANNER -->
          <qrcode-stream
            v-if="dialog"
            :camera="constraints"
            :paused="paused"
            @decode="decode"
          />
        </v-flex>

        <v-flex
          v-if="physicalScanner"
          xs12
        >
          <!-- PHYSICAL SCANNER -->
          <v-layout
            mt-2
            mb-2
            justify-center
          />
        </v-flex>
        <!----------------------------------------------------------------------------------------->
        <v-flex>
          <v-layout
            mt-2
            ml-2
            mr-2
            column
            align-center
            justify-center
          >
            <!--LOADER-->
            <v-flex xs12>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              />
            </v-flex>
            <!--ALERT-->
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
                outline
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>
            <v-flex xs12>
              <v-btn
                v-if="found"
                outline
                color="primary"
                @click="selectShipment"
              >
                {{ $t('shipments.scanner.select_shipment') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!------------------------------------------------------------------------------------------->
      <v-card-actions>
        <v-btn
          flat
          icon
          @click="reScan"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          icon
          @click="switchScanner"
        >
          <v-icon>fas fa-qrcode</v-icon>
        </v-btn>
        <v-btn
          flat
          icon
          @click="switchCamera"
        >
          <v-icon>switch_camera</v-icon>
        </v-btn>
        <v-btn
          outline
          color="error"
          @click="close"
        >
          {{ $t('shipments.scanner.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  components: {},
  data: () => ({
    dialog: false,
    front: false,
    paused: false,
    loading: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    found: null,
    shipmentId: null,
    physicalScanner: false,
    physicalScannerError: false,
  }),
  methods: {
    listenToEvent(e) { // Used for Physical Scanner.
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
        this.alert = {
          value: true,
          type: 'error',
          message: this.$i18n.t('shipments.scanner.error_alert'),
        };
      } else {
        this.decode(this.buffer);
        this.buffer = '';
      }
    },
    switchCamera() {
      this.physicalScanner = false;
      this.alert.message = this.$i18n.t('shipments.scanner.scan_alert_cam');
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        audio: false,
        video: { facingMode: this.front ? 'user' : 'environment' },
      };
    },
    switchScanner() {
      this.physicalScanner = true;
      this.alert.message = this.$i18n.t('shipments.scanner.scan_alert_phys');
      document.addEventListener('keydown', this.listenToEvent);
    },
    decode(value) {
      if (value && value.length > 0) {
        this.loading = true;
        this.found = null;
        this.paused = true;
        getRequest('/shipment', `id=${value}`)
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.shipment && response.shipment.length > 0) {
              this.found = true;
              this.shipmentId = response.shipment[0]._id;
              this.alert = {
                value: true,
                type: 'success',
                message: `${this.$i18n.t('shipments.scanner.found_alert')} ${this.shipmentId}`,
              };
            } else {
              this.found = false;
              this.shipmentId = null;
              this.alert = {
                value: true,
                type: 'warning',
                message: `${this.$i18n.t('shipments.scanner.not_found_alert')} ${this.shipmentId}`,
              };
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.found = false;
            this.shipmentId = null;
            this.alert = {
              value: true,
              type: 'error',
              message: this.$i18n.t('shipments.scanner.error_alert'),
            };
          });
      }
    },
    selectShipment() {
      this.$emit('select-shipment', this.shipmentId);
      this.close();
    },
    reScan() {
      this.found = null;
      this.shipmentId = null;
      this.physicalScannerError = false;
      this.loading = false;
      this.paused = false;
      this.alert = {
        value: true,
        type: 'info',
        message: this.physicalScanner
          ? this.$i18n.t('shipments.scanner.scan_alert_phys')
          : this.$i18n.t('shipments.scanner.scan_alert_cam'),
      };
    },
    open() {
      this.dialog = true;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('shipments.scanner.scan_alert_cam'),
      };
    },
    close() {
      this.dialog = false;
      this.paused = false;
      this.loading = false;
      this.found = null;
      this.shipmentId = null;
      this.physicalScanner = false;
      this.physicalScannerError = false;
      this.alert = {
        value: false,
        type: 'success',
        message: '',
      };
      document.removeEventListener('keydown', this.listenToEvent);
      this.$emit('close');
    },
  },
};
</script>
