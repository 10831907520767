/* eslint-disable linebreak-style */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */

// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import FlagIcon from 'vue-flag-icon';
import VueI18n from 'vue-i18n';
import TreeView from 'vue-json-tree-view';
import VueQrCodeReader from 'vue-qrcode-reader';
import SocialSharing from 'vue-social-sharing';
// import SocketIO from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
import App from './App.vue';
import router from './router';
import store from './store';

import config from './config';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBXOe12FrfXIPfqnWfaxXF1hp9cGeNNZZs",
    authDomain: "lekotech-bcfce.firebaseapp.com",
    databaseURL: "https://lekotech-bcfce.firebaseio.com",
    projectId: "lekotech-bcfce",
    storageBucket: "lekotech-bcfce.appspot.com",
    messagingSenderId: "707455168557",
    appId: "1:707455168557:web:8bcbfbde4d6f7cb250c8a4",
    measurementId: "G-CQD08FYDFX"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
Vue.prototype.$serverUri = config.getServerUri();
Vue.prototype.$scrollToTop = () => { /* DISABLED */ /* window.scroll({ top: 0, left: 0, behavior: 'smooth' }) */ };

Vue.use(Vuetify, {
  // iconfont: 'fa',
});
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(FlagIcon);
Vue.use(VueI18n);
Vue.use(TreeView);
Vue.use(VueQrCodeReader);
Vue.use(SocialSharing);
/*
Vue.use(new VueSocketIO({
  connection: SocketIO(config.server, { secure: true, rejectUnauthorized: false }),
  // connection: config.server,
  vuex: {
    store,
    actionPrefix: 'socketAction_',
    mutationPrefix: 'socketMutation_',
  },
}));
*/
// Vue.config.productionTip = false;

const locales = {
  en: require('./assets/locales/en.json'),
  sq: require('./assets/locales/sq.json'),
  it: require('./assets/locales/it.json'),
};

const i18n = new VueI18n({
  locale: 'en',
  messages: locales,
});

// eslint-disable-next-line no-new
new Vue({
  /*
  sockets: {
    connect() {
      console.log('socket connected');
    },
    active_method(data) {
      store.dispatch('activeAction', data);
    },
  },
  */
  i18n,
  el: '#app',
  data: {
    token: '',
  },
  router,
  store,
  render: h => h(App),
});

//
