<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="600"
  >
    <!-------------------------------------------STRING-------------------------------------------->
    <v-card
      v-if="dialog && imgObject.type === 'string'"
      flat
      color="grey lighten-4"
      class="d-flex"
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              icon
              @click="rotateL"
            >
              <v-icon>rotate_left</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="rotateR"
            >
              <v-icon>rotate_right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-carousel
            v-model="currentImageIndex"
            :cycle="false"
            height="auto"
          >
            <v-carousel-item
              v-for="(image, index) in imgObject.images"
              :key="index"
            >
              <v-layout
                row
                align-center
                justify-center
                fill-height
              >
                <v-img
                  ref="image"
                  :style="{ 'transform': `rotate(${rotation}deg)` }"
                  :src="`${server}/images?q=${image.image}`"
                  aspect-ratio="1"
                  contain
                  style="width: 100%; height: auto;"
                  @mousemove="zoomIn"
                  @mouseleave="zoomOut"
                >
                  <template v-slot:placeholder>
                    <v-layout
                      fill-height
                      align-center
                      justify-center
                      ma-0
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-layout>
                  </template>
                </v-img>
              </v-layout>
            </v-carousel-item>
          </v-carousel>
        </v-flex>
      </v-layout>
    </v-card>
    <!-------------------------------------------DATAURL------------------------------------------->
    <v-card
      v-else-if="dialog && imgObject.type === 'dataUrl'"
      flat
      color="grey darken-4"
      class="d-flex"
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              dark
              icon
              @click="rotateL"
            >
              <v-icon>rotate_left</v-icon>
            </v-btn>
            <v-btn
              dark
              icon
              @click="rotateR"
            >
              <v-icon>rotate_right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-carousel
            :cycle="false"
            height="auto"
          >
            <v-carousel-item
              v-for="(image, index) in imgObject.images"
              :key="index"
            >
              <v-layout
                row
                align-center
                justify-center
                fill-height
              >
                <v-img
                  :style="{ 'transform': `rotate(${rotation}deg)` }"
                  :src="image"
                  aspect-ratio="1"
                  contain
                >
                  <template v-slot:placeholder>
                    <v-layout
                      fill-height
                      align-center
                      justify-center
                      ma-0
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-layout>
                  </template>
                </v-img>
              </v-layout>
            </v-carousel-item>
          </v-carousel>
        </v-flex>
      </v-layout>
    </v-card>

    <!--
    <v-carousel  height="auto" :cycle="false">
      <v-carousel-item v-for="(image, index) in imgObject.images" :key="index" :src="image">
        <v-layout row justify-end>
          <v-btn class="ma-2" outline icon large color="red" @click="close">
            <v-icon x-large>close</v-icon>
          </v-btn>
        </v-layout>
      </v-carousel-item>
    </v-carousel>
    -->
  </v-dialog>
</template>

<script>
import axios from 'axios';
import zoom from '../utils/zoom';

export default {
  data: () => ({
    dialog: false,
    rotation: 0,
    images: [],
    currentImageIndex: 0,
    imgObject: {
      type: null,
      id: null,
      date: null,
      images: [],
    },
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  watch: {
    dialog(value) {
      if (!value) { this.close(); }
    },
  },
  methods: {
    open(data) {
      this.imgObject = data;
      this.dialog = true;
    },
    close() {
      this.imgObject = {
        type: null,
        id: null,
        date: null,
        images: [],
      };
      this.rotation = 0;
      this.dialog = false;
      this.currentImageIndex = 0;
    },
    rotateR() {
      this.rotation += 90;
      const imgSrc = this.$refs.image[this.currentImageIndex].src;
      axios.put(imgSrc, { rotate: this.rotation });
    },
    rotateL() {
      this.rotation -= 90;
      const imgSrc = this.$refs.image[this.currentImageIndex].src;
      axios.put(imgSrc, { rotate: this.rotation });
    },
    zoomIn(event) { zoom.zoomIn(2.4, event, this.rotation); },
    zoomOut(event) { zoom.zoomOut(event, this.rotation); },
  },
};
</script>
