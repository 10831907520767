<template>
  <div>
    <v-dialog
      v-model="loading"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alert.value"
      max-width="500"
    >
      <v-alert
        v-model="alert.value"
        :type="alert.type"
      >
        {{ $t(alert.message) }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="addEbayModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_ebay') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddEbay()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelAddEbay()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteEbayModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_delete_ebay') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptDeleteEbay()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelDeleteEbay()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successAddEbayModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="success"
        >
          <strong> {{ $t('parts.inventory.success_ebay') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="successAddEbay()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorEbayModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="error"
        >
          <strong> {{ errorMsg ? errorMsg : $t('parts.inventory.error_ebay') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="errorEbay()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alreadyOnEbayModal"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in onEbay"
          :key="index"
          :value="true"
          type="warning"
        >
          <strong>
            {{ item.infoCar && item.infoCar.description && item.infoCar.description.descriptionId && item.infoCar.description.descriptionId.title ? item.infoCar.description.descriptionId.title.it : '' }}
          </strong>
          {{ $t('parts.inventory.already_on_ebay') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeAlreadyEbay()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in ebayResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_ebay') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeEbayMultiple()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayRemoveMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in ebayResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_deleted_ebay') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="ebayRemoveMultipleDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="acceptAddMultipleEbayDialog"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_multiple_ebay') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddMultipleEbay()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelEbayMultiple()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="kTypesDialog"
      persistent
      width="350"
    >
      <v-flex xs12>
        <v-toolbar
          flat
          dark
          style="background-color: #D32F2F"
        >
          <v-toolbar-title>{{ $t('parts.inventory.ktype_codes') }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            outline
            icon
            small
            color="white"
            @click="closeKtypesDialog"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-flex>
      <v-card
        class="pa-3"
        height="400"
        style="overflow: scroll;"
      >
        <div
          class="col-12 text-center"
          style="display: ruby"
        >
          <v-card
            v-for="(item, index) in kTypes"
            :key="index"
            width="75"
            class="mb-2"
          >
            {{ item }}
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import getRequest from '../../api/getRequest';


export default {
  components: {
  },
  data: () => ({
    carData: null,
    xmlData: null,
    successAddEbayModal: false,
    errorEbayModal: false,
    addEbayModal: false,
    deleteEbayModal: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    alreadyOnEbayModal: false,
    onEbay: [],
    ebayResponse: null,
    ebayMultipleDialog: false,
    loading: false,
    multipleItems: [],
    acceptAddMultipleEbayDialog: false,
    ebayRemoveMultipleDialog: false,
    errorMsg: null,
    kTypesDialog: false,
    kTypes: [],
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    errorEbay() {
      this.errorEbayModal = false;
    },
    successAddEbay() {
      this.successAddEbayModal = false;
      this.close();
    },
    acceptDeleteEbay() {
      this.loading = true;
      axios.get(`${this.baseUrl}/ebay/delete-item/${this.ebayItem._id}?institutionId=${this.$store.getters.institutionId}`, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          this.loading = false;
          console.log('resss', res);
          this.deleteEbayModal = false;
          this.alert = {
            value: true,
            type: 'success',
            message: 'parts.inventory.successfully_removed_ebay',
          };
          setTimeout(() => { this.alert.value = false; }, 3000);
          this.carData = null;
          this.xmlData = null;
          this.ebayItem = null;
        }
      }).catch((err) => {
        console.log('ebay error', err.response.data);
        this.loading = false;
        this.deleteEbayModal = false;
        if (err && err.response && err.response.data && err.response.data.errorMsg && err.response.data.errorMsg != null) {
          this.errorEbayModal = true;
          this.errorMsg = err.response.data.errorMsg;
        }
      })
        .finally(() => {
          this.deleteEbayModal = false;
          this.loading = false;
          this.$emit('close');
        });
    },
    endItemEbay(item) {
      axios.get(`${this.baseUrl}/ebay/delete-item/${item._id}?institutionId=${this.$store.getters.institutionId}`, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          console.log('resss', res);
          return res;
        }
      }).catch((err) => {
        console.log('ebay error', err.response.data);
        return err.response.data;
      });
    },
    cancelDeleteEbay() {
      this.deleteEbayModal = false;
    },
    acceptAddEbay(item) {
      let itemToSend = null;
      if (item) {
        itemToSend = item;
      } else {
        itemToSend = this.ebayItem;
      }
      this.addEbayModal = false;
      this.loading = true;
      // baseURL is localhost for now in order to test is locally
      axios.get(`${this.baseUrl}/ebay/add-item/${itemToSend._id}?institution_id=${this.$store.getters.institutionId}`, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          this.loading = false;
          console.log('resss', res);
          this.successAddEbayModal = true;
        }
      }).catch((err) => {
        this.loading = false;
        console.log('ebay error', err.response.data);
        if (err && err.response && err.response.data && err.response.data.errorMsg && err.response.data.errorMsg != null) {
          this.errorEbayModal = true;
          this.errorMsg = err.response.data.errorMsg;
        }
      })
        .finally(() => {
          this.loading = false;
          this.$emit('close');
        });
    },
    cancelAddEbay() {
      this.addEbayModal = false;
    },
    async openAddEbay(item) {
      this.carData = null;
      this.xmlData = null;
      this.ebayItem = item;
      if (item.car_id) {
        await this.getCarData(item.car_id);
      }
      console.log('inside add ebay dialog', item);

      this.addEbayModal = true;
    },
    async getCarData(id) {
      await getRequest('/car/', `id=${id}`)
        .then((response) => { this.carData = response.car; })
        .catch((error) => { console.log(error); });
    },
    openDeleteEbay(item) {
      this.ebayItem = item;
      console.log('inside delete ebay dialog', item);
      this.deleteEbayModal = true;
    },
    addMultipleEbayItems(items) {
      console.log('items selected', items.length);
      // Check if more than 5 items are selected
      if (items.length > 5) {
        console.log('inside itemslengt > 5');
        this.alert = {
          value: true,
          type: 'error',
          message: 'parts.inventory.max_5_items',
        };
        setTimeout(() => { this.alert.value = false; }, 5000);
      } else {
        items.forEach((item) => {
          console.log('ebaystatus', item.eBayStatus);
          if (item.eBayStatus == true) {
            this.onEbay.push(item);
          }
        });
        if (this.onEbay.length > 0) {
          this.alreadyOnEbayModal = true;
        } else {
          this.multipleItems = items;
          this.acceptAddMultipleEbayDialog = true;
        }
      }
    },
    deleteMultipleEbayItems(items) {
      const itemIds = items.map(item => item._id);

      this.loading = true;
      axios.post(`${this.baseUrl}/ebay/delete-items`, { ids: itemIds, institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      })
        .then((response) => {
          console.log('Items successfully added to eBay:', response.data);
          this.loading = false;
          this.ebayResponse = response.data;
          this.ebayRemoveMultipleDialog = true;
          this.multipleItems = [];
        })
        .catch((error) => {
          console.error('Error adding items to eBay:', error);
          this.loading = false;
          this.multipleItems = [];
          this.alert = {
            value: true,
            type: 'error',
            message: 'parts.inventory.error_adding_items',
          };
          setTimeout(() => { this.alert.value = false; }, 5000);
        })
        .finally(() => {
          this.loading = false;
          this.multipleItems = [];
          this.$emit('close');
        });
    },
    acceptAddMultipleEbay() {
      const items = this.multipleItems;
      console.log('items', items);
      this.acceptAddMultipleEbayDialog = false;
      //  Create an array of item IDs
      const itemIds = items.map(item => item._id);
      console.log('itemIds', itemIds);
      this.loading = true;
      axios.post(`${this.baseUrl}/ebay/add-items`, { ids: itemIds, institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      })
        .then((response) => {
          console.log('Items successfully added to eBay:', response.data);
          this.loading = false;
          this.ebayResponse = response.data;
          this.ebayMultipleDialog = true;
          this.multipleItems = [];
        })
        .catch((error) => {
          console.error('Error adding items to eBay:', error);
          this.loading = false;
          this.multipleItems = [];
          this.alert = {
            value: true,
            type: 'error',
            message: 'parts.inventory.error_adding_items',
          };
          setTimeout(() => { this.alert.value = false; }, 5000);
        })
        .finally(() => {
          this.loading = false;
          this.multipleItems = [];
          this.$emit('close');
        });
    },
    cancelEbayMultiple() {
      this.acceptAddMultipleEbayDialog = false;
      this.ebayResponse = null;
      this.multipleItems = [];
    },
    closeAlreadyEbay() {
      this.alreadyOnEbayModal = false;
      this.onEbay = [];
    },
    closeEbayMultiple() {
      this.ebayMultipleDialog = false;
      this.ebayResponse = null;
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    showActionsErrorModal(msg) {
      this.errorEbayModal = true;
      this.errorMsg = msg;
    },
    syncWithEbay() {
      axios.post(`${this.baseUrl}/ebay/sync-with-ebay`, { institutionId: this.$store.getters.institutionId }, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          console.log('resss', res);
        }
      }).catch((err) => {
        console.log('ebay error', err.response.data);
      })
        .finally(() => {
          this.$emit('close');
        });
    },
    checkKtypes(item) {
      this.kTypesDialog = true;
      this.kTypes = item.infoCar.kTypes;
    },
    closeKtypesDialog() {
      this.kTypesDialog = false;
      this.kTypes = [];
    },
  },
};
</script>
