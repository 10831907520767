<template>
  <div>
    <v-data-table
      ref="dataTable"
      v-model="selected"
      :items="parts"
      :pagination.sync="pagination"
      :total-items="totalItems"
      :headers="role === 'LekoAdmin' ? headersAdmin : headers"
      :expand="expand"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      item-key="_id"
      select-all
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />

      <template
        slot="group"
        slot-scope="props"
      >
        <v-layout
          align-center
          row
        >
          <span
            v-if="props.groupName"
            class="ml-5 mr-2"
          >{{ $t('insert_parts.kit_qr') }}</span>
          <span
            v-if="props.groupName"
            class="ma-1"
          >
            <qr-code
              :size="50"
              :text="props.groupName"
            />
          </span>
        </v-layout>
      </template>
      <!----------------------------------------MAIN TABLE----------------------------------------->
      <template v-slot:items="props">
        <tr
          :class="{tableRowColorSelected : props.index == activeRow}"
          class="tableRowColor"
          @click="{
            activeRow = props.index,
            props.expanded = !props.expanded,
            expandedKit = {value: props.expanded, id: props.item.kit_id},
            getAdditionalParts(props.item.kit_id, props.expanded)
          }"
        >
          <!---------------------------------------SELECTION--------------------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <v-checkbox
                v-model="props.selected"
                :disabled="props.item.status === 4 || props.item.status === 5"
                class="mt-2"
                color="primary"
                hide-details
              />
            </v-layout>
          </td>
          <!----------------------------------------QR CODE---------------------------------------->
          <td class="ma-1 pt-1 pb-1">
            <v-layout
              class="pointer"
              ma-1
              column
              align-center
              justify-center
              @click="openQrDialog({
                id: props.item._id,
                type: 'temporary-parts',
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item._id"
              />
              <h4>{{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}</h4>
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td class="justify-center">
            <v-layout row>
              <v-btn icon @click="actions(props.item)">
                <v-icon color="grey darken-2">compare_arrows</v-icon>
              </v-btn>
              <v-btn icon @click="editPart(props.item, false, props.index)">
                <v-icon color="grey darken-2">edit</v-icon>
              </v-btn>
              <v-btn icon @click="deletePart(props.item._id, props.item.kit_id)">
                <v-icon color="grey darken-2">delete</v-icon>
              </v-btn>
            </v-layout>
          </td>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <td
            v-if="role === 'LekoAdmin' && props.item.institution_id"
            class="text-xs"
          >
            {{ props.item.institution_id.title }}
          </td>
          <td v-else-if="role === 'LekoAdmin' && !props.item.institution_id" />
          <!-----------------------------------------USER------------------------------------------>
          <td
            v-if="role === 'LekoAdmin' && props.item.user_id"
            class="text-xs"
          >
            {{ props.item.user_id.name }}
          </td>
          <td v-else-if="role === 'LekoAdmin' && !props.item.user_id" />
          <!--------------------------------------PRA NUMBER--------------------------------------->
          <td class="text-xs-center">
            {{ props.item.pra }}
          </td>
          <!-----------------------------------------BRAND----------------------------------------->
          <td class="text-xs-center">
            {{ props.item.brand }}
          </td>
          <!---------------------------------------EBAY CODE--------------------------------------->
          <td class="text-xs-center">
            {{ props.item.ebayCode }}
          </td>
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            <v-layout
              row
              align-center
              justify-center
              class="pointer"
              @click="showNotes(props.item.notes)"
            >
              <v-flex>
                <span v-if="props.item.notes && props.item.notes.length > 50">
                  {{ props.item.notes.substring(0, 50) }} ...
                </span>
                <span v-else> {{ props.item.notes }} </span>
              </v-flex>
              <v-flex v-if="props.item.notes && props.item.notes.length > 50">
                <v-icon>visibility</v-icon>
              </v-flex>
            </v-layout>
          </td>
          <!--------------------------------CLASSIFICATION WITH AI--------------------------------->
          <td class="text-xs-center">
            {{ props.item.classificationWithAI }}
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout align-center justify-center>
              <img
                :src="`${server}/images?q=${props.item.images && props.item.images.length
                  ? props.item.images[0].thumb : ''}`"
                class="pointer"
                height="75"
                @click="showImages(props.item.images)"
              >
            </v-layout>
          </td>
          <!-----------------------------------------AUDIO----------------------------------------->
          <td class="text-xs-center">
            <v-layout>
              <v-btn
                v-if="props.item.audio && !props.item.loadAudio"
                outline
                @click="props.item.loadAudio = true"
              >
                {{ $t('parts.insert_parts.load_recording') }}
                <v-icon right>
                  mic
                </v-icon>
              </v-btn>
              <audio
                v-if="props.item.loadAudio"
                :src="`${server}/audio/?q=${props.item.audio}`"
                controls
              />
            </v-layout>
          </td>
          <!---------------------------------------OEM CODE---------------------------------------->
          <td class="text-xs">
            {{ props.item.infoCar.oemCode }}
          </td>
          <!--------------------------------------PRICE/COST--------------------------------------->
          <td class="text-xs text-no-wrap">
            <span v-if="props.item.price">{{ $t('parts.table.price') }}: {{ props.item.price }} {{ props.item.currency }}</span>
            <span v-else> </span>
            <br />
            <span v-if="props.item.cost">{{ $t('parts.table.cost') }}: {{ props.item.cost }} {{ props.item.currency }}</span>
            <span v-else> </span>
          </td>
          <!---------------------------------------LOCATION---------------------------------------->
          <td
            v-if="props.item.shelf_id && props.item.shelf_data"
            class="text-xs"
          >
            <span>
              {{ props.item.shelf_data.shelf.room_name }} -
              {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},
              {{ props.item.shelf_data.shelf.y }}
            </span>

            <div class="text-no-wrap ">
              <span class="caption">Mag: </span>
              <span class="font-weight-bold caption">{{ props.item.shelf_data.repo }}</span>
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          >
            -
          </td>
          <!---------------------------------------SHIPMENT---------------------------------------->
          <td
            v-if="props.item.shipment_id"
            class="text-xs"
          >
            <a
              v-if="props.item.car_id"
              @click="viewCar(props.item.car_id)"
            > {{ props.item.shipment_id.name }} </a>
            <span v-else> {{ props.item.shipment_id.name }} </span>
          </td>
          <td
            v-else
            class="text-xs"
          />
          <!---------------------------------------WARRANTY---------------------------------------->
<!--          <td-->
<!--            v-if="props.item.warranty_id"-->
<!--            class="text-xs"-->
<!--          >-->
<!--            {{ props.item.warranty_id.return_days / 86400 }}-->
<!--            {{ $t('warranties.days') }}-->
<!--          </td>-->
<!--          <td-->
<!--            v-else-->
<!--            class="text-xs"-->
<!--          />-->
          <!----------------------------------------QUALITY---------------------------------------->
          <!-- Quality and Status -->
          <div style="padding-top: 15px; padding-bottom: 15px;">
          <td>
            <div v-if="props.item.quality" style="margin-left: 5px">
              {{ props.item.warranty_id.return_days / 86400 }} {{ $t('warranties.days') }}
              <v-rating
                :value="props.item.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                size="12"
              />
            </div>
            <div v-else class="text-xs"></div>
            <div style="margin-left: 0px">

              <div style="margin-left: 5px">{{ props.item.date_created | moment }}</div>
              <span v-if="props.item.status === 1">
      <v-chip label outline disabled color="primary">{{ $t('parts.status.available') }}</v-chip>
    </span>
              <span v-else-if="props.item.status === 2">
      <v-chip label outline disabled color="yellow darken-2">{{ $t('parts.status.waiting_approval') }}</v-chip>
    </span>
              <span v-else-if="props.item.status === 3">
      <v-chip label outline disabled color="yellow darken-2" @mouseover="activateSnackbar(props.item.internal_notes)">
        {{ $t('parts.status.review_needed') }}
      </v-chip>
    </span>
              <span v-else-if="props.item.status === 4">
      <v-chip label outline disabled color="error">{{ $t('parts.status.refused') }}</v-chip>
    </span>
              <span v-else-if="props.item.status === 5">
      <v-chip label outline disabled color="success">{{ $t('parts.status.approved') }}</v-chip>
    </span>
            </div>
          </td>
          </div>

        </tr>
      </template>
      <!----------------------------------KIT EXPANDED DATATABLE----------------------------------->
      <template
        v-if="props.item.kit"
        slot="expand"
        slot-scope="props"
      >
        <div class="tableBorder">
          <v-data-table
            :headers="role === 'LekoAdmin' ? headersAdmin : headers"
            :items="additionalParts"
            :loading="loadingAdditional"
            hide-actions
            class="tableRowColor"
          >
            <v-progress-linear
              slot="progress"
              color="red"
              indeterminate
            />
            <template
              slot="items"
              slot-scope="props"
            >
              <!--------------------------------------QR CODE-------------------------------------->
              <td class="ma-1 pt-1 pb-1">
                <v-layout
                  class="pointer"
                  ma-1
                  column
                  align-center
                  justify-center
                  @click="openQrDialog({
                    id: props.item._id,
                    type: 'temporary-parts',
                    printed: props.item.printed
                  })"
                >
                  <qr-code
                    :size="60"
                    :text="props.item._id"
                  />
                  <h4>
                    {{
                      props.item._id.slice(props.item._id.length-4, props.item._id.length)
                    }}
                  </h4>
                </v-layout>
              </td>
              <!--------------------------------------ACTIONS-------------------------------------->
              <td class="justify-center">
                <v-layout column>
                  <v-layout row>
                    <v-layout column>
                      <v-btn
                        icon
                        @click="editPart(props.item, false, props.index)"
                      >
                        <v-icon color="grey darken-2">
                          edit
                        </v-icon>
                      </v-btn>
                    </v-layout>
                    <v-layout column>
                      <v-btn
                        icon
                        @click="deletePart(props.item._id, props.item.kit_id)"
                      >
                        <v-icon color="grey darken-2">
                          delete
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-layout>
                  <v-layout row>
                    <v-layout column>
                      <v-btn
                        icon
                        @click="someOtherAction()"
                      >
                        <v-icon color="grey darken-2">
                          some_other_icon
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-layout>
                </v-layout>
              </td>


              <!------------------------------------INSTITUTION------------------------------------>
              <td
                v-if="role === 'LekoAdmin' && props.item.institution_id"
                class="text-xs"
              >
                {{ props.item.institution_id.title }}
              </td>
              <td v-else-if="role === 'LekoAdmin' && !props.item.institution_id" />
              <!---------------------------------------USER---------------------------------------->
              <td
                v-if="role === 'LekoAdmin' && props.item.user_id"
                class="text-xs"
              >
                {{ props.item.user_id.name }}
              </td>
              <td v-else-if="role === 'LekoAdmin' && !props.item.user_id" />
              <!--------------------------------------IMAGES--------------------------------------->
              <td>
                <v-layout align-center justify-center>
                  <img
                    :src="`${server}/images?q=${props.item.images[0].thumb}`"
                    class="pointer"
                    height="75"
                    @click="showImages(props.item.images)"
                  >
                </v-layout>
              </td>
              <!---------------------------------------AUDIO--------------------------------------->
              <td class="text-xs-center">
                <v-layout>
                  <v-btn
                    v-if="props.item.audio && props.item.audio.exists && !props.item.loadAudio"
                    outline
                    @click="props.item.loadAudio = true"
                  >
                    {{ $t('parts.insert_parts.load_recording') }}
                    <v-icon right>
                      mic
                    </v-icon>
                  </v-btn>
                  <audio
                    v-if="props.item.loadAudio"
                    :src="`${server}/audio/?q=${props.item._id}-${props.item.audio.title
                    }&date=${props.item.date_created}`"
                    controls
                  />
                </v-layout>
              </td>
              <!---------------------------------------PRICE--------------------------------------->
              <td class="text-xs">
                {{ props.item.price }}
                <span v-if="props.item.price">{{ props.item.currency }}</span>
              </td>
              <!----------------------------------------COST--------------------------------------->
              <td class="text-xs">
                {{ props.item.cost }}
                <span v-if="props.item.cost">{{ props.item.currency }}</span>
              </td>
              <!-------------------------------------LOCATION-------------------------------------->
              <td
                v-if="props.item.shelf_id && props.item.shelf_data"
                class="text-xs"
              >
                <span>
                  {{ props.item.shelf_data.shelf.room_name }} -
                  {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},
                  {{ props.item.shelf_data.shelf.y }}
                </span>

                <div class="text-no-wrap ">
                  <span class="caption">Mag: </span>
                  <span class="font-weight-bold caption">{{ props.item.shelf_data.repo }}</span>
                </div>
              </td>
              <td
                v-else
                class="text-xs"
              >
                -
              </td>
              <!--------------------------------------SHIPMENT------------------------------------->
              <td
                v-if="props.item.shipment_id"
                class="text-xs"
              >
                <a
                  v-if="props.item.car_id"
                  @click="viewCar(props.item.car_id)"
                > {{ props.item.shipment_id.name }} </a>
                <span v-else> {{ props.item.shipment_id.name }} </span>
              </td>
              <td
                v-else
                class="text-xs"
              />
              <!--------------------------------------WARRANTY------------------------------------->
<!--              <td-->
<!--                v-if="props.item.warranty_id"-->
<!--                class="text-xs"-->
<!--              >-->
<!--                {{ props.item.warranty_id.title }}-->
<!--              </td>-->
<!--              <td-->
<!--                v-else-->
<!--                class="text-xs"-->
<!--              />-->
              <!--------------------------------------QUALITY-------------------------------------->
<!--              <td v-if="props.item.quality">-->
<!--                <div class="flex items-center">-->
<!--                  <span class="mr-2">{{ props.item.warranty_id.return_days / 86400 }} {{ $t('warranties.days') }}</span>-->
<!--                  <v-rating :value="props.item.quality.rating" color="red" background-color="red darken-4" readonly dense size="12" />-->
<!--                </div>-->
<!--              </td>-->
<!--              <td v-else class="text-xs" />-->

              <!-----------------------------------DATE CREATED------------------------------------>
<!--              <td>{{ props.item.date_created | moment }}</td>-->
              <!--------------------------------------STATUS--------------------------------------->

              <td v-if="props.item.quality">
                <div class="flex items-center">
                  <span class="mr-2">{{ props.item.warranty_id.return_days / 86400 }} {{ $t('warranties.days') }}</span>
                  <v-rating :value="props.item.quality.rating" color="red" background-color="red darken-4" readonly dense size="12" />
                </div>
              </td>

              <td v-else class="text-xs" />
              <td v-if="props.item.status === 1">
                <v-chip
                  label
                  outline
                  disabled
                  color="primary"
                >
                  {{ $t('parts.status.available') }}
                </v-chip>
              </td>
              <td v-else-if="props.item.status === 2">
                <v-chip
                  label
                  outline
                  disabled
                  color="yellow darken-2"
                >
                  {{ $t('parts.status.waiting_approval') }}
                </v-chip>
              </td>
              <td v-else-if="props.item.status === 3">
                <v-chip
                  label
                  outline
                  disabled
                  color="yellow darken-2"
                  @mouseover="activateSnackbar(props.item.internal_notes)"
                >
                  {{ $t('parts.status.review_needed') }}
                </v-chip>
              </td>
              <td v-else-if="props.item.status === 4">
                <v-chip
                  label
                  outline
                  disabled
                  color="error"
                >
                  {{ $t('parts.status.refused') }}
                </v-chip>
              </td>
              <td v-else-if="props.item.status === 5">
                <v-chip
                  label
                  outline
                  disabled
                  color="success"
                >
                  {{ $t('parts.status.approved') }}
                </v-chip>
              </td>
              <!----------------------------------------------------------------------------------->
            </template>
          </v-data-table>
        </div>
      </template>
      <!------------------------------------------------------------------------------------------->
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator'; // eslint-disable-line import/extensions

import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search', 'selected_items'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    activeRow: null,
    expand: false,
    loading: false,
    loadingAdditional: false,
    headers: [
      {
        text: 'QR Code',
        sortable: false,
        value: '_id',
      },
      {
        text: 'Actions',
        sortable: false,
      },
      {
        text: 'Pra',
        sortable: true,
        value: 'pra',
      },
      {
        text: 'Brand',
        sortable: true,
        value: 'brand',
      },
      {
        text: 'Ebay Code',
        sortable: false,
        value: 'ebayCode',
      },
      {
        text: 'Notes',
        sortable: false,
      },
      {
        text: 'Classification With AI',
        sortable: false,
      },
      {
        text: 'Images',
        sortable: false,
        value: 'images',
      },
      {
        text: 'Audio',
        sortable: false,
        value: 'audio',
      },
      {
        text: 'OEM Code',
        sortable: true,
        value: '',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'price',
      },
      {
        text: 'Location',
        sortable: false,
        value: '',
      },
      {
        text: 'Shipment Name',
        sortable: false,
        value: 'shipment_id',
      },
      // {
      //   text: 'Warranty ID',
      //   sortable: false,
      //   value: 'warranty_id',
      // },
      // {
      //   text: 'Quality',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: 'Status',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
    headersAdmin: [
      {
        text: 'QR Code',
        sortable: false,
        value: '_id',
      },
      {
        text: 'Actions',
        sortable: false,
      },
      {
        text: 'Institution',
        sortable: false,
        value: 'institution',
      },
      {
        text: 'User',
        sortable: false,
        value: 'user',
      },
      {
        text: 'Pra',
        sortable: true,
        value: 'pra',
      },
      {
        text: 'Brand',
        sortable: true,
        value: 'brand',
      },
      {
        text: 'Ebay Code',
        sortable: false,
        value: 'ebayCode',
      },
      {
        text: 'Notes',
        sortable: false,
      },
      {
        text: 'Classification With AI',
        sortable: false,
      },
      {
        text: 'Images',
        sortable: false,
        value: 'images',
      },
      {
        text: 'Audio',
        sortable: false,
        value: 'audio',
      },
      {
        text: 'OEM Code',
        sortable: true,
        value: '',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'price',
      },
      {
        text: 'Location',
        sortable: false,
        value: '',
      },
      {
        text: 'Shipment Name',
        sortable: false,
        value: 'shipment_id',
      },
      // {
      //   text: 'Warranty ID',
      //   sortable: false,
      //   value: 'warranty_id',
      // },
      // {
      //   text: 'Quality',
      //   sortable: true,
      //   value: 'quality',
      // },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: 'Status',
      //   sortable: true,
      //   value: 'status',
      // },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    parts: [],
    totalItems: 0,
    additionalParts: [],
    selected: [],
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    role() {
      return this.$store.getters.role;
    },
    searchObject() {
      return this.$props.search;
    },
    selectedStatusItems() {
      return this.$props.selected_items;
    },
  },
  watch: {
    selected: {
      handler(data) {
        const items = data.reduce((accumulator, current) => {
          if (current.status === 4 || current.status === 5) {
            setTimeout(() => {
              /*
               * The watcher is triggered before the changes are reflected in the datatable
               * component, so we 'wait' for the changes to be applied.
               * This is a dumb solution, but couldn't find anything better at the moment.
               */
              const { selected } = this.$refs.dataTable;
              selected[current._id.toString()] = false;
              this.$refs.dataTable.$forceUpdate(); // re-render.
            }, 0); // Executes at next tick.
          } else { accumulator.push(current); }
          return accumulator;
        }, []);

        if (items.length > 0) {
          this.$emit('approve', { value: true, items });
        } else {
          this.$emit('approve', { value: false, items });
        }
      },
      deep: true,
    },
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getParts(this.selectedStatusItems);
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data, this.selectedStatusItems);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getParts(this.selectedStatusItems);
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data, this.selectedStatusItems);
        } else {
          console.log('search type is invalid');
        }
      },
    },
    selectedStatusItems: {
      handler(value) {
        if (this.searchObject.type === null) {
          this.getParts(value);
        }
      },
      deep: true,
    },
    parts(value) {
      this.$emit('items', value);
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.table.qr_code');
      this.headers[1].text = this.$i18n.t('parts.table.actions');
      this.headers[2].text = this.$i18n.t('parts.table.pra');
      this.headers[3].text = this.$i18n.t('parts.table.brand');
      this.headers[4].text = this.$i18n.t('parts.table.ebay_code');
      this.headers[5].text = this.$i18n.t('parts.table.notes');
      this.headers[6].text = this.$i18n.t('parts.table.classification_with_ai');
      this.headers[7].text = this.$i18n.t('parts.table.images');
      this.headers[8].text = this.$i18n.t('parts.table.audio');
      this.headers[9].text = this.$i18n.t('parts.table.oem_code');
      this.headers[10].text = this.$i18n.t('parts.table.price');
      this.headers[11].text = this.$i18n.t('parts.table.location');
      this.headers[12].text = this.$i18n.t('parts.table.shipment');
      // this.headers[10].text = this.$i18n.t('parts.table.warranty');
      // this.headers[10].text = this.$i18n.t('parts.table.quality');
      this.headers[13].text = this.$i18n.t('parts.table.date_created');
      // this.headers[10].text = this.$i18n.t('parts.table.status');
      this.headersAdmin[0].text = this.$i18n.t('parts.table.qr_code');
      this.headersAdmin[1].text = this.$i18n.t('parts.table.actions');
      this.headersAdmin[2].text = this.$i18n.t('parts.table.institution');
      this.headersAdmin[3].text = this.$i18n.t('parts.table.user');
      this.headersAdmin[4].text = this.$i18n.t('parts.table.pra');
      this.headersAdmin[5].text = this.$i18n.t('parts.table.brand');
      this.headersAdmin[6].text = this.$i18n.t('parts.table.ebay_code');
      this.headersAdmin[7].text = this.$i18n.t('parts.table.notes');
      this.headersAdmin[8].text = this.$i18n.t('parts.table.classification_with_ai');
      this.headersAdmin[9].text = this.$i18n.t('parts.table.images');
      this.headersAdmin[10].text = this.$i18n.t('parts.table.audio');
      this.headersAdmin[11].text = this.$i18n.t('parts.table.oem_code');
      this.headersAdmin[12].text = this.$i18n.t('parts.table.price');
      this.headersAdmin[13].text = this.$i18n.t('parts.table.location');
      this.headersAdmin[14].text = this.$i18n.t('parts.table.shipment');
      // this.headersAdmin[12].text = this.$i18n.t('parts.table.warranty');
      // this.headersAdmin[13].text = this.$i18n.t('parts.table.quality');
      this.headersAdmin[15].text = this.$i18n.t('parts.table.date_created');
      // this.headersAdmin[13].text = this.$i18n.t('parts.table.status');
    },
    getParts(statusArray) {
      this.loading = true;
      getRequest(
        '/temporary-part/insert/datatable/',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&status=${statusArray}`,
      )
        .then((response) => {
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.temporaryParts;
          this.loading = false;
          this.closeExpandedRow();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
        });
    },
    regularSearch(data, statusArray) {
      this.loading = true;
      getRequest(
        `/temporary-part/insert/search/${data.idOnly ? 'id' : 'regular'}`,
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&status=${statusArray
        }&search=${data.searchField}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.temporaryParts;
          this.loading = false;
          this.closeExpandedRow();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getAdditionalParts(kitId, expanded) {
      if (expanded && kitId) {
        this.loadingAdditional = true;
        getRequest('/temporary-part/additional', `kit_id=${kitId}`)
          .then((response) => {
            console.log(response);
            this.additionalParts = response.temporaryParts;
            this.loadingAdditional = false;
          })
          .catch((error) => {
            console.log(error);
            this.additionalParts = [];
            this.loadingAdditional = false;
          });
      } else {
        this.additionalParts = [];
      }
    },
    activateSnackbar(data) {
      this.$emit('activate-snackbar', data);
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    showImages(images) {
      this.$emit('images', { type: 'string', images });
    },
    actions(data) {
      this.$emit('actions', data);
    },
    editPart(data, kit) {
      this.$emit('edit', data, kit);
    },
    deletePart(id, kitId) {
      console.log(kitId);
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest(
          '/temporary-part/insert',
          `id=${id
          }&user_id=${this.$store.getters.userId
          }&institution_id=${this.$store.getters.institutionId}`,
        )
          .then((response) => {
            this.loading = false;
            console.log(response);
            this.$emit('delete');
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    updatePagination() {
      this.activeRow = null;
    },
    closeExpandedRow() {
      /**
       * TODO: make this smarter so it doesn't throw an error when there is no kit part expanded.
       */
      this.$set(this.$refs.dataTable.expanded, this.parts[this.activeRow]._id, false);
    },
    viewCar(carId) {
      this.$emit('view-car', { carId });
    },
    showNotes(notes) {
      this.$emit('show-notes', notes);
    },
  },
};
</script>
