import axios from 'axios';

import config from '../config';

const serverUri = config.getServerUri();

export default (routeString, queryString) => new Promise((resolve, reject) => {
  const { token } = localStorage;
  axios.delete(`${serverUri}${routeString}?${queryString}`, {
    headers: {
      authorization: token,
    },
  }).then((res) => {
    resolve(res);
  }).catch((err) => {
    reject(err);
  });
});
