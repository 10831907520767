<template>
  <v-layout
    align-center
    justify-center
    row
    wrap
  >
    <v-flex xs12>
      <v-alert
        :value="alert"
        type="error"
      >
        {{ $t('home.charts.alert') }}
      </v-alert>
    </v-flex>
    <!---------------------------------------TOTAL ACTIVITY---------------------------------------->
    <v-flex
      pl-2
      pr-2
      xs12
      md6
    >
      <v-card
        v-if="!noActivity"
        flat
      >
        <v-card-title>{{ $t('home.charts.total_activity') }}</v-card-title>
        <v-layout justify-center>
          <v-progress-circular
            v-if="totalActivity.loading"
            :size="50"
            class="mb-5"
            color="red"
            indeterminate
          />
        </v-layout>
        <chart
          v-if="!totalActivity.loading"
          :type="totalActivity.type"
          :data="totalActivity.data"
        />
      </v-card>
    </v-flex>
    <!--------------------------------------PAST 24H ACTIVITY-------------------------------------->
    <v-flex
      pl-2
      pr-2
      xs12
      md6
    >
      <v-card
        v-if="!noActivity"
        flat
      >
        <v-card-title>{{ $t('home.charts.past_24h_activity') }}</v-card-title>
        <v-layout justify-center>
          <v-progress-circular
            v-if="past24hActivity.loading"
            :size="50"
            class="mb-5"
            color="red"
            indeterminate
          />
        </v-layout>
        <chart
          v-if="!past24hActivity.loading"
          :type="past24hActivity.type"
          :data="past24hActivity.data"
        />
      </v-card>
    </v-flex>
    <!----------------------------------------PAST ACTIVITY---------------------------------------->
    <v-flex
      pa-2
      pt-3
      xs12
    >
      <v-card
        v-if="!noActivity"
        flat
      >
        <v-card-title>{{ $t('home.charts.past_activity') }}</v-card-title>
        <v-layout
          align-center
          justify-center
          wrap
        >
          <v-flex
            pa-4
            xs12
            md4
          >
            <v-layout row>
              <v-select
                v-model="pastActivity.timePeriod"
                :items="timePeriodItems"
                :label="$t('home.charts.time_period')"
                :hint="$t('home.charts.select_time_period')"
                solo
                flat
                item-text="title"
                item-value="value"
                persistent-hint
                color="red darken-2"
                background-color="grey lighten-4"
              />
              <v-btn
                icon
                @click="getPastUserActivity(userId)"
              >
                <v-icon color="grey darken-2">
                  cached
                </v-icon>
              </v-btn>
            </v-layout>
            <v-layout justify-center>
              <v-date-picker
                v-model="pastActivity.time"
                :type="pastActivity.timePeriod === 'Month' ? 'month' : 'date'"
                color="red darken-2"
                reactive
              />
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            md8
          >
            <v-layout justify-center>
              <v-progress-circular
                v-if="pastActivity.loading"
                :size="70"
                color="red"
                indeterminate
              />
            </v-layout>
            <chart
              v-if="!pastActivity.loading"
              :type="pastActivity.type"
              :data="pastActivity.data"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import Chart from '../chart.vue';

function generateRandomNumbers() {
  const arr = [];
  for (let a = 0; a < 3; a += 1) {
    arr.push(Math.floor(Math.random() * 256));
  }
  return arr;
}

export default {
  components: { chart: Chart },
  data: () => ({
    totalActivity: {
      loading: false,
      type: '',
      data: {},
    },
    past24hActivity: {
      loading: false,
      type: '',
      data: {},
    },
    pastActivity: {
      loading: false,
      type: '',
      data: {},
      timePeriod: 'Day',
      time: new Date().toISOString().substr(0, 10),
    },
    timePeriodItems: [
      { title: 'Month', value: 'Month' },
      { title: 'Week', value: 'Week' },
      { title: 'Day', value: 'Day' },
    ],
    statusString: '',
    noActivity: true,
    alert: false,
    userId: null,
  }),
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.timePeriodItems[0].title = this.$i18n.t('home.charts.time_period_items.month');
      this.timePeriodItems[1].title = this.$i18n.t('home.charts.time_period_items.week');
      this.timePeriodItems[2].title = this.$i18n.t('home.charts.time_period_items.day');
    },
    loadCharts(statusString, userId) {
      this.statusString = statusString;
      this.userId = userId;
      this.noActivity = false;
      this.getPast24hUserActivity(userId);
      this.getPastUserActivity(userId);
      this.getTotalUserActivity(userId);
    },
    getTotalUserActivity(userId) {
      this.totalActivity.loading = true;
      getRequest(
        `/history/user/single/${userId}/`,
        `status=${this.statusString}`,
      )
        .then((response) => {
          console.log(response);
          const datasets = [];
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in response) {
            const rnd = generateRandomNumbers();
            const color = `rgba(${rnd[0]},${rnd[1]},${rnd[2]}, 1)`;
            const obj = {
              label: key,
              backgroundColor: color,
              borderColor: color,
              data: [response[key]],
            };
            datasets.push(obj);
          }
          this.totalActivity.type = 'bar';
          this.totalActivity.data = { labels: ['Total Activity'], datasets };
          this.totalActivity.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.totalActivity = {
            loading: false,
            type: '',
            data: {},
          };
          this.noActivity = true;
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
    },
    getPast24hUserActivity(userId) {
      this.past24hActivity.loading = true;
      getRequest(
        `/history/user/single/${userId}/`,
        `recent=true&details=false&status=${this.statusString}`,
      )
        .then((response) => {
          console.log(response);
          const datasets = [];
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in response) {
            const rnd = generateRandomNumbers();
            const color = `rgba(${rnd[0]},${rnd[1]},${rnd[2]}, 1)`;
            const obj = {
              label: key,
              backgroundColor: color,
              borderColor: color,
              data: [response[key]],
            };
            datasets.push(obj);
          }

          this.past24hActivity.type = 'bar';
          this.past24hActivity.data = { labels: ['Past 24h Activity'], datasets };
          this.past24hActivity.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.past24hActivity = {
            loading: false,
            type: '',
            data: {},
          };
          this.noActivity = true;
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
    },
    getPastUserActivity(userId) {
      console.log(this.pastActivity);
      this.pastActivity.loading = true;
      getRequest(
        `/history/user/single/${userId}/`,
        `recent=true&details=true&period=${this.pastActivity.timePeriod
        }&time=${this.pastActivity.time}&status=${this.statusString}`,
      )
        .then((response) => {
          // LABELS
          const labels = [];
          response[Object.keys(response)[0]].forEach((element) => {
            if (this.pastActivity.timePeriod === 'Day') {
              labels.push(`${moment(element.startingTime).format('LT')
              } - ${moment(element.endingTime).format('LT')}`);
            } else if (this.pastActivity.timePeriod === 'Week') {
              labels.push(`${moment(element.startingTime).format('ddd, MMM D')}`);
            } else if (this.pastActivity.timePeriod === 'Month') {
              labels.push(`${moment(element.startingTime).format('D')}`);
            }
          });
          // DATASETS
          const datasets = [];
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in response) {
            const rnd = generateRandomNumbers();
            const data = [];
            response[key].forEach((element) => {
              data.push(element.value);
            });
            const obj = {
              label: key,
              backgroundColor: `rgba(${rnd[0]},${rnd[1]},${rnd[2]}, 0.02)`,
              borderColor: `rgba(${rnd[0]},${rnd[1]},${rnd[2]})`,
              data,
            };
            datasets.push(obj);
          }
          this.pastActivity.type = 'line';
          this.pastActivity.data = { labels, datasets };
          this.pastActivity.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.pastActivity = {
            loading: false,
            type: '',
            data: {},
          };
          this.noActivity = true;
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
          }, 2000);
        });
    },
  },
};
</script>
