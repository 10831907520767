<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
  >
    <v-card>
      <v-card-title class="red darken-2">
        <span class="headline">{{ $t('vehicle_entry.videos') }}</span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout
          column
          align-center
          justify-center
          wrap
        >
          <!----------------------------------------SWITCH----------------------------------------->
          <v-flex xs12>
            <v-switch
              v-model="cameraSwitch"
              :label="$t('videos.camera')"
              color="primary"
            />
          </v-flex>
          <!----------------------------------------UPLOAD----------------------------------------->
          <v-flex
            v-if="!cameraSwitch && !video"
            xs12
          >
            <v-btn
              color="primary"
              outline
              @click="openInputComponent"
            >
              <v-icon left>
                attach_file
              </v-icon>
              {{ $t('videos.upload') }}
            </v-btn>

            <input
              ref="input"
              type="file"
              style="display: none"
              accept="video/mp4"
              @change="onInput"
            >
          </v-flex>
          <!----------------------------------------CAMERA----------------------------------------->
          <v-flex
            xs12
            :class="{'d-none': video || !cameraSwitch }"
          >
            <video-recorder
              v-if="dialog"
              ref="videoRecorder"
              @video="saveVideo"
            />
          </v-flex>
          <!-----------------------------------------VIDEO----------------------------------------->
          <v-flex
            xs12
            :class="{'d-none': !video }"
          >
            <v-card flat>
              <v-layout
                align-center
                justify-center
              >
                <video
                  :src="videoSrc"
                  :width="videoWidth"
                  controls
                />
              </v-layout>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red darken-1"
                  @click="deleteVideo"
                >
                  {{ $t('delete') }}
                  <v-icon right>
                    delete
                  </v-icon>
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          flat
          @click="close"
        >
          {{ $i18n.t('vehicle_entry.close') }}
        </v-btn>
        <v-btn
          color="primary"
          flat
          @click="save"
        >
          {{ $i18n.t('vehicle_entry.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Video from '../video.vue';

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  components: {
    'video-recorder': Video,
  },
  data: () => ({
    dialog: false,
    cameraSwitch: true,
    video: null,
    videoSrc: null,
  }),
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 240;
        case 'sm':
          return 480;
        case 'md':
          return 680;
        default:
          return 680;
      }
    },
  },
  watch: {},
  methods: {
    openInputComponent() {
      this.$refs.input.click();
    },
    onInput(input) {
      const { files } = input.target;
      for (const file of files) { // eslint-disable-line no-restricted-syntax
        if (file && file !== undefined) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.addEventListener('load', () => {
            const dataUrl = fileReader.result;
            const blob = dataURLtoBlob(dataUrl);
            this.video = blob;
            this.videoSrc = URL.createObjectURL(blob);
          });
          console.log(fileReader);
        }
      }
    },
    open(video) {
      this.dialog = true;
      if (video && video.blob) {
        this.video = video.blob;
        this.videoSrc = URL.createObjectURL(video.blob);
      }
    },
    save() {
      const random = Math.random()
        .toString(36)
        .substring(9);
      const timestamp = new Date()
        .getTime()
        .toString(36);

      const video = this.video ? { title: `${random}${timestamp}.mp4`, blob: this.video, dataUrl: this.videoSrc } : null;
      this.$emit('save', { video });
      this.close();
    },
    close() {
      this.dialog = false;
      this.cameraSwitch = true;
      this.video = null;
      this.videoSrc = null;
      this.$emit('close');
    },
    saveVideo(data) {
      this.video = data.blob;
      this.videoSrc = URL.createObjectURL(data.blob);
      this.stopCamera();
    },
    deleteVideo() {
      this.video = null;
      this.videoSrc = null;
      this.startCamera();
    },

    startCamera() {
      const { videoRecorder } = this.$refs;
      videoRecorder.startCamera();
    },
    stopCamera() {
      const { videoRecorder } = this.$refs;
      videoRecorder.stopCamera();
    },

    hide() { this.dialog = false; },
    show() { this.dialog = true; },
  },

};

</script>
