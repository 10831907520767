<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1000"
  >
    <v-card class="pa-2">
      <v-layout justify-end>
        <v-btn
          outline
          icon
          large
          color="red"
          @click="dialog = false"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-layout>
      <!--------------------------------------------CAR-------------------------------------------->
      <v-layout
        row
        wrap
      >
        <v-flex>
          <v-layout column>
            <!--BRAND-->
            <v-flex class="caption">
              {{ $t('part_requests.car.brand') }}:
            </v-flex>
            <v-flex>
              <span
                v-if="car.brand"
                class="body-2"
              > {{ car.brand.title }} </span>
            </v-flex>
            <!--MODEL-->
            <v-flex class="caption">
              {{ $t('part_requests.car.model') }}:
            </v-flex>
            <v-flex>
              <span
                v-if="car.model"
                class="body-2"
              >
                {{ car.model.description }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout
            v-if="car.version"
            row
            wrap
          >
            <!--VERSION-->
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.version') }}:
              </div>
              <div class="body-2">
                {{ car.version.description }}
              </div>
            </v-flex>
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.year') }}:
              </div>
              <div class="body-2">
                {{ car.version.startingDate | moment }} - {{ car.version.endingDate | moment }}
              </div>
            </v-flex>
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.body') }}:
              </div>
              <div class="body-2">
                {{ car.version.numberOfDoors }}
              </div>
            </v-flex>
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.power') }}:
              </div>
              <div class="body-2">
                {{ car.version.power.kw }} kw
              </div>
            </v-flex>
            <!--
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.engine') }}:
              </div>
              <div class="body-2">
                {{ car.version.engine }}
              </div>
            </v-flex>
            -->
            <v-flex>
              <div class="caption">
                {{ $t('part_requests.car.supply') }}:
              </div>
              <div class="body-2">
                {{ getSupplyLocale(car.version.supplyCode) }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
        <v-flex>
          <!--PERMIT-->
          <v-layout
            column
            align-start
            justify-center
          >
            <div class="caption">
              {{ $t('part_requests.permit') }}:
            </div>
            <img
              style="max-width: 130px;"
              class="pointer"
              :src="permit && permit.value
                ? `${server}/images/part-request?q=${user.id}-${permit.title}`
                : logo"
              block
              @click="viewPermitImage"
            >
          </v-layout>
        </v-flex>
      </v-layout>
      <!-----------------------------------------REQUESTS------------------------------------------>
      <v-layout
        row
        wrap
        align-center
        class="mt-3"
      >
        <v-flex
          v-for="(request, r) in requests"
          :key="r"
          xs12
          sm6
          md4
          d-flex
        >
          <v-card class="ma-1">
            <v-layout
              column
              fill-height
            >
              <!--IMAGES-->
              <v-flex xs12>
                <v-carousel
                  height="250"
                  class="pointer"
                >
                  <v-carousel-item
                    v-for="(image,i) in request.images"
                    :key="i"
                    :src="`${server}/images/part-request?q=${request.id}-${image}`"
                    @click="viewRequestImages(request)"
                  />
                </v-carousel>
              </v-flex>
              <v-flex
                class="mt-2 mb-1 ml-2 mr-2"
                xs12
              >
                <div class="caption">
                  {{ $t('part_requests.request.code') }}:
                </div>
                <div class="title">
                  {{ request.code }}
                </div>
              </v-flex>
              <v-flex
                class="mt-1 mb-2 ml-2 mr-2"
                xs12
              >
                <div class="caption">
                  {{ $t('part_requests.request.description') }}:
                </div>
                <div class="body-2">
                  {{ request.description }}
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!-------------------------------------------USER-------------------------------------------->
      <div class="body-1 mt-3">
        {{ $t('part_requests.user.users_details') }}:
      </div>
      <v-layout
        v-if="user.details"
        row
        wrap
      >
        <v-flex>
          <div class="caption">
            {{ $t('part_requests.user.name') }}:
          </div>
          <div class="subheading font-weight-medium">
            {{ user.details.name }}
          </div>
        </v-flex>
        <v-flex>
          <div class="caption">
            {{ $t('part_requests.user.surname') }}:
          </div>
          <div class="subheading font-weight-medium">
            {{ user.details.surname }}
          </div>
        </v-flex>
        <v-flex>
          <div class="caption">
            {{ $t('part_requests.user.address') }}:
          </div>
          <div class="subheading font-weight-medium">
            {{ user.details.address }}
          </div>
        </v-flex>
        <v-flex>
          <div class="caption">
            {{ $t('part_requests.user.phone') }}:
          </div>
          <div class="subheading font-weight-medium">
            {{ user.details.phone }}
          </div>
        </v-flex>
        <v-flex>
          <div class="caption">
            {{ $t('part_requests.user.email') }}:
          </div>
          <div class="subheading font-weight-medium">
            {{ user.details.email }}
          </div>
        </v-flex>
      </v-layout>
      <!------------------------------------------------------------------------------------------->
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import logo from '../../assets/logo_small.png';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('MM/YYYY');
    },
  },
  data: () => ({
    logo,
    dialog: false,
    pagination: {
      rowsPerPage: 4,
    },
    requests: [],
    car: {},
    user: {},
    permit: { value: false, title: null },
  }),
  computed: {
    server() { return this.$serverUri; },
  },
  watch: {},
  methods: {
    open(data) {
      console.log(data);
      this.dialog = true;
      this.requests = data.requests;
      this.car = data.car;
      this.user = data.user;
      this.permit = data.permit;
    },
    close() {
      this.requests = [];
      this.car = {};
      this.user = {};
      this.permit = { value: false, title: null };
    },
    hide() { this.dialog = false; },
    show() { this.dialog = true; },
    viewPermitImage() {
      this.$emit('view-permit-image', { id: this.user.id, title: this.permit.title });
    },
    viewRequestImages(request) {
      this.$emit('view-request-images', { id: request.id, images: request.images });
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.infoCar.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.infoCar.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.infoCar.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
