<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('states.form.edit_state') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('states.form.create_state') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-autocomplete
                  v-model="state.name"
                  :items="states"
                  :label="$t('states.form.state')"
                  color="red darken-1"
                  item-text="name"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="state.language"
                  :rules="rules.requiredLetters"
                  :label="$t('states.form.language')"
                  color="red darken-1"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('states.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('states.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    edit: null,
    valid: false,
    state: {},
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
    },
    states: [
      {
        name: 'Albania',
        iso: 'AL',
      },
      {
        name: 'France',
        iso: 'FR',
      },
      {
        name: 'Italy',
        iso: 'IT',
      },
      {
        name: 'United Kingdom',
        iso: 'GB',
      },
      {
        name: 'United States',
        iso: 'US',
      },
    ],
  }),
  methods: {
    open(edit, data) {
      this.edit = edit;
      if (data) this.state = data;
      this.dialog = true;
    },
    close() {
      this.loading = false;
      this.edit = null;
      this.dialog = false;
      this.state = {};
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const flag = this.states.find(f => f.name === this.state.name);
        this.state.flag = flag.iso;
        if (this.edit === true) {
          putRequest('/state/', `id=${this.state._id}`, this.state).then((response) => {
            console.log(response);
            this.$emit('save');
            this.close();
          }).catch((error) => {
            console.log(error);
            this.$emit('error');
            this.close('');
          });
        } else {
          postRequest.object('/state/', this.state).then((response) => {
            console.log(response);
            this.$emit('save');
            this.close();
          }).catch((error) => {
            console.log(error);
            this.$emit('error');
            this.close();
          });
        }
      }
    },
  },
};
</script>
