<template>
  <v-dialog
    v-model="dialog"
    max-width="650"
  >
    <v-card>
      <!-----------------------------------------TITLE BAR----------------------------------------->
      <v-card-title class="redColor">
        <!--TITLE-->
        <span class="headline">
          {{ $t('home.search_part.label') }}
        </span>

        <v-spacer />

        <!--CLOSE BUTTON-->
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <!---------------------------------------SELECTOR---------------------------------------->
          <v-flex xs12>
            <v-layout
              row
              wrap
              align-center
              justify-center
              class="mb-3"
            >
              <v-btn
                depressed
                :color="type === 'search' ? 'primary' : ''"
                style="margin-right: -8px"
                @click="switchTo('search')"
              >
                {{ $t('home.search_part.search') }}
              </v-btn>
              <v-btn
                depressed
                :color="type === 'scan' ? 'primary' : ''"
                @click="switchTo('scan')"
              >
                {{ $t('home.search_part.scan') }}
              </v-btn>
            </v-layout>
          </v-flex>
          <!----------------------------------------SEARCH----------------------------------------->
          <v-flex
            v-if="type === 'search'"
            xs12
          >
            <v-text-field
              v-model="search"
              flat
              :label="$t('home.search_part.search')"
              outline
              color="red darken-4"
              append-icon="search"
              single-line
              hide-details
              :loading="loadingSearch"
              @keyup.enter="searchMethod"
              @click:append="searchMethod"
            />
          </v-flex>
          <!-----------------------------------------SCAN------------------------------------------>
          <v-flex
            v-if="type === 'scan'"
            xs12
          >
            <v-layout
              column
              wrap
            >
              <!----------------------------------CAMERA SCANNER----------------------------------->
              <v-flex v-if="!physicalScanner">
                <qrcode-stream
                  v-if="dialog"
                  :camera="constraints"
                  :paused="paused"
                  @decode="decode"
                />
              </v-flex>
              <!---------------------------------PHYSICAL SCANNER---------------------------------->
              <v-flex v-if="physicalScanner">
                <!---->
              </v-flex>
              <!----------------------------------------------------------------------------------->
              <v-flex xs12>
                <v-layout
                  v-if="loadingScan"
                  row
                  align-center
                  justify-center
                  class="ma-3"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="48"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!----------------------------------------MESSAGE---------------------------------------->
          <v-flex xs12>
            <v-layout
              row
              wrap
              align-center
              justify-center
              class="mt-2"
            >
              <v-alert
                :value="alert.value"
                :type="alert.type"
                outline
              >
                {{ alert.message }}
              </v-alert>
            </v-layout>
          </v-flex>
          <!---------------------------------------LOCATION---------------------------------------->
          <v-flex xs12>
            <v-layout
              v-if="found"
              row
              wrap
              align-center
              justify-center
              @click="goToPart"
            >
              <v-btn outline>
                {{ getStatus() }}
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="type === 'scan'"
          flat
          icon
          @click="reScan"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="type === 'scan'"
          flat
          icon
          disabled
          @click="''"
        >
          <v-icon>fas fa-qrcode</v-icon>
        </v-btn>
        <v-btn
          v-if="type === 'scan'"
          flat
          icon
          @click="switchCamera"
        >
          <v-icon>switch_camera</v-icon>
        </v-btn>
        <v-btn
          outline
          color="error"
          @click="close"
        >
          {{ $t('home.search_part.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    dialog: false,
    type: 'search',
    loadingSearch: false,
    loadingScan: false,
    search: '',
    physicalScanner: false, // Not implemented at the moment.
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    paused: false,
    decodedString: null,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    part: {
      location: null,
      id: null,
      status: null,
    },
    found: false,
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('home.search_part.alert.insert_id'),
      };
    },
    close() {
      this.dialog = false;
      this.loadingSearch = false;
      this.loadingScan = false;
      this.search = '';
      this.paused = false;
      this.decodedString = null;
      this.alert.value = false;
      this.part = {
        location: null,
        id: null,
        status: null,
      };
      this.found = false;
    },
    async searchMethod() {
      this.loadingSearch = true;
      try {
        const response = await this.searchPart(this.search);
        this.found = true;
        this.part = { location: response.location, id: response.id, status: response.status };
        this.displayAlert(true, response.id);
      } catch (error) {
        console.log(error);
        this.found = false;
        this.displayAlert(false, this.search);
      }
      this.loadingSearch = false;
    },
    async decode(value) {
      if (value !== null && value.length > 0) {
        console.log(value);
        this.loadingScan = true;
        this.decodedString = value;
        this.paused = true;
        try {
          const response = await this.searchPart(this.decodedString);
          this.found = true;
          this.part = { location: response.location, id: response.id, status: response.status };
          this.displayAlert(true, response.id);
        } catch (error) {
          console.log(error);
          this.found = false;
          this.displayAlert(false, this.decodedString);
        }
        this.loadingScan = false;
      }
    },
    reScan() {
      this.found = false;
      this.decodedString = false;
      this.loadingScan = false;
      this.alert = {

      };
    },
    switchCamera() {

    },
    searchPart(id) {
      return new Promise((resolve, reject) => {
        getRequest(`/part/search/${id}`, `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}&user_id=${this.$store.getters.userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    displayAlert(success, id) {
      if (success) this.alert = { value: true, type: 'success', message: `${this.$i18n.t('home.search_part.alert.part_with_id')} ${id} ${this.$i18n.t('home.search_part.alert.was_found')}` };
      else this.alert = { value: true, type: 'error', message: `${this.$i18n.t('home.search_part.alert.part_with_id')} ${id} ${this.$i18n.t('home.search_part.alert.was_not_found')}` };
    },
    getStatus() {
      if (this.part.location === 'temporary-parts') {
        return this.$i18n.t('home.search_part.temporary_parts');
      }
      if (this.part.location === 'parts') {
        if (
          this.part.status === 1
        || this.part.status === 2
        || this.part.status === 2.1
        || this.part.status === 3
        || this.part.status === 4
        ) return this.$i18n.t('home.search_part.inventory');

        if (this.part.status === 5) return this.$i18n.t('home.search_part.sales');

        if (this.part.status === 6 || this.part.status === 6.1) this.$i18n.t('home.search_part.lost');

        if (
          this.part.status === 7
        || this.part.status === 8
        || this.part.status === 8.1
        || this.part.status === 9
        ) return this.$i18n.t('home.search_part.damaged');

        if (this.part.status === 10) return this.$i18n.t('home.search_part.reserved');

        if (this.part.status === 0) return this.$i18n.t('home.search_part.recycled');
      }

      return '';
    },
    goToPart() {
      if (this.part.location === 'temporary-parts') {
        return this.$router.push(`/insert-parts?id=${this.part.id}`);
      }
      if (this.part.location === 'parts') {
        if (
          this.part.status === 1
        || this.part.status === 2
        || this.part.status === 2.1
        || this.part.status === 3
        || this.part.status === 4
        ) return this.$router.push(`/inventory?id=${this.part.id}`);

        if (this.part.status === 5) return this.$router.push(`/sales?id=${this.part.id}`);

        if (this.part.status === 6 || this.part.status === 6.1) return this.$router.push(`/lost?id=${this.part.id}`);

        if (
          this.part.status === 7
        || this.part.status === 8
        || this.part.status === 8.1
        || this.part.status === 9
        ) return this.$router.push(`/damaged?id=${this.part.id}`);

        if (this.part.status === 10) return this.$router.push(`/ordered?id=${this.part.id}`);

        if (this.part.status === 0) return this.$router.push(`/recycled?id=${this.part.id}`);
      }

      return 0;
    },
    switchTo(type) {
      this.type = type;
      this.loadingSearch = false;
      this.loadingScan = false;
      this.search = '';
      this.paused = false;
      this.decodedString = null;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('home.search_part.alert.insert_id'),
      };
      this.part = {
        location: null,
        id: null,
        status: null,
      };
      this.found = false;
    },
  },
};

</script>
