<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title class="red darken-2">
        <span
          v-if="vehicle && vehicle.brand && vehicle.model"
          class="headline"
        >
          {{ $i18n.t('vehicle_entry.car_details') }}
          {{ vehicle.brand.title }}
          {{ vehicle.model.fullDescription }}
        </span>
        <span
          v-else
          class="headline"
        >{{ $i18n.t('vehicle_entry.car_details') }}</span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <!--------------------------------------------------------------------------------------->
          <v-flex xs12>
            <!-- CAR DETAILS -->
            <v-layout
              v-if="vehicle && vehicle.version"
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ vehicle.version.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
                md2
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ vehicle.version.startingDate | moment }}
                    -
                    {{ vehicle.version.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ vehicle.version.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2"> {{ vehicle.version.power.kw }} kw </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ vehicle.version.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(vehicle.version.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
          <v-flex
            xs12
            md6
          >
            <!-- VEHICLE DETAILS -->
            <vehicle-details
              ref="vehicleDetails"
              :disabled="true"
            />
            <!-- PHOTO GRID -->
            <v-layout
              v-if="vehicle && vehicle.photos"
              row
              wrap
            >
              <v-flex
                v-for="(image, index) in vehicle.photos"
                :key="index"
                xs6
                sm4
                pa-1
                d-flex
              >
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    flat
                    tile
                    class="d-flex"
                  >
                    <v-img :src="`${server}/images?q=${image.thumb}`">
                      <v-layout
                        row
                        ma-1
                      >
                        <v-icon
                          v-if="hover"
                          x-large
                          color="primary"
                          class="pointer"
                          @click="viewImage(image)"
                        >
                          image
                        </v-icon>
                        <v-spacer />
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
          <v-flex
            xs12
            md6
          >
            <!-- VEHICLE VIEW -->
            <vehicle-view
              ref="vehicleView"
              @position="openPartSelectionDialog"
            />
          </v-flex>
          <!--------------------------------------------------------------------------------------->
          <v-flex xs12>
            <v-btn
              color="primary"
              depressed
              @click="openPartsList"
            >
              {{ $i18n.t('vehicle_entry.parts_list.label') }}
              <v-icon right>
                fas fa-file-pdf
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="openInventoryList"
            >
              {{ $i18n.t('vehicle_entry.inventory_list.label') }}
            </v-btn>
            <v-btn
              v-if="vehicle && vehicle.video"
              color="primary"
              @click="viewVideo"
            >
              {{ $i18n.t('vehicle_entry.videos') }}
              <v-icon right>
                videocam
              </v-icon>
            </v-btn>
            <v-btn
              v-if="vehicle && vehicle.document"
              icon
              @click="viewDocument"
            >
              <v-icon>description</v-icon>
            </v-btn>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>
        <!-- HIDDEN COMPONENTS -->
        <selected-parts
          ref="selectedParts"
          @close="showDialog"
        />
        <img-dialog
          ref="imgDialog"
          @close="showDialog"
        />
        <video-dialog
          ref="videoDialog"
          @close="showDialog"
        />
        <inventory-list
          ref="inventoryList"
          @goToInventory="goToInventory"
          @close="showDialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

import VehicleDetails from '@/components/VehicleEntry/vehicleDetails.vue';
import VehicleView from '@/components/VehicleEntry/vehicleView.vue';

import ImgDialog from '@/components/imgDialog.vue';
import VideoDialog from '@/components/VehicleEntry/videoDialog.vue';

import SelectedParts from '@/components/VehicleEntry/selectedParts.vue';
import InventoryList from '@/components/VehicleEntry/inventoryList.vue';

export default {
  components: {
    'vehicle-details': VehicleDetails,
    'vehicle-view': VehicleView,
    'selected-parts': SelectedParts,
    'inventory-list': InventoryList,
    'img-dialog': ImgDialog,
    'video-dialog': VideoDialog,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    dialog: false,
    vehicle: null,
    photos: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  methods: {
    open(vehicle) {
      console.log(vehicle);
      this.vehicle = vehicle;
      this.setVehicleDetails(vehicle);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.version = null;
      this.clearVehicleDetails();
    },
    setVehicleDetails(details) {
      const { vehicleDetails } = this.$refs;
      vehicleDetails.setDetails(details);
    },
    clearVehicleDetails() {
      const { vehicleDetails } = this.$refs;
      vehicleDetails.clear();
    },
    viewImage(image) {
      const { imgDialog } = this.$refs;
      imgDialog.open({ type: 'string', data: image });
      this.hideDialog();
    },
    viewVideo() {
      const { videoDialog } = this.$refs;
      videoDialog.open({ type: 'string', data: this.vehicle.video });
      this.hideDialog();
    },
    openPartSelectionDialog(position) {
      const { selectedParts } = this.$refs;
      selectedParts.open(position, this.vehicle.parts[position], this.vehicle.parts.comment);
      this.hideDialog();
    },
    openInventoryList() {
      const { inventoryList } = this.$refs;
      this.hideDialog();
      inventoryList.open(this.vehicle);
    },
    openPartsList() {
      this.$emit('parts-list', { vehicle: this.vehicle, partsList: this.vehicle.partsList, component: 'details' });
      this.hideDialog();
    },
    hideDialog() {
      this.dialog = false;
    },
    showDialog() {
      this.dialog = true;
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    viewDocument() {
      window.open(`${this.$serverUri}/documents?q=${this.vehicle.document}`);
    },
    goToInventory(data) {
      this.$emit('goToInventory', data);
      this.close();
    },
  },
};
</script>
