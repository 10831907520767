<template>
  <v-btn
    :loading="loading"
    depressed
    color="primary"
    @click="open()"
  >
    {{ $t('parts.insert_parts.qr_codes.open_all') }}
    <v-icon right>
      fas fa-qrcode
    </v-icon>
  </v-btn>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    open() {
      getRequest(
        '/temporary-part/insert/empty',
        `institution_id=${this.$store.getters.institutionId}&role=${
          this.$store.getters.role
        }`,
      )
        .then((response) => {
          console.log(response);
          const parts = response.emptyParts;
          this.$emit('parts', parts);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
