<template>
  <v-container
    fluid
    class="pa-0"
  >
    <toolbar :items="datatableItems" />
    <datatable
      ref="datatable"
      @items="getDatatableItems"
      @images="openImagesCarousel"
      @price="openPriceDialog"
      @buyer-details="showBuyerDetails"
      @qr="openQrDialog"
      @actions="openActionsDialog"
    />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
    <buyer-details ref="buyerDetails" />
    <price-dialog ref="priceDialog" />
    <actions-dialog
      ref="actionsDialog"
      @add-clients="openClientsDialog"
      @action-completed="getOrders"
      @close="''"
    />

    <clients-dialog
      ref="clientsDialog"
      @saved="getClients"
      @closed="showActionsDialog"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/Shippings/datatable.vue';
import Toolbar from '@/components/Shippings/toolbar.vue';
import ActionsDialog from '@/components/Shippings/actionsDialog.vue';

import ClientsDialog from '@/components/Clients/editDialog.vue';

import BuyerDetails from '@/components/buyerDetails.vue';
import PriceDialog from '@/components/priceDialog.vue';
/* import DetailsDialog from '@/components/detailsDialog.vue'; */
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';

export default {
  components: {
    datatable: Datatable,
    toolbar: Toolbar,
    'actions-dialog': ActionsDialog,
    'buyer-details': BuyerDetails,
    /* 'details-dialog': DetailsDialog, */
    'clients-dialog': ClientsDialog,
    'price-dialog': PriceDialog,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    searchObject: {
      type: null,
      data: {},
    },
    datatableItems: [],
  }),
  mounted() {},
  methods: {
    /* Img Carousel ----------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* Price Dialog ----------------------------------------------------------------------------- */
    openPriceDialog(data) {
      console.log(data);
      const { priceDialog } = this.$refs;
      priceDialog.open(data);
    },
    /* Buyer Details ---------------------------------------------------------------------------- */
    showBuyerDetails(data) {
      const { buyerDetails } = this.$refs;
      buyerDetails.showBuyerDetails(data);
    },
    /* QR Dialog -------------------------------------------------------------------------------- */
    openQrDialog(id) {
      const { qrDialog } = this.$refs;
      qrDialog.open(id);
    },
    /* Actions Dialog --------------------------------------------------------------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
    /* Clients Dialog --------------------------------------------------------------------------- */
    openClientsDialog(client) {
      const { clientsDialog } = this.$refs;
      const { actionsDialog } = this.$refs;
      clientsDialog.open(false, client, this.$store.getters.institutionId, true);
      actionsDialog.hide();
    },
    showActionsDialog() {
      const { actionsDialog } = this.$refs;
      actionsDialog.show();
    },
    getClients() {
      const { actionsDialog } = this.$refs;
      actionsDialog.getMatchingClients();
    },
    /* Datatable -------------------------------------------------------------------------------- */
    getOrders() {
      const { datatable } = this.$refs;
      datatable.getOrders();
    },
    getDatatableItems(data) {
      this.datatableItems = data;
    },
  },
};
</script>
