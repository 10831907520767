<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t("users.form.edit_user") }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t("users.form.create_user") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="user.name"
                  :rules="rules.requiredLetters"
                  :label="$t('users.form.name')"
                  color="red darken-1"
                  prepend-icon="person"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="user.surname"
                  :rules="rules.requiredLetters"
                  :label="$t('users.form.surname')"
                  color="red darken-1"
                  prepend-icon="person"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.email"
                  :rules="rules.email"
                  :label="$t('users.form.email')"
                  color="red darken-1"
                  prepend-icon="email"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('users.form.password')"
                  color="red darken-1"
                  prepend-icon="lock"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="edit ? rules.password : rules.requiredPassword"
                  @click:append="showPassword = !showPassword"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.phone"
                  :rules="rules.requiredNumbers"
                  :label="$t('users.form.phone')"
                  color="red darken-1"
                  prepend-icon="call"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.address"
                  :rules="rules.required"
                  :label="$t('users.form.address')"
                  color="red darken-1"
                  prepend-icon="list_alt"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="state"
                  :rules="rules.required"
                  :items="states"
                  :label="$t('users.form.state')"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="public"
                  return-object
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="user.city"
                  :rules="rules.required"
                  :items="cities"
                  :label="$t('users.form.city')"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="location_city"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.date"
                  :rules="rules.required"
                  :label="$t('users.form.date_of_birth')"
                  color="red darken-1"
                  prepend-icon="date_range"
                  type="date"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.status"
                  :rules="rules.requiredLetters"
                  :label="$t('users.form.status')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-if="$store.getters.role == 'LekoAdmin'
                    || $store.getters.role == 'LekoEmployee'
                  "
                  v-model="user.role"
                  :items="lekoRoles"
                  :label="$t('users.form.role')"
                  color="red darken-1"
                  prepend-icon="poll"
                  item-text="title"
                  item-value="value"
                />

                <v-select
                  v-if="$store.getters.role == 'InstitutionAdmin'"
                  v-model="user.role"
                  :items="ownerRoles"
                  :label="$t('users.form.role')"
                  color="red darken-1"
                  prepend-icon="poll"
                  item-text="title"
                  item-value="value"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-if="$store.getters.role == 'LekoAdmin'
                    || $store.getters.role == 'LekoEmployee'
                  "
                  v-model="user.institution_id"
                  :rules="rules.required"
                  :items="institutions"
                  :label="$t('users.form.select_institution')"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                  prepend-icon="domain"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('users.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('users.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    edit: null,
    valid: false,
    loading: false,
    loadingState: false,
    loadingCity: false,
    loadingInstitution: false,
    user: {},
    state: {},
    states: [],
    cities: [],
    institutions: [],
    password: '',
    showPassword: false,
    rules: {
      required: [
        v => !!v || 'Please fill this field',
      ],
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      password: [
        (v) => {
          if (v) return /(?=.*\d)(?=.*[!@#$%^&*])/.test(v) || 'Password must contain at least one number and one special character';
          return true;
        },
      ],
      requiredPassword: [
        v => !!v || 'Please fill this field',
        v => /(?=.*\d)(?=.*[!@#$%^&*])/.test(v) || 'Password must contain at least one number and one special character',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /^[0-9]*$/.test(v) || 'Only numbers are allowed',
      ],
      email: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    lekoRoles: [
      {
        title: 'Leko Employee',
        value: 'LekoEmployee',
      },
      {
        title: 'Owner',
        value: 'InstitutionAdmin',
      },
      {
        title: 'Manager',
        value: 'InstitutionManager',
      },
      {
        title: 'Worker',
        value: 'User',
      },
    ],
    ownerRoles: [
      {
        title: 'Manager',
        value: 'InstitutionManager',
      },
      {
        title: 'Worker',
        value: 'User',
      },
    ],
  }),
  watch: {
    state: {
      handler(value) {
        if (value) {
          this.user.state = value.name;
          this.getCities(value._id);
        }
      },
    },
  },
  methods: {
    open(edit, data) {
      this.edit = edit;
      if (data) {
        this.user = data;
        this.user.password = '';
      }
      this.dialog = true;
      this.getStates();
      if (data && data.state) {
        this.state = data.state;
        this.getCities();
      }
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee') {
        this.getInstitutions();
      }
    },
    close() {
      this.dialog = false;
      this.user = {};
      this.state = {};
      this.edit = null;
      this.institutions = [];
      this.states = [];
      this.cities = [];
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$store.getters.role === 'InstitutionAdmin') {
          this.user.institution_id = this.$store.getters.institutionId;
        }
        if (this.edit === true) {
          putRequest('/user/', `id=${this.user._id}`, this.user)
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.$emit('saved');
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$emit('error', error);
              this.close();
            });
        } else {
          postRequest
            .object('/user/', this.user)
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.$emit('saved');
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$emit('error', error);
              this.close();
            });
        }
      }
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/')
        .then((response) => {
          console.log(response.institutions);
          this.institutions = response.institutions;
          this.loadingInstitution = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingInstitution = false;
        });
    },
    getStates() {
      this.loadingState = true;
      getRequest('/state/')
        .then((response) => {
          console.log(response);
          this.states = response.states;
          this.loadingState = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingState = false;
        });
    },
    getCities(id) {
      console.log(id);
      this.loadingCity = true;
      getRequest('/city/', `state_id=${id}`)
        .then((response) => {
          console.log(response);
          this.cities = response.cities;
          this.loadingCity = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCity = false;
        });
    },
  },
};
</script>
